import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { AnswerStatsType, QuizData } from "types/training";
import { useTranslation } from "react-i18next";
import HTMLPreview from "components/shared/HTMLPreview";
import { Divider } from "@mui/material";

export interface ICourseHintPreviewProps {
  currentQuestionIndex: number;
  currentQuestion: QuizData;
  answerStats: AnswerStatsType;
}

const CourseHintPreview: React.FC<ICourseHintPreviewProps> = ({
  currentQuestionIndex,
  currentQuestion,
  answerStats,
}) => {
  const { t } = useTranslation();
  const progress = Math.round((currentQuestionIndex / answerStats.questions) * 100);

  return (
    <Stack direction="row" className="p-5 bg-[#1249540A] rounded relative" data-testid="course-hint-preview">
      <Stack gap={1.25} className="flex-1">
        <Typography className="font-helvetica-neue text-lg">
          <span className="font-bold">
            {t("training.question_index", {
              questionIndex: currentQuestionIndex + 1,
            })}
          </span>
          /<span className="text-[#8F8F8F]">{answerStats.questions}</span>
        </Typography>
        <HTMLPreview htmlContent={currentQuestion.hint} className="text-base text-[#212529]" />
      </Stack>
      <Divider className="mx-4" orientation="vertical" flexItem />
      <Typography className="flex flex-col items-center gap-1 text-center px-4">
        <span className="font-bold text-2xl">{answerStats.correct}</span>
        <span className="text-base text-[#8F8F8F]">{t("training.correct")}</span>
      </Typography>
      <LinearProgress
        variant="determinate"
        color="inherit"
        value={progress}
        className="absolute bottom-0 left-0 right-0 text-primary"
      />
    </Stack>
  );
};

export default CourseHintPreview;
