import { useMutation } from "@tanstack/react-query";
import {
  AddTopicTypeResponse,
  RegenerateTopicTypeResponse,
  UpdateTopicTypeRequest,
  UpdateTopicTypeResponse,
} from "types/topic";
import { fetchDataAsync, postDataAsync } from "utilities/http";

export const useUpdateTopicToOrganization = (
  payload: UpdateTopicTypeRequest,
  onSuccess?: (data: UpdateTopicTypeResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["updateTopicToOrganizationDashboard", payload],
    async () => {
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));

      const data = await fetchDataAsync<UpdateTopicTypeResponse>("/update_topic_in_organization", "POST", formData);
      return data;
    },
    {
      onSuccess: (data: UpdateTopicTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useAddTopicToOrganization = (
  payload: UpdateTopicTypeRequest,
  onSuccess?: (data: AddTopicTypeResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["addTopicToOrganizationDashboard", payload],
    async () => {
      const formData = new FormData();
      formData.append("data", JSON.stringify(payload));

      const data = await fetchDataAsync<AddTopicTypeResponse>("/add_new_topic_to_organization", "POST", formData);
      return data;
    },
    {
      onSuccess: (data: AddTopicTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useDeleteTopicToOrganization = (
  topicId: number,
  onSuccess?: (data: UpdateTopicTypeResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["deleteTopicToOrganizationDashboard", topicId],
    async () => {
      const data = await fetchDataAsync<UpdateTopicTypeResponse>(`/delete_topic_in_organization?id=${topicId}`, "POST");
      return data;
    },
    {
      onSuccess: (data: UpdateTopicTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useMoveTopicInCourse = (
  topicId: number,
  onSuccess?: (data: UpdateTopicTypeResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["moveTopicInCourse", topicId],
    async (isUp: boolean) => {
      const payload = { id: topicId, is_up: isUp.toString() };
      const data = await postDataAsync<UpdateTopicTypeResponse>(`/move_topic_in_course`, payload);
      return data;
    },
    {
      onSuccess: (data: UpdateTopicTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useRegenerateTopic = (
  topicId: number,
  onSuccess?: (data: RegenerateTopicTypeResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["regenerateTopic", topicId],
    async ({ isReplace, content, wordCount }: { isReplace: boolean; content: string; wordCount: number }) => {
      const payload = { topic_id: topicId, is_replace: isReplace, content, word_count: wordCount };
      const data = await postDataAsync<RegenerateTopicTypeResponse>(`/open_ai_regenerate_topic`, payload);
      return data;
    },
    {
      onSuccess: (data: RegenerateTopicTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useGenerateTopicQuiz = (
  topicId: number,
  onSuccess?: (data: UpdateTopicTypeResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["generateTopicQuiz", topicId],
    async () => {
      const payload = { topic_id: topicId };
      const data = await postDataAsync<UpdateTopicTypeResponse>(`/open_ai_generate_topic_quiz`, payload);
      return data;
    },
    {
      onSuccess: (data: UpdateTopicTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};
