import React from "react";
import TrainingProvider from "contexts/TrainingContext";
import View from "./view";

const Container = () => {
  return (
    <TrainingProvider>
      <View />
    </TrainingProvider>
  );
};

export default Container;
