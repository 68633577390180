import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface IDeleteCourseModalProps {
  showDeleteCourseModal: boolean;
  hideDeleteModal: () => void;
  onDelete: () => void;
}

const DeleteCourseModal: React.FC<IDeleteCourseModalProps> = props => {
  const { t } = useTranslation();
  const { showDeleteCourseModal, hideDeleteModal, onDelete } = props;

  return (
    <Dialog
      open={showDeleteCourseModal}
      onClose={hideDeleteModal}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{ display: "flex", alignItems: "center", borderBottom: "1px solid #E2E2E2", padding: "20px 24px" }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {t("course.delete_modal.title")}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={hideDeleteModal}
          sx={{
            position: "absolute",
            right: 18,
            top: 18,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px", marginTop: "16px" }}>
        <Typography>{t("course.delete_modal.content")}</Typography>
      </DialogContent>
      <DialogActions sx={{ padding: "8px 24px 24px" }}>
        <Button variant="contained" className="bg-transparent text-mt-dark-slate py-2 w-full" onClick={hideDeleteModal}>
          {t("course.delete_modal.cancel")}
        </Button>
        <Button variant="contained" className="bg-primary py-2 w-full" onClick={onDelete}>
          {t("course.delete_modal.accept")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCourseModal;
