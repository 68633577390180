import React from "react";
import icon from "./base";

export default icon("MoveDown", ({ fill = "black" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9 14L4 9L4.705 8.295L8.5 12.085V3.5H2V2.5H8.5C8.76522 2.5 9.01957 2.60536 9.20711 2.79289C9.39464 2.98043 9.5 3.23478 9.5 3.5V12.085L13.295 8.295L14 9L9 14Z"
      fill={fill}
    />
  </svg>
));
