import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import CurriculumItem from "components/shared/CurriculumItem";
import { useTranslation } from "react-i18next";
import { useTrainingContext } from "contexts/TrainingContext";
import AboutCourseButton from "./AboutCourseButton";

interface ICurriculumProps {}

const Curriculum: React.FC<ICurriculumProps> = () => {
  const { t } = useTranslation();
  const {
    viewModeType,
    listTopicsInCourse,
    currentTopicSelected,
    currentTopicQuizSelected,
    handleRefetchListTopics,
    handleCurrentTopicChange,
    handleCurrentTopicQuizChange,
    handleCurrentAboutCourseChange,
  } = useTrainingContext();

  useEffect(() => {
    handleCurrentAboutCourseChange();
  }, [handleCurrentAboutCourseChange]);

  if (!listTopicsInCourse) return <></>;

  const isHasMultipleTopic = listTopicsInCourse.length > 1;

  return (
    <Stack
      className="w-[339px] h-screen top-20 bg-white rounded-2xl"
      style={{
        position: "sticky",
        top: "5rem",
        height: "auto",
        maxHeight: "calc(100vh - 160px)",
      }}
      gap={1}
    >
      <Typography className="bg-[#E3E6EB] text-xl font-bold py-3 px-4 rounded-t-2xl">
        {t("course.curriculum")}
      </Typography>
      <Stack className="px-4 py-3 flex-1 overflow-y-auto" gap={2}>
        <AboutCourseButton
          isActiveCourse={viewModeType === "course"}
          handleCurrentAboutCourseChange={handleCurrentAboutCourseChange}
        />

        {listTopicsInCourse &&
          listTopicsInCourse.map(topic => {
            const quizTopicId = `quiz_${topic.id}`;
            const isActiveTopic = currentTopicSelected?.id === topic.id;
            const isActiveQuestion = currentTopicQuizSelected === quizTopicId;

            return (
              <CurriculumItem
                key={`${topic.id}`}
                rowIndex={topic.uiIndex!}
                topicId={topic.id}
                topicName={topic.name}
                questionCount={topic.questions_count}
                isActiveTopic={!isActiveQuestion && isActiveTopic}
                isActiveQuestion={isActiveQuestion}
                handleTopicClick={() => handleCurrentTopicChange(topic)}
                handleQuestionClick={() => handleCurrentTopicQuizChange(topic, quizTopicId)}
                refetchListTopics={handleRefetchListTopics}
                isHasMultipleTopic={isHasMultipleTopic}
                isLearning
                isCompleted={topic.isCompleted}
                isSeen={topic.isSeen}
              />
            );
          })}
      </Stack>
    </Stack>
  );
};

export default Curriculum;
