/* eslint-disable no-undef */
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { fetchDataOption, getCSRFToken } from "./fetchDataOption";

const STATUS_REDIRECTS: Record<number, string> = {
  401: "/v2/login",
  403: "/v2/require_access",
  404: "/v2/not_found",
};

const redirectTo = (path: string) => {
  window.location.href = path;
};

async function fetchDataAsync<T>(url: string, method: string, payload?: any, redirectOnFailure?: boolean): Promise<T> {
  try {
    const fetchOptions = fetchDataOption(method, payload);

    // @ts-ignore
    const response = await fetch(url, fetchOptions);

    if (!response.ok) {
      const redirectPath = STATUS_REDIRECTS[response.status];
      if (redirectPath && redirectOnFailure) {
        redirectTo(redirectPath);
      }

      throw new Error(`Failed to fetch data: ${response.statusText}`);
    }

    const data: T = await response.json();

    return data;
  } catch (error) {
    toast.error(error instanceof Error ? error.message : "An unknown error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });

    throw error;
  }
}

function useAsyncData<T>(url: string, method: string) {
  return useQuery<T, Error>([url, method], () => fetchDataAsync<T>(url, method), {
    retry: false,
    refetchOnWindowFocus: false,
  });
}

function getDataAsync<T>(url: string): Promise<T> {
  return axios.get(url, { headers: { "X-CSRF-TOKEN": getCSRFToken() } }).then(res => res.data);
}

function postDataAsync<T>(url: string, payload: any): Promise<T> {
  return axios.post(url, payload, { headers: { "X-CSRF-TOKEN": getCSRFToken() } }).then(res => res.data);
}

function patchDataAsync<T>(url: string, payload: any): Promise<T> {
  return axios.patch(url, payload, { headers: { "X-CSRF-TOKEN": getCSRFToken() } }).then(res => res.data);
}

export { useAsyncData, fetchDataAsync, getDataAsync, postDataAsync, patchDataAsync };
