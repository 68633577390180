import React, { useCallback } from "react";
import { Button, Stack, Typography } from "@mui/material";
import BackIcon from "assets/icons/back";
import CloseIcon from "assets/icons/close";
import { useTranslation } from "react-i18next";
import { useTrainingContext } from "contexts/TrainingContext";
import { useLocation, useParams } from "react-router-dom";
import { useUpdateSeenThreadInCourseTraining } from "hooks/useTraining";
import CurrentProgressView from "./CurrentProgressView";

interface ICourseHeaderProps {}

const CourseHeaderView: React.FC<ICourseHeaderProps> = () => {
  const { t } = useTranslation();
  const { course_id: courseId } = useParams<{ course_id: string }>();
  const location = useLocation();
  const viewType = new URLSearchParams(location.search).get("type");

  const {
    viewModeType,
    currentTopicSelected,
    handleNextTopic,
    handleRefetchListTopics,
    handleCurrentTopicQuizChange,
  } = useTrainingContext();

  const { mutate: mutateUpdateSeenTopic } = useUpdateSeenThreadInCourseTraining(currentTopicSelected.id, () => {
    handleRefetchListTopics();
    handleCurrentTopicQuizChange(currentTopicSelected, `quiz_${currentTopicSelected.id}`);
  });

  const handleCompleteAndContinue = useCallback(() => {
    if (viewModeType === "topic") {
      mutateUpdateSeenTopic();
      return;
    }

    handleNextTopic();
  }, [viewModeType, handleNextTopic, mutateUpdateSeenTopic]);

  const isViewAsLearner = viewType === "learner";

  return (
    <header className="sticky top-0 z-50 bg-white">
      <Stack className="flex-row justify-between items-center px-6 py-4 w-full">
        {isViewAsLearner && (
          <>
            <Button
              LinkComponent="a"
              href={`/v2/dashboard/courses/${courseId}/edit`}
              variant="text"
              className="flex-row gap-2 items-center w-56"
              disabled
            >
              {/** TODO: Add back icon */}
              {/* <BackIcon width={9} height={18} /> */}
            </Button>
            <Stack>
              <Typography className="text-base font-bold leading-6 text-mt-dark-gray">
                {t("course.action.view_as_learner")}
              </Typography>
            </Stack>
            <Button
              LinkComponent="a"
              href={`/v2/dashboard/courses/${courseId}/edit`}
              variant="text"
              className="flex-row items-center gap-2 normal-case text-[#313B3D] text-xs font-bold rounded-lg border border-solid border-[#555F60] px-4 py-2"
              data-testid="header-close-button"
            >
              {t("global.close")}
              <CloseIcon width={16} />
            </Button>
          </>
        )}

        {!isViewAsLearner && (
          <>
            <Stack direction="row" gap={5}>
              <Button
                LinkComponent="a"
                href="/v2/dashboard/courses"
                variant="text"
                className="flex-row gap-2 items-center"
              >
                <BackIcon width={9} height={18} />
                <Typography className="text-sm leading-6 text-black normal-case">{t("global.back")}</Typography>
              </Button>
              <CurrentProgressView />
            </Stack>
            <Stack>
              <Typography className="text-base font-bold leading-6 text-mt-dark-gray">
                {currentTopicSelected?.course_name}
              </Typography>
            </Stack>
            <Button
              className="flex gap-2 items-center px-4 py-3 h-10 text-white normal-case rounded-lg bg-primary hover:bg-primary/80 disabled:text-white/80 disabled:bg-primary/40"
              onClick={handleCompleteAndContinue}
              data-testid="btn-continue-course"
              disabled={viewModeType === "quiz" && !currentTopicSelected.isCompleted}
            >
              {t("training.complete_and_continue")}
            </Button>
          </>
        )}
      </Stack>
    </header>
  );
};

export default CourseHeaderView;
