import React, { useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  Stack,
  LinearProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface ITopicAiWriterPopupProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (description: string, isReplaceContent: boolean, wordCount: number) => void;
  isLoading: boolean;
}

const TopicAiWriterPopup: React.FC<ITopicAiWriterPopupProps> = ({ open, onClose, onSubmit, isLoading }) => {
  const { t } = useTranslation();
  const [isReplaceContent, setIsReplaceContent] = useState(false);
  const [description, setDescription] = useState("");
  const [wordCount, setWordCount] = useState("500");

  const handleSubmit = useCallback(() => {
    if (isLoading) return;

    if (!description) {
      toast.error(t("topic.ai_writer_modal.describe_text_placeholder"));
      return;
    }

    if (Number(wordCount) <= 0) {
      toast.error(t("topic.ai_writer_modal.word_count_error"));
      return;
    }

    onSubmit(description, isReplaceContent, Number(wordCount));
  }, [description, isReplaceContent, wordCount, onSubmit, isLoading]);

  const handleClose = useCallback(() => {
    if (isLoading) return;

    onClose();
  }, [onClose, isLoading]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      data-testid="topic-ai-writer-popup"
      sx={{
        "& .MuiDialog-paper": {
          width: "600px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogContent className="p-6">
        <FormControlLabel
          control={
            <Checkbox
              className="p-2 text-xs text-primary"
              checked={isReplaceContent}
              onChange={e => setIsReplaceContent(e.target.checked)}
              color="default"
              data-testid="checkbox-replace-content"
            />
          }
          label={t("topic.ai_writer_modal.replace_text")}
          className="font-medium text-black"
        />
        <Stack className="border-t border-[#E2E2E2] mt-2 mb-4"></Stack>

        <Typography className="mb-2 font-medium text-black">{t("topic.ai_writer_modal.describe_text")}</Typography>
        <TextField
          multiline
          rows={4}
          fullWidth
          placeholder={t("topic.ai_writer_modal.describe_text_placeholder")}
          value={description}
          onChange={e => setDescription(e.target.value)}
          variant="outlined"
          slotProps={{
            htmlInput: {
              "data-testid": "input-description",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              padding: 0,
              borderRadius: "8px",
              "& textarea": {
                padding: "8px",
                boxShadow: "none",
              },
            },
          }}
          className="mb-4"
        />

        <Typography className="mb-2 font-medium text-black">{t("topic.ai_writer_modal.word_count")}</Typography>
        <TextField
          type="number"
          fullWidth
          value={wordCount}
          onChange={e => setWordCount(e.target.value)}
          variant="outlined"
          slotProps={{
            htmlInput: {
              "data-testid": "input-words-count",
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "8px",
              "& .MuiOutlinedInput-input": {
                borderRadius: "8px",
                "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "&[type=number]": {
                  "-moz-appearance": "textfield",
                },
              },
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
        />

        <Stack className="mt-6" gap={0.5}>
          {isLoading && (
            <LinearProgress
              color="inherit"
              className="rounded-lg text-primary"
              variant={isLoading ? undefined : "determinate"}
              sx={{ height: 5 }}
            />
          )}

          <Button
            variant="contained"
            className="px-4 py-2 font-bold bg-primary"
            sx={{ textTransform: "capitalize", borderRadius: 2 }}
            fullWidth
            onClick={handleSubmit}
            disabled={isLoading}
            data-testid="button-submit"
          >
            {t("topic.ai_writer_modal.update_button")}
          </Button>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default TopicAiWriterPopup;
