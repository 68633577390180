import { useEffect } from "react";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";

const PageViewEvent = () => {
  useEffect(() => {
    try {
      const currentPath = window.location.pathname + window.location.search;

      ReactGA.send({ hitType: "pageview", page: currentPath });
      ReactPixel.trackCustom("page-view", { path: currentPath });
    } catch (error) {
      console.error("Failed to send analytics:", error);
    }
  }, []);

  return null;
};

export default PageViewEvent;
