import React, { useCallback, useState } from "react";
import Box from "@mui/material/Box";

type ResponsiveImageProps = {
  alt: string;
  src: string;
  /**
   * This takes precedence over `ratio`.
   */
  height?: string;
  ratio?: number; // width/height
  width?: string;
  borderRadius?: string;
};

const ResponsiveImageView = ({ alt, src, height, ratio, borderRadius, width = "100%" }: ResponsiveImageProps) => {
  const [paddingTop, setPaddingTop] = useState("");

  const onLoad = useCallback(
    (img: HTMLImageElement) => {
      const q = ratio ? 1 / ratio : img.naturalHeight / img.naturalWidth;
      setPaddingTop(`${(q * 100).toFixed()}%`);
    },
    [ratio],
  );

  return (
    <Box className="r-image" style={{ width }}>
      <Box className="relative" style={height ? { height } : { paddingTop }}>
        <img
          data-testid="responsive-image-view"
          className="object-cover"
          alt={alt}
          src={src}
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            objectFit: "cover",
            borderRadius: borderRadius,
          }}
          onLoad={e => onLoad(e.currentTarget)}
        />
      </Box>
    </Box>
  );
};

export default ResponsiveImageView;
