import React from "react";
import icon from "./base";

export default icon("User", ({ fill = "#fff" }) => (
  <svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 9.5C10.4855 9.5 12.5 7.48555 12.5 5C12.5 2.51445
      10.4855 0.5 8 0.5C5.51445 0.5 3.5 2.51445 3.5 5C3.5
      7.48555 5.51445 9.5 8 9.5ZM11.15 10.625H10.5629C9.78242
      10.9836 8.91406 11.1875 8 11.1875C7.08594 11.1875 6.22109
      10.9836 5.43711 10.625H4.85C2.24141 10.625 0.125 12.7414
      0.125 15.35V16.8125C0.125 17.7441 0.880859 18.5 1.8125
      18.5H14.1875C15.1191 18.5 15.875 17.7441 15.875 16.8125V15.35C15.875
      12.7414 13.7586 10.625 11.15 10.625Z"
      fill={fill}
    />
  </svg>
));
