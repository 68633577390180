import React, { createContext, useState, useEffect, useCallback, useContext } from "react";
import { CourseLearningType, CourseType } from "types/course";
import { useGetCourseList, useGetListCourseSearchAll, useGetCourseLearningList } from "hooks/useCourse";

interface CourseContextType {
  coursesList?: CourseType[];
  refetchDataCourses: () => void;
  searchDataCourses: (keyword: string) => void;
  isLoading: boolean;
  coursesLearningList?: CourseLearningType[];
  isLoadingCourseLearningList: boolean;
  filterLearningCourses: (keyword: string) => void;
}

const defaultContextValue: CourseContextType = {
  coursesList: undefined,
  refetchDataCourses: () => {},
  searchDataCourses: () => {},
  isLoading: false,
  coursesLearningList: undefined,
  isLoadingCourseLearningList: false,
  filterLearningCourses: () => {},
};

export const CourseContext = createContext<CourseContextType>(defaultContextValue);

const CourseProvider = ({ children }: { children: React.ReactNode }) => {
  const [searchText, setSearchText] = useState("");
  const [filterValue, setFilterValue] = useState("all");
  const [coursesList, setCoursesList] = useState<CourseType[]>([]);
  const [coursesLearningList, setCoursesLearningList] = useState<CourseLearningType[]>([]);

  const { data: dataCourseList, isLoading: isLoadingList, refetch: refetchDataCoursesList } = useGetCourseList();
  const { data: dataCourseLearningList, isLoading: isLoadingCourseLearningList } = useGetCourseLearningList(
    searchText,
    filterValue,
  );
  const { data: dataCourseSearchList, isLoading: isLoadingSearch } = useGetListCourseSearchAll(searchText);
  const isLoading = isLoadingList || isLoadingSearch;

  useEffect(() => {
    if (searchText) {
      if (dataCourseSearchList?.data) {
        setCoursesList([...dataCourseSearchList.data]);
      } else {
        setCoursesList([]);
      }
    } else {
      if (dataCourseList?.data) {
        setCoursesList([...dataCourseList.data]);
      }
    }
  }, [dataCourseList, dataCourseSearchList, searchText]);

  useEffect(() => {
    if (dataCourseList && dataCourseList.data) {
      setCoursesList(dataCourseList.data);
    }
  }, [dataCourseList]);

  useEffect(() => {
    if (dataCourseLearningList?.course_progresses) {
      const data = dataCourseLearningList.course_progresses;
      setCoursesLearningList(data);
    }
  }, [dataCourseLearningList]);

  const refetchDataCourses = useCallback(() => {
    refetchDataCoursesList();
  }, [refetchDataCoursesList]);
  const searchDataCourses = useCallback((keyword: string) => {
    setSearchText(keyword);
  }, []);
  const filterLearningCourses = useCallback((filter: string) => {
    setFilterValue(filter);
  }, []);

  const value = {
    coursesList,
    refetchDataCourses,
    searchDataCourses,
    isLoading,
    coursesLearningList,
    isLoadingCourseLearningList,
    filterLearningCourses,
  };
  return <CourseContext.Provider value={value}>{children}</CourseContext.Provider>;
};

export default CourseProvider;

export const useCourseContext = () => useContext(CourseContext);
