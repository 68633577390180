import { useGetCourseDetails, useGetListTopics } from "hooks/useCourse";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { CourseType } from "types/course";
import { TopicListTypeResponse, TopicType } from "types/topic";

interface CourseContextState {
  isLoading: boolean;
  showSaving: boolean;
  dataTopics: TopicListTypeResponse | undefined;
  courseDetail: CourseType | undefined;
  currentTopicSelected: TopicType | undefined;
}

interface CourseContextType {
  state: CourseContextState;
  refetchListTopics: () => void;
  handleShowSaving: (isSaving: boolean) => void;
  handleLoading: (isLoading: boolean) => void;
  handleSetCurrentTopic: (topic: TopicType) => void;
}

const initialState: CourseContextState = {
  isLoading: false,
  showSaving: false,
  dataTopics: undefined,
  courseDetail: undefined,
  currentTopicSelected: undefined,
};

const defaultContextValue: CourseContextType = {
  state: initialState,
  refetchListTopics: () => {},
  handleShowSaving: () => {},
  handleLoading: () => {},
  handleSetCurrentTopic: () => {},
};

export const CourseViewOrEditContext = createContext<CourseContextType>(defaultContextValue);

const CourseViewOrEditProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState(initialState);
  const { course_id: courseId } = useParams<{ course_id: string }>();

  const { data: dataTopics, refetch: refetchListTopics } = useGetListTopics(courseId);
  const { data: courseDetail } = useGetCourseDetails(courseId);

  const handleShowSaving = useCallback((isSaving: boolean) => {
    setState(state => ({ ...state, showSaving: isSaving }));
  }, []);

  const handleLoading = useCallback((isLoading: boolean) => {
    setState(state => ({ ...state, isLoading }));
  }, []);

  const handleSetTopics = useCallback((topics?: TopicListTypeResponse) => {
    setState(state => ({ ...state, dataTopics: topics }));
  }, []);

  const handleSetCurrentTopic = useCallback((topic?: TopicType) => {
    setState(state => ({ ...state, currentTopicSelected: topic }));
  }, []);

  const handleSetCourseDetail = useCallback((courseDetail?: CourseType) => {
    setState(state => ({ ...state, courseDetail }));
  }, []);

  useEffect(() => {
    if (!dataTopics) return;

    handleSetTopics(dataTopics);
  }, [dataTopics]);

  useEffect(() => {
    if (!courseDetail) return;

    handleSetCourseDetail(courseDetail);
  }, [courseDetail]);

  const value = useMemo(
    () => ({
      state,
      refetchListTopics,
      handleShowSaving,
      handleLoading,
      handleSetCurrentTopic,
    }),
    [state, handleShowSaving, handleLoading, refetchListTopics, handleSetCurrentTopic],
  );

  return <CourseViewOrEditContext.Provider value={value}>{children}</CourseViewOrEditContext.Provider>;
};

export default CourseViewOrEditProvider;

export const useCourseViewOrEditContext = () => useContext(CourseViewOrEditContext);
export const useCourseViewOrEditSelector = <T,>(selector: (state: CourseContextState) => T) => {
  const context = useContext(CourseViewOrEditContext);

  return selector(context.state);
};
