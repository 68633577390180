import React from "react";
import { useParams } from "react-router-dom";
import CourseViewOrEditProvider from "contexts/CourseViewOrEditContext";
import useGenerateQuestionsStatus from "hooks/useGenerateQuestionsStatus";
import CourseViewOrEdit from "../CourseViewOrEdit";
import HeaderView from "./components/HeaderView";

const View = () => {
  const { course_id: courseId } = useParams<{ course_id: string }>();
  const { isCompleted, isHasTopic, topicsGenerating } = useGenerateQuestionsStatus(courseId);

  return (
    <CourseViewOrEditProvider>
      <HeaderView courseId={courseId} />
      <CourseViewOrEdit
        action="edit"
        isCompleted={isCompleted}
        isHasTopic={isHasTopic}
        topicsGenerating={topicsGenerating}
      />
    </CourseViewOrEditProvider>
  );
};

export default View;
