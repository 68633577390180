import React, { useCallback, useState } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useGetListQuestionOfTopic } from "hooks/useCourse";
import { TopicType } from "types/topic";
import { useTranslation } from "react-i18next";
import GeneratingView from "components/shared/GeneratingView";
import Box from "@mui/material/Box";
import { useCourseViewOrEditContext } from "contexts/CourseViewOrEditContext";
import QuestionItem from "./QuestionItem";
import AddQuestionButton from "./AddQuestionButton";
import AddQuestionAiMakerButton from "./AddQuestionAiMakerButton";

interface IQuestionEditProps {
  currentTopicSelected: TopicType;
  isTopicSelectedGenerating: boolean;
}

const QuestionEdit: React.FC<IQuestionEditProps> = ({ currentTopicSelected, isTopicSelectedGenerating }) => {
  const { t } = useTranslation();
  const [isGenerating, setIsGenerating] = useState(false);

  const { refetchListTopics } = useCourseViewOrEditContext();
  const { data: dataQuestion, refetch: refetchListQuestion } = useGetListQuestionOfTopic(
    currentTopicSelected.course_id.toString(),
    currentTopicSelected.id.toString(),
  );

  const handleGenerateQuestion = useCallback(() => {
    setIsGenerating(true);
  }, []);

  const handleGenerateQuestionSuccess = useCallback(() => {
    setIsGenerating(false);
    refetchListTopics();
    refetchListQuestion();
  }, [refetchListQuestion, refetchListTopics]);

  if (isTopicSelectedGenerating || !dataQuestion) {
    return <GeneratingView label={t("question.your_question_is_being_generated")} />;
  }

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography className="font-medium uppercase text-sm text-[#313B3D]">
          {t("question.topic", {
            index: currentTopicSelected.uiIndex,
          })}
        </Typography>

        <Stack direction="row" justifyContent="space-between">
          <Typography className="font-bold text-[2rem] text-[#313B3D]">Q&A</Typography>

          <Stack direction="row" gap={1} alignItems="center">
            <AddQuestionButton
              courseId={currentTopicSelected.course_id.toString()}
              threadId={currentTopicSelected.id.toString()}
              refetch={refetchListQuestion}
              isDisabled={isGenerating}
            />

            <AddQuestionAiMakerButton
              threadId={currentTopicSelected.id.toString()}
              onSuccess={handleGenerateQuestionSuccess}
              onClick={handleGenerateQuestion}
              isDisabled={isGenerating}
            />
          </Stack>
        </Stack>
      </Stack>

      <Stack gap={5} className="relative">
        {dataQuestion.data.map((item, index) => (
          <QuestionItem
            key={`question-${item.id}`}
            question={item}
            index={index + 1}
            refetchListQuestion={refetchListQuestion}
          />
        ))}

        {isGenerating && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "center",
              paddingTop: "100px",
              zIndex: 1,
            }}
          >
            <GeneratingView label={t("question.your_question_is_being_generated")} isBorder />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export default QuestionEdit;
