import { useMutation } from "@tanstack/react-query";
import { GenerateCourseTypeRequest } from "types/course";
import { getCSRFToken } from "../utilities/fetchDataOption";

interface GenerateCourseResponse {
  success: boolean;
  course_id?: string;
  error?: string;
}

const useGenerateCourse = ({
  onSuccess,
  onError,
}: {
  onSuccess: (data: GenerateCourseResponse) => void;
  onError: (error: any) => void;
}) => {
  return useMutation(
    (payload: GenerateCourseTypeRequest) => {
      return fetch("/open_ai_generate_questions", {
        method: "POST",
        // @ts-ignore
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": getCSRFToken(),
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "same-origin",
        body: JSON.stringify(payload),
      }).then(res => res.json());
    },
    {
      onSuccess,
      onError,
    },
  );
};

export default useGenerateCourse;
