import React, { useMemo } from "react";
import Stack from "@mui/material/Stack";
import { TopicType } from "types/topic";
import ContentEdit from "components/course/CourseEdit/components/Content";
import ContentView from "components/course/CourseView/components/Content";
import GeneratingView from "components/shared/GeneratingView";
import { useTranslation } from "react-i18next";
import { useCourseViewOrEditSelector } from "contexts/CourseViewOrEditContext";
import CourseDetail from "../CourseView/components/CourseDetail";
import CourseEdit from "../CourseEdit/components/CourseEdit";
import Curriculum from "./components/Curriculum";
import { ViewModeType, ActionType } from "./types";

interface IViewProps {
  action: ActionType;
  courseId: string;
  currentTopicQuizSelected?: string;
  handleCurrentTopicChange: (topic: TopicType) => void;
  handleCurrentTopicQuizChange: (topic: TopicType, quizId: string) => void;
  viewModeType: ViewModeType;
  isCompleted: boolean;
  isHasTopic: boolean;
  topicsGenerating: number[];
}
const View: React.FC<IViewProps> = ({
  action,
  courseId,
  currentTopicQuizSelected,
  handleCurrentTopicChange,
  handleCurrentTopicQuizChange,
  viewModeType,
  isCompleted,
  isHasTopic,
  topicsGenerating,
}) => {
  const { t } = useTranslation();
  const { currentTopicSelected, courseDetail } = useCourseViewOrEditSelector(state => state);

  const isTopicSelectedGenerating = useMemo(
    () => !isCompleted && topicsGenerating.includes(currentTopicSelected?.id ?? -1),
    [currentTopicSelected, topicsGenerating, isCompleted],
  );

  return (
    <Stack gap={3} data-testid="course-view-or-edit">
      {action === "view" && courseDetail && <CourseDetail courseDetail={courseDetail} />}
      {action === "edit" && courseDetail && <CourseEdit courseDetail={courseDetail} />}

      <Stack direction="row" gap={2} className="container bg-[#F4F4F4] mb-4">
        {(isCompleted || isHasTopic) && (
          <>
            <Curriculum
              courseId={courseId}
              currentTopicSelected={currentTopicSelected}
              currentTopicQuizSelected={currentTopicQuizSelected}
              handleCurrentTopicChange={handleCurrentTopicChange}
              handleCurrentTopicQuizChange={handleCurrentTopicQuizChange}
            />

            {action === "view" && (
              <ContentView viewModeType={viewModeType} isTopicSelectedGenerating={isTopicSelectedGenerating} />
            )}
            {action === "edit" && (
              <ContentEdit
                viewModeType={viewModeType}
                isTopicSelectedGenerating={isTopicSelectedGenerating}
                handleCurrentTopicQuizChange={handleCurrentTopicQuizChange}
              />
            )}
          </>
        )}

        {!isCompleted && !isHasTopic && <GeneratingView label={t("topic.your_topic_is_being_generated")} />}
      </Stack>
    </Stack>
  );
};

export default View;
