import { useMutation, useQuery } from "@tanstack/react-query";
import { fetchDataAsync, postDataAsync } from "utilities/http";
import {
  CourseDetailTypeResponse,
  PaginationParams,
  UpdateCourseTypeRequest,
  UpdateCourseTypeResponse,
  ListCourseTypeResponse,
  AssignCourseTypeRequest,
  CourseLearningListTypeResponse,
  CategoryListResponse,
} from "types/course";
import { TopicListTypeResponse } from "types/topic";
import { QuestionDataResponse } from "types/question";

export const useGetCourseList = () => {
  return useQuery({
    queryKey: ["fetchGetCourseList"],
    queryFn: async () => {
      const data = await fetchDataAsync<ListCourseTypeResponse>(`/get_list_course_dashboard`, "POST");
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetCourseLearningList = (searchText: string, filter: string) => {
  // TODO: Waiting for API support
  const unfinished = false;
  return useQuery({
    queryKey: ["fetchGetCourseLearningList", searchText, filter],
    queryFn: async () => {
      const data = await fetchDataAsync<CourseLearningListTypeResponse>(
        `/get_user_progress_training?unfinished=${unfinished}&course_name=${searchText}&learnt_status=${filter}`,
        "GET",
      );
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetCourseDetails = (courseId: string) => {
  return useQuery({
    queryKey: ["fetchGetCourseDetails", courseId],
    queryFn: async () => {
      const data = await fetchDataAsync<CourseDetailTypeResponse>(
        `/get_course_details?id=${courseId}`,
        "GET",
        undefined,
        true,
      );
      return {
        ...data.course,
        total_questions: data.course.total_questions ?? 0,
        total_topics: data.course.total_topics ?? 0,
      };
    },
    refetchOnWindowFocus: false,
  });
};

export const useDeleteCourseToOrganization = (
  courseId: number,
  onSuccess?: (data: any) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["deleteCourseToOrganization", courseId],
    async () => {
      const data = await fetchDataAsync<UpdateCourseTypeResponse>(
        `/delete_course_in_organization?id=${courseId}`,
        "POST",
      );
      return data;
    },
    {
      onSuccess: (data: any) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message ?? "Something went wrong");
      },
    },
  );
};
export const useAssignCourseToOrganization = (
  payload: AssignCourseTypeRequest,
  onSuccess?: (data: any) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["assignCourseToOrganization", payload],
    async () => {
      const formData = new FormData();
      formData.append("course_id", payload.course_id.toString());
      formData.append("email", payload.email);
      const data = await fetchDataAsync<UpdateCourseTypeResponse>(`/assign_course`, "POST", formData);
      return data;
    },
    {
      onSuccess: (data: any) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message ?? "Something went wrong");
      },
    },
  );
};

export const useGetListCourseSearchAll = (keyword: string) => {
  return useQuery({
    queryKey: ["fetchGetListCourseSearchAll", keyword],
    queryFn: async () => {
      const formData = new FormData();
      formData.append("current_page", "1");
      formData.append("take", "");
      formData.append("keyword", keyword);

      const reps = await fetchDataAsync<ListCourseTypeResponse>(
        "/get_list_course_search_all_dashboard",
        "POST",
        formData,
      );
      return reps;
    },
  });
};

export const useGetListTopics = (
  courseId: string,
  keyword = "",
  pagination: PaginationParams = {
    current_page: "1",
    take: "50",
  },
) => {
  return useQuery({
    queryKey: ["fetchGetListTopics", courseId],
    queryFn: async () => {
      const formData = new FormData();
      formData.append("current_page", pagination.current_page);
      formData.append("take", pagination.take);
      formData.append("keyword", keyword);
      formData.append("course_id", courseId);

      const reps = await fetchDataAsync<TopicListTypeResponse>("/get_list_topics", "POST", formData);
      return reps;
    },
    refetchOnWindowFocus: false,
  });
};

export const useGetListQuestionOfTopic = (
  courseId: string,
  courseThreadId: string,
  keyword = "",
  pagination: PaginationParams = {
    current_page: "1",
    take: "50",
  },
) => {
  return useQuery({
    queryKey: ["fetchGetListQuestionOfTopics", courseId, courseThreadId],
    queryFn: async () => {
      const formData = new FormData();
      formData.append("current_page", pagination.current_page);
      formData.append("take", pagination.take);
      formData.append("keyword", keyword);
      formData.append("course_id", courseId);
      formData.append("course_thread_id", courseThreadId);

      const reps = await fetchDataAsync<QuestionDataResponse>(
        "/get_list_question_of_topic_in_dashboard",
        "POST",
        formData,
      );
      return reps;
    },
  });
};

export const useUpdateCourseToOrganization = (
  payload: UpdateCourseTypeRequest,
  onSuccess?: (data: any) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["updateCourseToOrganizationDashboard", payload],
    async () => {
      const formData = new FormData();
      if (payload.image_file) {
        formData.append("image_file", payload.image_file, payload.image_file.name);
      }
      formData.append("data", JSON.stringify(payload));

      const data = await fetchDataAsync<UpdateCourseTypeResponse>("/update_course_in_organization", "POST", formData);
      return data;
    },
    {
      onSuccess: (data: UpdateCourseTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useGetCategoryList = () => {
  return useQuery({
    queryKey: ["useGetCategoryList"],
    queryFn: async () => {
      const data = await fetchDataAsync<CategoryListResponse>(`/get_list_categories`, "GET");
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export const useGenerateDetailedTopic = (
  courseId: number,
  onSuccess?: (data: UpdateCourseTypeResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["generateDetailedTopic", courseId],
    async ({ isAutoWriteContent }: { isAutoWriteContent: boolean }) => {
      const payload = { course_id: courseId, is_auto_write_content: isAutoWriteContent };
      const data = await postDataAsync<UpdateCourseTypeResponse>(`/open_ai_generate_detailed_topic`, payload);
      return data;
    },
    {
      onSuccess: (data: UpdateCourseTypeResponse) => {
        onSuccess?.(data);
      },
      onError: (error: Error) => {
        onError?.(error?.message);
      },
    },
  );
};
