/* eslint-disable import/named */
import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";

const EditorRoute = (props: RouteProps) => {
  const { infoAppDashboard } = useContext(AuthContext);
  const { ability } = infoAppDashboard!;

  if (ability.can("contribute_to", "Organization")) {
    return <Route {...props} />;
  } else {
    return <Redirect to="/" />;
  }
};

export default EditorRoute;
