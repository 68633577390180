import { Box, Button, Checkbox, FormControlLabel, Stack, Typography } from "@mui/material";
import HTMLPreview from "components/shared/HTMLPreview";
import SectionHeader from "components/shared/SectionHeader";
import { useGetListTopics, useGetCourseDetails, useGenerateDetailedTopic } from "hooks/useCourse";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AddIcon from "assets/icons/add";
import { useAddTopicToOrganization } from "hooks/useTopic";
import { toast } from "react-toastify";
import LoadingOverlayView from "components/shared/LoadingOverLayView";
import { TopicItemView } from "./TopicItemView";

interface CourseReviewProps {
  courseId: string;
}

const CourseReview = ({ courseId }: CourseReviewProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isAutoWriteContent, setIsAutoWriteContent] = useState(true);

  const { data: dataTopics, refetch: refetchListTopics } = useGetListTopics(courseId);
  const { data: courseDetail } = useGetCourseDetails(courseId);

  const {
    mutate: mutateAddTopicToOrganization,
    isLoading: isLoadingAddTopicToOrganization,
  } = useAddTopicToOrganization(
    {
      id: 0,
      name: t("topic.untitled"),
      description: t("topic.add_content_here"),
      course_id: Number(courseId),
    },
    data => {
      if (data) {
        toast.success(t("global.added_successfully"));
        refetchListTopics();
      }
    },
    error => {
      toast.error(error);
    },
  );

  const redirectToEditCourse = useCallback(() => {
    history.push(`/v2/dashboard/courses/${courseId}/edit`);
  }, [courseId, history]);

  const { mutate: mutateGenerateDetailedTopic, isLoading: isLoadingGenerateDetailedTopic } = useGenerateDetailedTopic(
    Number(courseId),
    () => {
      redirectToEditCourse();
    },
    error => {
      toast.error(error);
    },
  );

  const handleAddTopic = useCallback(() => {
    mutateAddTopicToOrganization();
  }, [mutateAddTopicToOrganization]);

  const handleGenerateDetailedTopics = useCallback(() => {
    if (isAutoWriteContent) {
      mutateGenerateDetailedTopic({ isAutoWriteContent });
    } else {
      redirectToEditCourse();
    }
  }, [mutateGenerateDetailedTopic, isAutoWriteContent, redirectToEditCourse]);

  if (!courseDetail) return <LoadingOverlayView />;

  return (
    <div>
      <Stack spacing={3}>
        <Box>
          <Typography className="text-base font-bold" gutterBottom>
            {t("course.title")}:
          </Typography>
          <Typography className="text-sm">{courseDetail.name}</Typography>
        </Box>

        <Box>
          <Typography className="text-base font-bold" gutterBottom>
            {t("course.description")}:
          </Typography>
          <HTMLPreview htmlContent={courseDetail.description} className="text-sm text-black " />
        </Box>
      </Stack>

      <SectionHeader title={t("create_course_with_ai.edit_your_course_structure_below")} />

      <Box>
        {dataTopics?.data.map((topic, index) => (
          <TopicItemView key={index} topic={topic} index={index} refetchListTopics={refetchListTopics} />
        ))}
      </Box>

      <Box className="my-10">
        <Button
          variant="outlined"
          className="flex items-center gap-2 py-2 mt-10 text-sm font-normal normal-case border-none rounded-lg text-primary hover:bg-primary/10 w-fit"
          onClick={handleAddTopic}
          disabled={isLoadingAddTopicToOrganization}
          data-testid="btn-add-topic"
        >
          <AddIcon width={24} /> {t("topic.add_topic")}
        </Button>
      </Box>

      <Stack gap={1}>
        <FormControlLabel
          control={
            <Checkbox
              className="p-2 text-xs text-primary"
              checked={isAutoWriteContent}
              onChange={e => setIsAutoWriteContent(e.target.checked)}
              color="default"
              data-testid="checkbox-replace-content"
            />
          }
          label={t("create_course_with_ai.automatically_write_content_for_all_topics")}
          className="font-medium text-black"
        />
        <Button
          variant="contained"
          className="w-full px-4 py-2 bg-primary"
          sx={{ textTransform: "capitalize", borderRadius: 2 }}
          onClick={handleGenerateDetailedTopics}
          data-testid="btn-generate-detailed-topics"
          disabled={isLoadingGenerateDetailedTopic}
        >
          {t("create_course_with_ai.generate_detailed_topics")}
        </Button>
      </Stack>
    </div>
  );
};

export default CourseReview;
