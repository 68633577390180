import React, { createContext, useCallback, useContext, useEffect, useMemo } from "react";
import {
  calculateAnswerStatsByTopic,
  calculateCourseAnswerStats,
} from "components/training/components/LearnCourseView/utils";
import { ViewModeType } from "components/training/types";
import { useGetListTopics } from "hooks/useCourse";
import { useGetLearnProgressOfCourseTraining, useGetQuestionCourseTraining } from "hooks/useTraining";
import { useParams } from "react-router-dom";
import { TopicType } from "types/topic";
import { CountAnswersByTopicType, CourseOverallAnswerStatsType, QuizData } from "types/training";

export interface TrainingContextType {
  viewModeType: ViewModeType;
  currentTopicSelected: TopicType;
  currentTopicQuizSelected?: string;
  handleCurrentAboutCourseChange: () => void;
  handleCurrentTopicChange: (topic: TopicType) => void;
  handleCurrentTopicQuizChange: (topic: TopicType, topicQuiz: string) => void;
  handleNextTopic: () => void;
  handleRefetchListTopics: () => void;
  handleRefetchProgressCourse: () => void;
  questionsInTopic: QuizData[];
  listTopicsInCourse: TopicType[];
  countAnswersByTopic: CountAnswersByTopicType;
  courseOverallAnswerStats: CourseOverallAnswerStatsType;
}

const defaultContextValue: TrainingContextType = {
  viewModeType: "topic",
  currentTopicSelected: {
    course_id: 0,
    course_name: "",
    id: 0,
    name: "",
    creator_name: "",
    uiIndex: 0,
    description: "",
    order_value: 0,
    questions_count: 0,
  },
  currentTopicQuizSelected: undefined,
  handleCurrentAboutCourseChange: () => {},
  handleCurrentTopicChange: () => {},
  handleCurrentTopicQuizChange: () => {},
  handleNextTopic: () => {},
  handleRefetchListTopics: () => {},
  handleRefetchProgressCourse: () => {},
  questionsInTopic: [],
  listTopicsInCourse: [],
  countAnswersByTopic: {},
  courseOverallAnswerStats: { percentage: 0, totalAnswered: 0, totalQuestions: 0, totalQuestionsInTopics: {} },
};

export const TrainingContext = createContext<TrainingContextType>(defaultContextValue);

const TrainingProvider = ({ children }: { children: React.ReactNode }) => {
  const { course_id: courseId } = useParams<{ course_id: string }>();
  const [currentTopicSelected, setCurrentTopicSelected] = React.useState<TopicType>(
    defaultContextValue.currentTopicSelected,
  );
  const [currentTopicQuizSelected, setCurrentTopicQuizSelected] = React.useState<string>();
  const [viewModeType, setViewModeType] = React.useState<ViewModeType>("topic");

  const { data: courseTraining, refetch: refetchCourseTraining } = useGetQuestionCourseTraining(courseId, true);
  const { data: dataTopics, refetch: refetchListTopics } = useGetListTopics(courseId);
  const { data: dataProgressCourse, refetch: refetchProgressCourse } = useGetLearnProgressOfCourseTraining(courseId);

  const listTopicsInCourse: TopicType[] = useMemo(() => {
    if (!dataTopics || !dataProgressCourse) return [];

    return dataTopics.data.map((item, index, array) => {
      const isCompleted = dataProgressCourse.completed_topic_ids.includes(item.id);
      const isSeen = dataProgressCourse.seen_topic_ids.includes(item.id);
      const isLastTopic = index === array.length - 1;
      const uiIndex = index + 1;

      return {
        ...item,
        isCompleted,
        isSeen,
        isLastTopic,
        uiIndex,
      };
    });
  }, [dataTopics, dataProgressCourse]);

  useEffect(() => {
    const topic = listTopicsInCourse.find(item => item.id === currentTopicSelected.id);
    if (topic) {
      setCurrentTopicSelected(topic);
    }
  }, [listTopicsInCourse]);

  const countAnswersByTopic: CountAnswersByTopicType = useMemo(() => {
    const answers = dataProgressCourse?.correct_answer_questions_in_topics;
    if (!answers) return {};

    return calculateAnswerStatsByTopic({ answers });
  }, [dataProgressCourse]);

  const courseOverallAnswerStats: CourseOverallAnswerStatsType = useMemo(() => {
    const learnedQuestionsInTopics = dataProgressCourse?.learned_questions_in_topics;
    if (!learnedQuestionsInTopics)
      return { totalAnswered: 0, totalQuestions: 0, percentage: 0, totalQuestionsInTopics: {} };

    return calculateCourseAnswerStats({ learnedQuestionsInTopics });
  }, [dataProgressCourse]);

  const questionsInTopic = useMemo(() => {
    if (!courseTraining || !currentTopicSelected) return [];

    return courseTraining.data.filter(item => item.course_thread_id === currentTopicSelected.id);
  }, [courseTraining, currentTopicSelected]);

  const handleRefetchListTopics = useCallback(() => {
    refetchListTopics();
    refetchProgressCourse();
    refetchCourseTraining();
  }, [refetchListTopics, refetchProgressCourse, refetchCourseTraining]);

  const handleRefetchProgressCourse = useCallback(() => {
    refetchProgressCourse();
  }, [refetchProgressCourse]);

  const handleCurrentAboutCourseChange = useCallback(() => {
    setCurrentTopicSelected(defaultContextValue.currentTopicSelected);
    setCurrentTopicQuizSelected(undefined);
    setViewModeType("course");
  }, []);

  const handleCurrentTopicChange = useCallback((topic: TopicType) => {
    setCurrentTopicSelected(topic);
    setCurrentTopicQuizSelected(undefined);
    setViewModeType("topic");
  }, []);

  const handleCurrentTopicQuizChange = useCallback((topic: TopicType, quizId: string) => {
    setCurrentTopicSelected(topic);
    setCurrentTopicQuizSelected(quizId);
    setViewModeType("quiz");
  }, []);

  const handleNextTopic = useCallback(() => {
    const currentIndex = (currentTopicSelected?.uiIndex ?? 0) + 1;
    const nextTopic = listTopicsInCourse.find(item => item.uiIndex === currentIndex);

    if (!nextTopic) return;

    handleCurrentTopicChange(nextTopic);
  }, [currentTopicSelected, handleCurrentTopicChange, listTopicsInCourse]);

  const value = useMemo(
    () => ({
      viewModeType,
      currentTopicSelected,
      currentTopicQuizSelected,
      questionsInTopic,
      listTopicsInCourse,
      handleCurrentAboutCourseChange,
      handleCurrentTopicChange,
      handleCurrentTopicQuizChange,
      handleNextTopic,
      handleRefetchListTopics,
      handleRefetchProgressCourse,
      countAnswersByTopic,
      courseOverallAnswerStats,
    }),
    [
      viewModeType,
      currentTopicSelected,
      currentTopicQuizSelected,
      questionsInTopic,
      listTopicsInCourse,
      countAnswersByTopic,
      courseOverallAnswerStats,
      handleNextTopic,
      handleRefetchListTopics,
      handleRefetchProgressCourse,
    ],
  );
  return <TrainingContext.Provider value={value}>{children}</TrainingContext.Provider>;
};

export default TrainingProvider;

export const useTrainingContext = () => useContext(TrainingContext);
