import React from "react";
import Stack from "@mui/material/Stack";
import CourseHeaderView from "components/training/components/CourseHeaderView";
import Curriculum from "./components/Curriculum";
import Content from "./components/Content";

interface IViewProps {}

const View: React.FC<IViewProps> = () => {
  return (
    <>
      <CourseHeaderView />
      <Stack direction="row" gap={2} className="container bg-[#F4F4F4] mb-4 pt-4">
        <Curriculum />

        <Content />
      </Stack>
    </>
  );
};

export default View;
