import React from "react";
import icon from "./base";

export default icon("Close", ({ fill = "#000000" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M3.5 3.5L8 8M8 8L3.5 12.5M8 8L12.5 12.5M8 8L12.5 3.5"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
