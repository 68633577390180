import Stack from "@mui/material/Stack";
import React from "react";
import pluralize from "pluralize";
import { useTranslation } from "react-i18next";

const BoxItemView = ({ icon, label, className }: { icon: React.ReactNode; label: string; className?: string }) => {
  const { i18n } = useTranslation();
  const isLanguageEn = i18n.resolvedLanguage === "en";

  return (
    <Stack direction="row" className={`text-white text-sm py-2 px-4 bg-[#5CA8B73D] rounded-full ${className}`} gap={1}>
      {icon}
      <span className="font-medium">{isLanguageEn ? pluralize(label) : label}</span>
    </Stack>
  );
};

export default BoxItemView;
