import React from "react";
// @ts-ignore
import { markdown } from "markdown";
import CheckYesIcon from "assets/icons/check-yes";
import CheckNoIcon from "assets/icons/check-no";

const AnswerMarkdownPreviewView = ({ text, icon }: { text: string; icon: "yes" | "no" | "none" }) => {
  let str_markdown = markdown
    .toHTML(text)
    .replace("<p>", "")
    .replace("</p>", "");

  return (
    <div className="w-full text-base text-[#313B3D] flex items-center justify-between">
      <span className="flex-1" dangerouslySetInnerHTML={{ __html: str_markdown }} />
      {icon === "yes" && <CheckYesIcon width={16} />}
      {icon === "no" && <CheckNoIcon width={16} />}
    </div>
  );
};

export default AnswerMarkdownPreviewView;
