import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useGetListQuestionOfTopic } from "hooks/useCourse";
import { TopicType } from "types/topic";
import { useTranslation } from "react-i18next";
import GeneratingView from "components/shared/GeneratingView";
import QuestionItem from "./QuestionItem";

interface IQuestionDetailProps {
  currentTopicSelected: TopicType;
  isTopicSelectedGenerating: boolean;
}

const QuestionDetail: React.FC<IQuestionDetailProps> = ({ currentTopicSelected, isTopicSelectedGenerating }) => {
  const { t } = useTranslation();

  const { data: dataQuestion } = useGetListQuestionOfTopic(
    currentTopicSelected.course_id.toString(),
    currentTopicSelected.id.toString(),
  );

  if (isTopicSelectedGenerating || !dataQuestion) {
    return <GeneratingView label={t("question.your_question_is_being_generated")} />;
  }

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography className="font-medium uppercase text-sm text-[#313B3D]">
          {t("question.topic", {
            index: currentTopicSelected.uiIndex,
          })}
        </Typography>
        <Typography className="font-bold text-[2rem] text-[#313B3D]">Quiz</Typography>
      </Stack>

      <Stack gap={5}>
        {dataQuestion.data.map((item, index) => (
          <QuestionItem key={`question-${item.id}`} question={item} index={index + 1} />
        ))}
      </Stack>
    </Stack>
  );
};

export default QuestionDetail;
