/* eslint-disable max-len */
import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import AddIcon from "assets/icons/add";
import { toast } from "react-toastify";
import { useAddTopicToOrganization } from "hooks/useTopic";
import { useTranslation } from "react-i18next";
import { scrollToElement } from "utilities/scroll";
import { TopicType } from "types/topic";

interface IAddTopicButtonProps {
  courseId: string;
  refetch: () => void;
  handleCurrentTopicChange: (topic: TopicType) => void;
}

const AddTopicButton: React.FC<IAddTopicButtonProps> = ({ courseId, refetch, handleCurrentTopicChange }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useAddTopicToOrganization(
    {
      id: 0,
      name: t("topic.untitled"),
      description: t("topic.add_content_here"),
      course_id: Number(courseId),
    },
    data => {
      if (data) {
        toast.success(t("global.added_successfully"));
        refetch();

        setTimeout(() => {
          scrollToElement(`topic_item_${data.topic.id}`);
          handleCurrentTopicChange(data.topic);
        }, 1100);
      }
    },
    error => {
      toast.error(error);
    },
  );

  const handleAddTopic = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <Button
      variant="outlined"
      className="flex items-center gap-2 py-2 text-sm font-bold normal-case rounded-lg text-primary border-primary w-fit"
      onClick={handleAddTopic}
      disabled={isLoading}
      data-testid="btn-add-topic"
    >
      <AddIcon width={24} /> {t("topic.add_topic")}
    </Button>
  );
};

export default AddTopicButton;
