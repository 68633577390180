import React from "react";
import icon from "./base";

export default icon("Saving", ({ fill = "#FFB319" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect x="7.33398" width="1.33333" height="4" rx="0.666667" fill={fill} />
    <rect x="7.33398" y="12" width="1.33333" height="4" rx="0.666667" fill={fill} fillOpacity="0.56" />
    <rect
      y="8.6665"
      width="1.33333"
      height="4"
      rx="0.666667"
      transform="rotate(-90 0 8.6665)"
      fill={fill}
      fillOpacity="0.56"
    />
    <rect
      x="12"
      y="8.6665"
      width="1.33333"
      height="4"
      rx="0.666667"
      transform="rotate(-90 12 8.6665)"
      fill={fill}
      fillOpacity="0.56"
    />
    <rect
      x="1.87109"
      y="2.81445"
      width="1.33333"
      height="4"
      rx="0.666667"
      transform="rotate(-45 1.87109 2.81445)"
      fill={fill}
      fillOpacity="0.56"
    />
    <rect
      x="10.3574"
      y="11.2998"
      width="1.33333"
      height="4"
      rx="0.666667"
      transform="rotate(-45 10.3574 11.2998)"
      fill={fill}
      fillOpacity="0.56"
    />
    <rect
      x="2.81445"
      y="14.1284"
      width="1.33333"
      height="4"
      rx="0.666667"
      transform="rotate(-135 2.81445 14.1284)"
      fill={fill}
      fillOpacity="0.56"
    />
    <rect
      x="11.2988"
      y="5.64307"
      width="1.33333"
      height="4"
      rx="0.666667"
      transform="rotate(-135 11.2988 5.64307)"
      fill={fill}
      fillOpacity="0.56"
    />
  </svg>
));
