import React, { useEffect } from "react";
import { Stack, Typography } from "@mui/material";
import CurriculumItem from "components/shared/CurriculumItem";
import { TopicType } from "types/topic";
import AddTopicButton from "components/course/CourseEdit/components/AddTopicButton";
import { useTranslation } from "react-i18next";
import { useCourseViewOrEditContext, useCourseViewOrEditSelector } from "contexts/CourseViewOrEditContext";

interface ICurriculumProps {
  courseId: string;
  currentTopicSelected?: TopicType;
  currentTopicQuizSelected?: string;
  handleCurrentTopicChange: (topic: TopicType) => void;
  handleCurrentTopicQuizChange: (topic: TopicType, quizId: string) => void;
}

const Curriculum: React.FC<ICurriculumProps> = ({
  courseId,
  currentTopicSelected,
  currentTopicQuizSelected,
  handleCurrentTopicChange,
  handleCurrentTopicQuizChange,
}) => {
  const { t } = useTranslation();

  const { refetchListTopics } = useCourseViewOrEditContext();
  const dataTopics = useCourseViewOrEditSelector(state => state.dataTopics);

  useEffect(() => {
    if (!dataTopics || dataTopics.data.length === 0 || currentTopicSelected) return;

    handleCurrentTopicChange(dataTopics.data[0]);
  }, [dataTopics, currentTopicSelected]);

  if (!dataTopics) return <></>;

  const isHasMultipleTopic = dataTopics.data.length > 1;

  return (
    <Stack
      className="w-[339px] h-screen top-20 bg-white rounded-2xl"
      style={{
        position: "sticky",
        top: "5rem",
        height: "auto",
        maxHeight: "calc(100vh - 160px)",
      }}
      gap={1}
    >
      <Typography className="bg-[#E3E6EB] text-xl font-bold py-3 px-4 rounded-t-2xl">
        {t("course.curriculum")}
      </Typography>
      <Stack className="flex-1 px-4 py-3 overflow-y-hidden hover:overflow-y-auto" gap={2}>
        {dataTopics &&
          dataTopics.data.map((topic, index, array) => {
            const quizTopicId = `quiz_${topic.id}`;
            const isActiveTopic = currentTopicSelected?.id === topic.id;
            const isActiveQuestion = currentTopicQuizSelected === quizTopicId;
            const uiIndex = index + 1;
            const isFirst = index === 0;
            const isLast = index === array.length - 1;

            const newTopic = { ...topic, uiIndex };

            return (
              <CurriculumItem
                key={`${topic.id}`}
                rowIndex={uiIndex}
                topicId={topic.id}
                topicName={topic.name}
                questionCount={topic.questions_count}
                isActiveTopic={!isActiveQuestion && isActiveTopic}
                isActiveQuestion={isActiveQuestion}
                handleTopicClick={() => handleCurrentTopicChange(newTopic)}
                handleQuestionClick={() => handleCurrentTopicQuizChange(newTopic, quizTopicId)}
                refetchListTopics={refetchListTopics}
                isHasMultipleTopic={isHasMultipleTopic}
                isFirst={isFirst}
                isLast={isLast}
              />
            );
          })}
      </Stack>

      <Stack className="px-4 py-2">
        <AddTopicButton
          courseId={courseId}
          refetch={refetchListTopics}
          handleCurrentTopicChange={handleCurrentTopicChange}
        />
      </Stack>
    </Stack>
  );
};

export default Curriculum;
