import React from "react";
import icon from "./base";

export default icon("Eyes", ({ fill = "#000000" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5334 4.04604C13.861 4.99331 14.8819 6.30882 15.47 7.83C15.5097 7.93985 15.5097 8.06015 15.47 8.17C14.8819 9.69118 13.861 11.0067 12.5334 11.954C11.2058 12.9012 9.62974 13.4387 8 13.5C6.37026 13.4387 4.79419 12.9012 3.4666 11.954C2.13901 11.0067 1.11808 9.69118 0.530001 8.17C0.490285 8.06015 0.490285 7.93985 0.530001 7.83C1.11808 6.30882 2.13901 4.99331 3.4666 4.04604C4.79419 3.09878 6.37026 2.56129 8 2.5C9.62974 2.56129 11.2058 3.09878 12.5334 4.04604ZM1.535 8C2.55 10.535 5.35 12.5 8 12.5C10.65 12.5 13.45 10.535 14.465 8C13.45 5.465 10.65 3.5 8 3.5C5.35 3.5 2.55 5.465 1.535 8ZM6.33329 5.50559C6.82664 5.17595 7.40666 5 8 5C8.79565 5 9.55871 5.31607 10.1213 5.87868C10.6839 6.44129 11 7.20435 11 8C11 8.59334 10.8241 9.17336 10.4944 9.66671C10.1648 10.1601 9.69623 10.5446 9.14805 10.7716C8.59987 10.9987 7.99667 11.0581 7.41473 10.9424C6.83279 10.8266 6.29824 10.5409 5.87868 10.1213C5.45912 9.70176 5.1734 9.16721 5.05765 8.58527C4.94189 8.00333 5.0013 7.40013 5.22836 6.85195C5.45543 6.30377 5.83994 5.83524 6.33329 5.50559ZM6.88886 9.66294C7.21776 9.8827 7.60444 10 8 10C8.53043 10 9.03914 9.78929 9.41421 9.41421C9.78929 9.03914 10 8.53043 10 8C10 7.60444 9.8827 7.21776 9.66294 6.88886C9.44318 6.55996 9.13082 6.30362 8.76537 6.15224C8.39992 6.00087 7.99778 5.96126 7.60982 6.03843C7.22186 6.1156 6.86549 6.30608 6.58579 6.58579C6.30608 6.86549 6.1156 7.22186 6.03843 7.60982C5.96126 7.99778 6.00087 8.39991 6.15224 8.76537C6.30362 9.13082 6.55996 9.44318 6.88886 9.66294Z"
      fill={fill}
    />
  </svg>
));
