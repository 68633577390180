import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useGetGenerateQuestionsStatus } from "hooks/useQuestion";
import CourseViewOrEditProvider from "contexts/CourseViewOrEditContext";
import CourseViewOrEdit from "../CourseViewOrEdit";

const View = () => {
  const { course_id: courseId } = useParams<{ course_id: string }>();

  const { data: dataCourseStatus } = useGetGenerateQuestionsStatus(courseId);

  const [isCompleted, isHasTopic, topicsGenerating] = useMemo(() => {
    if (!dataCourseStatus) return [false, false, []];

    const isCompleted = dataCourseStatus.status === "completed";
    const isHasTopic = dataCourseStatus.waiting_questions_in_topics?.length > 0;
    const topicsGenerating = dataCourseStatus.waiting_questions_in_topics ?? [];

    return [isCompleted, isHasTopic, topicsGenerating];
  }, [dataCourseStatus]);

  return (
    <CourseViewOrEditProvider>
      <CourseViewOrEdit
        action="view"
        isCompleted={isCompleted}
        isHasTopic={isHasTopic}
        topicsGenerating={topicsGenerating}
      />
    </CourseViewOrEditProvider>
  );
};

export default View;
