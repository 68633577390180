import React from "react";
import CourseProvider from "contexts/CourseContext";
import View from "./view";

const Container: React.FC = () => {
  return (
    <CourseProvider>
      <View />
    </CourseProvider>
  );
};

export default Container;
