import React from "react";
import Stack from "@mui/material/Stack";
import TopicDetail from "components/course/CourseView/components/TopicDetail";
import { useTrainingContext } from "contexts/TrainingContext";
import AboutCourseView from "./AboutCourseView";
import LearnCourseView from "./LearnCourseView";

interface IContentProps {}
const Content: React.FC<IContentProps> = () => {
  const {
    viewModeType,
    currentTopicSelected,
    handleRefetchListTopics,
    handleCurrentTopicQuizChange,
  } = useTrainingContext();

  return (
    <Stack className="flex-1 rounded-2xl p-6 bg-white">
      {viewModeType === "course" && <AboutCourseView />}

      {viewModeType === "topic" && currentTopicSelected && (
        <TopicDetail
          key={`topic_detail_${currentTopicSelected.id}`}
          currentTopicSelected={currentTopicSelected}
          isLearning
          handleCompleteAndContinue={() => {
            handleRefetchListTopics();
            handleCurrentTopicQuizChange(currentTopicSelected, `quiz_${currentTopicSelected.id}`);
          }}
        />
      )}

      {viewModeType === "quiz" && currentTopicSelected && (
        <LearnCourseView key={`learn_course_topic_${currentTopicSelected.id}`} />
      )}
    </Stack>
  );
};

export default Content;
