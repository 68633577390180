import React, { useEffect, useRef, useState } from "react";
import { Tooltip, Typography } from "@mui/material";

const TruncatedTextWithTooltip = ({ text, className }: { text: string; className: string }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (textRef.current) {
      const element = textRef.current;
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  }, [text]);

  return (
    <Tooltip title={text} placement="top" arrow disableHoverListener={!isTruncated}>
      <Typography ref={textRef} className={className}>
        {text}
      </Typography>
    </Tooltip>
  );
};

export default TruncatedTextWithTooltip;
