import React from "react";
import icon from "./base";

export default icon("MoveUp", ({ fill = "black" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      d="M9 2L4 7L4.705 7.705L8.5 3.915V12.5H2V13.5H8.5C8.76522 13.5 9.01957 13.3946 9.20711 13.2071C9.39464 13.0196 9.5 12.7652 9.5 12.5V3.915L13.295 7.705L14 7L9 2Z"
      fill={fill}
    />
  </svg>
));
