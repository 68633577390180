import React, { FunctionComponent, ReactNode } from "react";
import Box from "@mui/material/Box";
import camelCase from "lodash/camelCase";
import kebabCase from "lodash/kebabCase";
import upperFirst from "lodash/upperFirst";

import { IconProps } from "./types";

const icon = (name: string, render: (props: IconProps) => ReactNode) => {
  const Icon: FunctionComponent<IconProps> = props => {
    const height = props.height ?? props.width ?? "1em";
    const width = props.width ?? props.height ?? "1em";

    return (
      <Box className="icon" data-testid={kebabCase(`icon ${name}`)} display="flex" height={height} width={width}>
        {render(props)}
      </Box>
    );
  };
  Icon.displayName = upperFirst(camelCase(`icon ${name}`));
  return Icon;
};

export default icon;
