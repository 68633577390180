import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface IAnswerItemProps {
  answer: string;
  correct: boolean;
}
const AnswerItem: React.FC<IAnswerItemProps> = ({ answer, correct }) => {
  return (
    <Stack direction="row" className={`${correct ? "bg-[#E7F2F4]" : "bg-[#F5F5F5]"} p-2 rounded-lg`}>
      <Typography className="text-sm">{answer}</Typography>
    </Stack>
  );
};

export default AnswerItem;
