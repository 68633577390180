import React from "react";

const LoadingOverlayView: React.FC = () => {
  return (
    <div className="loadingOverlay" data-testid="loading-overlay">
      <div className="loadingOverlayContent">
        <img height="40px" src="/images/loading.gif" />
      </div>
    </div>
  );
};
export default LoadingOverlayView;
