import React from "react";
import Stack from "@mui/material/Stack";
import { ViewModeType } from "components/course/CourseViewOrEdit/types";
import { useCourseViewOrEditSelector } from "contexts/CourseViewOrEditContext";
import { TopicType } from "types/topic";
import TopicEdit from "./TopicEdit";
import QuestionEdit from "./QuestionEdit";

interface IContentProps {
  viewModeType: ViewModeType;
  isTopicSelectedGenerating: boolean;
  handleCurrentTopicQuizChange: (topic: TopicType, quizId: string) => void;
}
const Content: React.FC<IContentProps> = ({
  viewModeType,
  isTopicSelectedGenerating,
  handleCurrentTopicQuizChange,
}) => {
  const { currentTopicSelected } = useCourseViewOrEditSelector(state => state);

  return (
    <Stack className="flex-1 p-6 bg-white rounded-2xl">
      {viewModeType === "topic" && currentTopicSelected && (
        <TopicEdit
          key={`edit_topic_${currentTopicSelected.id}`}
          currentTopicSelected={currentTopicSelected}
          handleCurrentTopicQuizChange={handleCurrentTopicQuizChange}
        />
      )}
      {viewModeType === "quiz" && currentTopicSelected && (
        <QuestionEdit
          currentTopicSelected={currentTopicSelected}
          isTopicSelectedGenerating={isTopicSelectedGenerating}
        />
      )}
    </Stack>
  );
};

export default Content;
