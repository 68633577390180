import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "assets/icons/arrow-right";
import TopicOptionsButton from "components/course/CourseEdit/components/TopicOptionsButton";
import CheckYesIcon from "assets/icons/check-yes";
import CheckEmptyIcon from "assets/icons/check-empty";
import { useTranslation } from "react-i18next";

const CurriculumItem = ({
  rowIndex,
  topicId,
  topicName,
  questionCount,
  isActiveTopic,
  isActiveQuestion,
  handleTopicClick,
  handleQuestionClick,
  refetchListTopics,
  isHasMultipleTopic,
  isLearning,
  isCompleted,
  isSeen,
  isFirst,
  isLast,
}: {
  rowIndex: number;
  topicId: number;
  topicName: string;
  questionCount: number;
  isActiveTopic: boolean;
  isActiveQuestion: boolean;
  handleTopicClick: () => void;
  handleQuestionClick: () => void;
  refetchListTopics: () => void;
  isHasMultipleTopic: boolean;
  isLearning?: boolean;
  isCompleted?: boolean;
  isSeen?: boolean;
  isFirst?: boolean;
  isLast?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <Stack className="gap-2" id={`topic_item_${topicId}`}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography className="text-sm font-medium text-[#313B3D] uppercase">
          {t("question.topic", { index: rowIndex })}
        </Typography>
        {!isLearning && isHasMultipleTopic && (
          <TopicOptionsButton
            topicId={topicId}
            refetchListTopic={refetchListTopics}
            isFirst={isFirst}
            isLast={isLast}
          />
        )}
      </Stack>

      <Stack gap={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={`cursor-pointer border border-solid border-[#E4E7EC] px-4 py-[10px] rounded-lg ${
            isActiveTopic ? "bg-[#0B3037] text-white" : "bg-[#357E8105]"
          }`}
          onClick={handleTopicClick}
          gap={1}
          data-testid={`topic_item_${topicId}`}
        >
          {isLearning && (
            <>
              {isSeen ? (
                <CheckYesIcon width={16} />
              ) : (
                <CheckEmptyIcon width={16} fill={isActiveTopic ? "white" : "#0B3037"} />
              )}
            </>
          )}
          <Typography className="flex-1 text-sm font-semibold truncate">{topicName}</Typography>
          <ArrowRightIcon width={24} fill={isActiveTopic ? "white" : "#0B3037"} />
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={`cursor-pointer border border-solid border-[#E4E7EC] px-4 py-[10px] rounded-lg ${
            isActiveQuestion ? "bg-[#0B3037] text-white" : "bg-[#357E8105] text"
          }`}
          onClick={handleQuestionClick}
          gap={1}
          data-testid={`quiz_item_${topicId}`}
        >
          {isLearning && (
            <>
              {isCompleted ? (
                <CheckYesIcon width={16} />
              ) : (
                <CheckEmptyIcon width={16} fill={isActiveQuestion ? "white" : "#0B3037"} />
              )}
            </>
          )}
          <Typography className="flex-1 text-sm font-semibold">
            Q&A{" "}
            <span className={`${isActiveQuestion ? "text-white" : "text-[#555F60]"} font-medium text-xs`}>
              ({t("question.question_count", { count: questionCount })})
            </span>
          </Typography>
          <ArrowRightIcon width={24} fill={isActiveQuestion ? "white" : "#0B3037"} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CurriculumItem;
