import React from "react";
import icon from "./base";

export default icon("Detail", () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <g clipPath="url(#clip0_550_2273)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35986 4.32129H20.9999V19.5133C21.0001 20.6128 20.5691 21.6686 19.7996 22.4538C19.03 23.2391 17.9831 23.6913 16.8839 23.7133H6.35986V4.32129ZM7.55986 5.52129V22.5133H16.8659C17.65 22.496 18.3962 22.1724 18.9446 21.6116C19.4931 21.0509 19.8001 20.2976 19.7999 19.5133V5.52129H7.55986Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6039 9.50566H9.75586V8.30566H17.6039V9.50566ZM17.6039 12.9137H9.75586V11.7137H17.6039V12.9137ZM17.6039 16.3217H9.75586V15.1217H17.6039V16.3217ZM14.2439 19.7297H9.75586V18.5297H14.2439V19.7297Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0.913574H18.204V4.92157H17.004V2.11357H4.2V19.6696H6.96V20.8696H3V0.913574Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_550_2273">
        <rect width="24" height="24" fill="white" transform="translate(0 0.313477)" />
      </clipPath>
    </defs>
  </svg>
));
