import React from "react";
// @ts-ignore
import sanitizeHtml from "sanitize-html";

type RenderHtmlProps = {
  htmlContent: string;
  sanitize?: boolean;
  className?: string;
};

const HTMLPreview: React.FC<RenderHtmlProps> = ({ htmlContent, sanitize = true, className }) => {
  const safeHtml = sanitize
    ? sanitizeHtml(htmlContent, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat(["img", "h1", "h2"]),
        allowedAttributes: {
          ...sanitizeHtml.defaults.allowedAttributes,
          img: ["src", "alt"],
        },
      })
    : htmlContent;

  return (
    <div
      data-testid="html-preview"
      className={`${className ?? ""} prose max-w-full`}
      dangerouslySetInnerHTML={{ __html: safeHtml }}
    />
  );
};

export default HTMLPreview;
