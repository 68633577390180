import React, { useCallback, useMemo } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { CheckAnswerForCourseQuestionTrainingResponse, QuizData } from "types/training";
import AnswerMarkdownPreviewView from "components/shared/AnswerMarkdownPreviewView";
import ArrowRightIcon from "assets/icons/arrow-right";
import { useCheckAnswerForCourseQuestionTraining } from "hooks/useTraining";
import { useTranslation } from "react-i18next";
import DetailIcon from "assets/icons/detail";
import AreaIcon from "assets/icons/area";
import HTMLPreview from "components/shared/HTMLPreview";
import { useTrainingContext } from "contexts/TrainingContext";
import Link from "@mui/material/Link";
import { determineAnswerBackgroundClass } from "../utils";

interface ILearnCourseAnswerViewProps {
  currentQuestion: QuizData;
  handleNextQuestion: () => void;
}

const LearnCourseAnswerView: React.FC<ILearnCourseAnswerViewProps> = ({ currentQuestion, handleNextQuestion }) => {
  const { t } = useTranslation();
  const [answerSelected, setAnswerSelected] = React.useState<number>(-1);
  const [answer, setAnswer] = React.useState<CheckAnswerForCourseQuestionTrainingResponse>();
  const { currentTopicSelected, handleRefetchProgressCourse } = useTrainingContext();
  const getAnswerFromAnswerKey = useCallback(
    (answerIndex: number) => {
      if (!currentQuestion) return "";

      return currentQuestion.questions[answerIndex].answer;
    },
    [currentQuestion],
  );

  const answerInputed = useMemo(() => {
    if (answerSelected < 0) return "";

    return getAnswerFromAnswerKey(answerSelected);
  }, [answerSelected, getAnswerFromAnswerKey]);

  const { mutate: mutateCheckAnswer, isLoading: isLoadingCheckAnswer } = useCheckAnswerForCourseQuestionTraining(
    {
      id: currentQuestion.id,
      answer_input: answerInputed,
      course_id: currentTopicSelected.course_id.toString(),
      status: "check_answer",
    },
    data => {
      setAnswer(data);
      handleRefetchProgressCourse();
    },
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (answer) return;

      setAnswerSelected(Number((event.target as HTMLInputElement).value));
    },
    [answer],
  );

  const handleNextQuestionClick = useCallback(() => {
    handleNextQuestion();
    setAnswer(undefined);
    setAnswerSelected(-1);
  }, [handleNextQuestion]);

  const getExtraClasses = useCallback(
    (answerIndex: number) => {
      const currentAnswer = getAnswerFromAnswerKey(answerIndex);
      return determineAnswerBackgroundClass({
        answer,
        answerSelected,
        answerIndex,
        currentAnswer,
        answerInputed,
      });
    },
    [answer, answerInputed, answerSelected, getAnswerFromAnswerKey],
  );

  const isDisabledCheckAnswer = answerSelected < 0 || isLoadingCheckAnswer || !!answer;
  const isDisabledNextQuestion = answerSelected < 0 || isLoadingCheckAnswer || !answer;

  return (
    <Stack className="mt-10" gap={2}>
      <FormControl>
        <RadioGroup
          aria-labelledby="traing-course-answer-label"
          name="traing-course-answer-group"
          value={answerSelected}
          onChange={handleChange}
          data-testid="check_answer_group"
        >
          {currentQuestion.questions.map((question, index) => {
            const answerKey = `answer_${index}`;
            const extraClasses = getExtraClasses(index);
            const iconType =
              extraClasses === "bg-mt-medium-sea-green"
                ? "yes"
                : extraClasses === "bg-mt-bright-red-pink"
                ? "no"
                : "none";

            if (question.answer.length === 0) return <div key={answerKey} />;

            return (
              <FormControlLabel
                key={answerKey}
                value={index}
                data-testid={answerKey}
                className={`p-2 mx-0 rounded-lg ${extraClasses} mb-[10px]`}
                slotProps={{
                  typography: {
                    className: "text-base w-full",
                  },
                }}
                control={<Radio color="default" className="text-primary" />}
                label={<AnswerMarkdownPreviewView text={question.answer} icon={iconType} />}
              />
            );
          })}
        </RadioGroup>
      </FormControl>

      <Stack direction="row" alignItems="center" justifyContent="end" gap={2}>
        <Button
          className="h-10 px-3 normal-case border border-solid rounded-lg border-primary text-primary disabled:border-primary/20"
          disabled={isDisabledCheckAnswer}
          onClick={() => {
            mutateCheckAnswer();
          }}
          data-testid="check-answer-button"
        >
          {t("training.check_answer")}
        </Button>
        <Button
          className="flex items-center h-10 gap-2 px-3 text-white normal-case rounded-lg bg-primary hover:bg-primary/80 disabled:text-white/80 disabled:bg-primary/40"
          disabled={isDisabledNextQuestion}
          onClick={handleNextQuestionClick}
          data-testid="next-question-button"
        >
          {t("training.next")}
          <ArrowRightIcon width={24} height={24} />
        </Button>
      </Stack>

      {answer?.data.question_explanation && (
        <Stack className="p-4 bg-[#FBFAF3] rounded-2xl" gap={3}>
          {answer.data.question_explanation.explanation && (
            <Stack gap={1} data-testid="explanation-stack">
              <Typography className="flex items-center gap-2 text-base font-bold" component="div">
                <DetailIcon width={24} />
                {t("question.explanation")}:
              </Typography>
              <HTMLPreview className="text-base font-bold" htmlContent={answer.data.question_explanation.explanation} />
            </Stack>
          )}

          {answer.data.question_explanation.reference_page && (
            <Stack gap={1} data-testid="reference-page-stack">
              <Typography className="flex items-center gap-2 text-base font-bold" component="div">
                <AreaIcon width={24} />
                {t("question.reference_page")}:
              </Typography>

              <Link
                href={answer.data.question_explanation.document_url ?? "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="underline text-primary"
                data-testid="reference-page-link"
              >
                {t("question.page")} {answer.data.question_explanation.reference_page}
                {answer.data.question_explanation.reference_text &&
                  `: ${answer.data.question_explanation.reference_text}`}
              </Link>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export default LearnCourseAnswerView;
