import { Button } from "@mui/material";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import WelcomeImage from "assets/icons/welcome";
import GoogleIcon from "assets/icons/google";

const MSG_LOGIN_GOOGLE = "login-google";

const LoginView = () => {
  const { t } = useTranslation();
  // const organizationName = window.location.hostname.split(".")[0];
  const handleGoogleLoginClick = useCallback((event: React.MouseEvent<HTMLButtonElement>) => {
    if ((window as any).isNative) {
      event.preventDefault();
      window.postMessage(MSG_LOGIN_GOOGLE, "*");
    }
  }, []);
  const err = window.location.search.split("=")[1];

  return (
    <div className="container pt-10 text-center">
      <div className="p-10 mx-auto bg-white rounded-lg shadow-lg w-fit">
        <div className="flex justify-center">
          <WelcomeImage width={150} height={150} />
        </div>

        {err && err === "user_does_not_exist" && (
          <div className="text-sm italic text-center text-red-600" role="alert">
            {t("global.user_does_not_belong_to_this_organization")}
          </div>
        )}

        <p className="text-2xl text-[#313B3D] mt-10 mb-8">
          {t("global.welcome_to_the_training_platform")}
          <br />
          {t("global.login_to_continue")}
        </p>

        <Button
          onClick={handleGoogleLoginClick}
          LinkComponent="a"
          href="/auth/google_oauth2"
          variant="text"
          className="flex-row items-center gap-2 normal-case text-[#124954] text-base font-medium rounded-lg border border-solid border-[#124954] px-4 py-2"
          data-testid="google-login-button"
        >
          <GoogleIcon width={32} />
          {t("global.sign_in_with_google")}
        </Button>
      </div>
    </div>
  );
};

export default LoginView;
