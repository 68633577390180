import React, { useContext, useEffect } from "react";

import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Cookies from "universal-cookie";
import LoginView from "components/shared/LoginView";
import { AuthContext } from "./contexts/AuthContext";

import LoadingInPageView from "./components/shared/LoadingInPageView";
import Footer from "./layouts/main/Footer";
import Header from "./layouts/main/Header";
import { Switch } from "./routes";
import history from "./utilities/history";
import PageViewEvent from "./PageViewEvent";

const advancedMatchingPixel: any = {};
const optionPixels = {
  autoConfig: true,
  debug: false,
};

function App() {
  const { infoAppDashboard, refreshAppInfo, isLoading } = useContext(AuthContext);

  useEffect(() => {
    // @ts-ignore
    if (window.RAILS_ENV === "production") {
      // @ts-ignore
      ReactGA.initialize(window.GA_MEASUREMENT_ID);
      ReactPixel.init("221553698566333", advancedMatchingPixel, optionPixels);
    } else {
      ReactPixel.init("231084487711058", advancedMatchingPixel, optionPixels);
    }

    const cookies = new Cookies();
    const lng = cookies.get("lng");
    if (typeof lng === "undefined") {
      cookies.set("lng", "en");
    }
  }, []);

  if (isLoading || infoAppDashboard.is_setting_up) {
    return <LoadingInPageView isSettingUp={infoAppDashboard.is_setting_up} refreshAppInfo={() => refreshAppInfo()} />;
  }

  if (!infoAppDashboard.current_user_role) {
    return <LoginView />;
  }

  return (
    <Router history={history}>
      <div className="relative flex flex-col min-h-screen antialiased bg-mt-light-gray">
        <Header />
        <main className="relative flex-1">
          <Switch />
        </main>
        <Footer />
      </div>

      {/* @ts-ignore */}
      <ToastContainer />
      <PageViewEvent />
    </Router>
  );
}

export default App;
