import Button from "@mui/material/Button";
import { useGenerateTopicQuiz } from "hooks/useTopic";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";

interface IAddQuestionAiMakerButtonProps {
  threadId: string;
  onSuccess?: () => void;
  onClick?: () => void;
  isDisabled?: boolean;
}

const AddQuestionAiMakerButton: React.FC<IAddQuestionAiMakerButtonProps> = ({
  threadId,
  onSuccess,
  onClick,
  isDisabled,
}) => {
  const { t } = useTranslation();

  const { mutate: generateTopicQuiz, isLoading: isGeneratingTopicQuiz } = useGenerateTopicQuiz(
    Number(threadId),
    (data: any) => {
      if (data.success) {
        onSuccess?.();
        ReactGA.event("create_ai_quiz_maker", { threadId });
      } else {
        toast.error(data.error);
      }
    },
    error => {
      toast.error(error);
    },
  );

  const handleSubmitQuizMaker = useCallback(() => {
    generateTopicQuiz();
    onClick?.();
  }, [generateTopicQuiz, onClick]);

  return (
    <Button
      variant="contained"
      className="px-4 py-2 bg-primary w-fit h-fit"
      sx={{ textTransform: "capitalize", borderRadius: 2 }}
      onClick={handleSubmitQuizMaker}
      disabled={isGeneratingTopicQuiz || isDisabled}
      data-testid="btn-ai-quiz-maker-topic"
    >
      {t("topic.ai_quiz_maker")}
    </Button>
  );
};

export default AddQuestionAiMakerButton;
