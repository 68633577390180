import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import ByeImage from "assets/icons/bye";
const RequireAccessView = () => {
  const { t } = useTranslation();

  return (
    <div className="container pt-10 text-center">
      <div className="p-10 mx-auto bg-white rounded-lg shadow-lg w-fit">
        <div className="flex justify-center">
          <ByeImage width={150} height={150} />
        </div>
        <p className="font-bold text-[1.75rem] text-[#313B3D] mt-10 mb-8">
          {t("global.you_need_access_to_learn_this_course")}
        </p>

        <Button
          LinkComponent="a"
          href="/v2/dashboard/courses"
          variant="text"
          className="flex-row items-center gap-2 normal-case text-[#313B3D] text-xs font-bold rounded-lg border border-solid border-[#555F60] px-4 py-2"
          data-testid="back-to-home-button"
        >
          {t("global.back_to_home")}
        </Button>
      </div>
    </div>
  );
};

export default RequireAccessView;
