import { useMutation, useQuery } from "@tanstack/react-query";
import { GenerateQuestionStatusResponse, UpdateQuestionTypeRequest, UpdateQuestionTypeResponse } from "types/question";
import { fetchDataAsync, postDataAsync } from "utilities/http";

export const useGetGenerateQuestionsStatus = (courseId: string) => {
  return useQuery({
    queryKey: ["fetchGenerateQuestionsStatus", courseId],
    queryFn: async () => {
      const data = await fetchDataAsync<GenerateQuestionStatusResponse>(
        `/open_ai_generate_questions_status?course_id=${courseId}`,
        "GET",
      );
      return data;
    },
    enabled: !!courseId,
  });
};

export const useAddQuestionToOrganization = (
  payload: UpdateQuestionTypeRequest,
  onSuccess?: (data: any) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["addQuestionToOrganization", payload],
    async () => {
      const formData = new FormData();
      payload.timestamp = Date.now();
      formData.append("data", JSON.stringify(payload));

      const data = await fetchDataAsync<UpdateQuestionTypeResponse>(
        "/add_new_question_to_organization",
        "POST",
        formData,
      );
      return data;
    },
    {
      onSuccess: (data: any) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useUpdateQuestionToOrganization = (
  payload: UpdateQuestionTypeRequest,
  onSuccess?: (data: any) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["updateQuestionToOrganization", payload],
    async () => {
      const data = await postDataAsync<UpdateQuestionTypeResponse>("/update_question_in_organization", payload);
      return data;
    },
    {
      onSuccess: (data: any) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useDeleteQuestionToOrganization = (
  questionId: number,
  onSuccess?: (data: any) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["deleteQuestionToOrganization", questionId],
    async () => {
      const data = await fetchDataAsync<UpdateQuestionTypeResponse>(
        `/delete_question_in_organization?id=${questionId}`,
        "POST",
      );
      return data;
    },
    {
      onSuccess: (data: any) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message);
      },
    },
  );
};
