import React from "react";
import icon from "./base";

export default icon("ArrowRight", ({ fill = "white" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M8.625 5.25L15.375 12L8.625 18.75"
      stroke={fill}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
