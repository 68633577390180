import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import EditorView from "components/shared/EditorView";
import TextInputView from "components/shared/TextInputView";
import { AuthContext } from "contexts/AuthContext";
import { useCourseViewOrEditContext } from "contexts/CourseViewOrEditContext";
import { useRegenerateTopic, useUpdateTopicToOrganization } from "hooks/useTopic";
import debounce from "lodash/debounce";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { RegenerateTopicTypeResponse, TopicType } from "types/topic";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import sanitizeHtml from "sanitize-html";
import Button from "@mui/material/Button";
import ReactGA from "react-ga4";
import TopicAiWriterPopup from "./TopicAiWriterPopup";
import AddQuestionAiMakerButton from "./AddQuestionAiMakerButton";

interface ITopicEditProps {
  currentTopicSelected: TopicType;
  handleCurrentTopicQuizChange: (topic: TopicType, quizId: string) => void;
}

const TopicEdit: React.FC<ITopicEditProps> = ({ currentTopicSelected, handleCurrentTopicQuizChange }) => {
  const [topicName, setTopicName] = React.useState(currentTopicSelected.name);
  const [topicDescription, setTopicDescription] = React.useState(currentTopicSelected.description);
  const [openModal, setOpenModal] = useState(false);

  const { handleShowSaving, handleLoading, refetchListTopics } = useCourseViewOrEditContext();
  const { infoAppDashboard } = useContext(AuthContext);
  const { t } = useTranslation();

  const { mutate, isLoading } = useUpdateTopicToOrganization(
    {
      id: currentTopicSelected.id,
      name: topicName.trim(),
      description: sanitizeHtml(topicDescription.trim()),
      course_id: currentTopicSelected.course_id,
    },
    data => {
      if (data && data.errors) {
        handleShowSaving(false);

        let message = "";
        if (data.errors["name"]) {
          message = t(`topic.${data.errors.name}`, {
            word_limit: infoAppDashboard?.thread_name_word_limit,
          });
        }
        if (data.errors["description"]) {
          message = t(`topic.${data.errors.description}`, {
            word_limit: infoAppDashboard?.thread_description_word_limit,
          });
        }
        toast.error(message);
      } else {
        refetchListTopics();
      }
    },
    error => {
      toast.error(error);
    },
  );

  const { mutate: regenerateTopic, isLoading: isRegeneratingTopic } = useRegenerateTopic(
    currentTopicSelected.id,
    (data: RegenerateTopicTypeResponse) => {
      if (data.success) {
        setTopicDescription(data.topic_description);
        setOpenModal(false);
        ReactGA.event("create_ai_writer_topic", { threadId: currentTopicSelected.id });
      } else {
        toast.error(data.error);
      }
    },
    error => {
      toast.error(error);
    },
  );

  const getCounter = useCallback((text: string) => {
    const counter = text.split(/\n| /);
    const filted_counter = counter.filter(el => {
      return el != "";
    });
    return filted_counter.length;
  }, []);

  const counter = useMemo(() => {
    return getCounter(topicDescription);
  }, [topicDescription]);

  const handleSubmitAiWriter = useCallback(
    (description: string, replaceText: boolean, wordCount: number) => {
      regenerateTopic({ content: description, wordCount, isReplace: replaceText });
    },
    [regenerateTopic],
  );

  const debouncedSave = useMemo(
    () =>
      debounce((newName: string, newDescription: string) => {
        const trimmedName = newName.trim();
        const trimmedDescription = sanitizeHtml(newDescription.trim());

        if (!trimmedName) {
          toast.error(t("topic.name_required"));
          return;
        }

        if (!trimmedDescription || trimmedDescription === "<p><br /></p>") {
          toast.error(t("topic.description_required"));
          return;
        }

        if (currentTopicSelected.name !== trimmedName || currentTopicSelected.description !== trimmedDescription) {
          mutate();
        }
      }, 2000),
    [currentTopicSelected.name, currentTopicSelected.description, mutate, t],
  );

  const onChangeTextInput = useCallback(
    (_name: string, value: string) => {
      setTopicName(value);
      debouncedSave(value, topicDescription);
    },
    [debouncedSave, topicDescription],
  );

  const editorOnChange = useCallback(
    (value: string) => {
      setTopicDescription(value);
      debouncedSave(topicName, value);
    },
    [debouncedSave, topicName],
  );

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    handleLoading(isLoading);
    if (isLoading) handleShowSaving(true);

    if (!isLoading) {
      const timer = setTimeout(() => {
        handleShowSaving(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  return (
    <Stack className="relative" gap={3}>
      <Stack className="flex-row justify-end" gap={1}>
        <Button
          variant="contained"
          className="px-4 py-2 bg-primary w-fit"
          sx={{ textTransform: "capitalize", borderRadius: 2 }}
          onClick={() => setOpenModal(true)}
          data-testid="btn-ai-writer-topic"
        >
          {t("topic.ai_writer")}
        </Button>

        <AddQuestionAiMakerButton
          threadId={currentTopicSelected.id.toString()}
          onClick={() => handleCurrentTopicQuizChange(currentTopicSelected, `quiz_${currentTopicSelected.id}`)}
        />
      </Stack>
      <TextInputView
        name="name"
        label={t("topic.topic")}
        value={topicName}
        id="edit_name_txt"
        onChangeTextInput={onChangeTextInput}
      />

      <EditorView
        id="edit_description_txt"
        name="description"
        value={topicDescription}
        onChangeContent={editorOnChange}
      />

      <Typography className="text-sm">
        {t("topic.count_words")}: {counter}
      </Typography>

      <TopicAiWriterPopup
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSubmit={handleSubmitAiWriter}
        isLoading={isRegeneratingTopic}
      />
    </Stack>
  );
};

export default TopicEdit;
