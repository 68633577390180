import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import UserIcon from "assets/icons/user";
import { Button, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "contexts/AuthContext";
// @ts-ignore
import ReactCountryFlag from "react-country-flag";
import Cookies from "universal-cookie";

const Header: React.FC = () => {
  const location = useLocation();

  const { infoAppDashboard } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [language, setLanguage] = useState("en");
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const { t, i18n } = useTranslation();
  useEffect(() => {
    const cookies = new Cookies();
    setLanguage(cookies.get("lng"));
  }, [i18n.language]);

  const handleLanguageChange = (language: string) => {
    const cookies = new Cookies();
    i18n.changeLanguage(language);
    cookies.set("lng", language, { path: "/" });
  };

  const isHideHeader = useMemo(() => {
    const regexList = [
      /^\/v2\/training\/course\/[^/]+$/, // Match: /v2/training/course/:course_id
      /^\/v2\/dashboard\/courses\/[^/]+\/edit$/, // Match: /v2/dashboard/courses/:course_id/edit
    ];

    return regexList.some(regex => regex.test(location.pathname));
  }, [location.pathname]);

  if (isHideHeader) return <></>;

  return (
    <header className="bg-mt-dark-teal sticky top-0 z-50" data-testid="layout-header">
      <div className="container px-5 py-4 flex flex-row items-center justify-between">
        <Link to="/v2/dashboard/courses">
          <Button variant="text">
            <Typography className="text-white font-helvetica-neue text-2xl font-medium">{t("header.title")}</Typography>
          </Button>
        </Link>
        <Stack className="flex-row gap-4">
          <Stack>
            {language === "en" && (
              <Stack
                className="flex-row cursor-pointer justify-center items-center gap-1"
                onClick={() => handleLanguageChange("vi")}
              >
                <ReactCountryFlag code="US" />
                <Typography className="text-white font-helvetica-neue">English</Typography>
              </Stack>
            )}
            {language === "vi" && (
              <Stack
                className="flex-row cursor-pointer justify-center items-center gap-1"
                onClick={() => handleLanguageChange("en")}
              >
                <ReactCountryFlag code="VN" />
                <Typography className="text-white font-helvetica-neue">Tiếng Việt</Typography>
              </Stack>
            )}
          </Stack>
          <Stack onClick={handleClick} className="flex-row justify-between items-center gap-1">
            <UserIcon height={24} />
            <Typography className="text-white cursor-pointer">{infoAppDashboard?.full_name}</Typography>
          </Stack>
        </Stack>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          slotProps={{
            paper: {
              sx: {
                mt: 1,
              },
            },
          }}
        >
          <Button href="/users/sign_out" data-testid="header-logout-button">
            <MenuItem className="text-black normal-case" onClick={handleClose}>
              {t("header.logout")}
            </MenuItem>
          </Button>
        </Menu>
      </div>
    </header>
  );
};

export default Header;
