import { useAssignCourseToOrganization } from "hooks/useCourse";
import React, { useCallback, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface IAssignCourseModalProps {
  courseId: number;
  showAssignCourseModal: boolean;
  hideAssignModal: () => void;
  onAssign: () => void;
}

const AssignCourseModal: React.FC<IAssignCourseModalProps> = props => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const { courseId, showAssignCourseModal, hideAssignModal, onAssign } = props;
  const { mutate } = useAssignCourseToOrganization(
    {
      course_id: courseId,
      email: email.trim(),
    },
    data => {
      if (data && data.success) {
        toast.success(t("course.assign_modal.success_msg"));
        onAssign();
        setEmail("");

        return;
      }
      toast.error(data.message);
    },
    error => {
      console.log(error);
      toast.error(error);
    },
  );

  const handleEmailChange = useCallback((e: string) => {
    setEmail(e);
  }, []);

  const handleAssign = () => {
    mutate();
  };

  return (
    <Dialog
      open={showAssignCourseModal}
      onClose={hideAssignModal}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          width: "400px",
          borderRadius: "12px",
        },
      }}
    >
      <DialogTitle
        sx={{
          display: "flex",
          alignItems: "center",
          borderBottom: "1px solid #E2E2E2",
          padding: "20px 24px",
        }}
      >
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          {t("course.assign_modal.title")}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={hideAssignModal}
          sx={{
            position: "absolute",
            right: 16,
            top: 16,
            color: theme => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: "16px 24px", marginTop: "16px" }}>
        <Typography className="mb-2 text-4 leading-6">{t("course.assign_modal.label")}</Typography>
        <TextField
          id="standard-helperText"
          fullWidth
          value={email}
          placeholder={t("course.assign_modal.placeholder")}
          onChange={e => handleEmailChange(e.target.value)}
          variant="outlined"
        />
      </DialogContent>
      <DialogActions sx={{ padding: "8px 24px 24px" }}>
        <Button
          onClick={handleAssign}
          variant="contained"
          className="bg-primary py-4"
          fullWidth
          sx={{ textTransform: "none", borderRadius: 2 }}
        >
          <Typography className="leading-4 text-[16px] font-bold">{t("course.assign_modal.assign")}</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AssignCourseModal;
