import { Box, Stack, TextField } from "@mui/material";
import React from "react";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

interface INumberInputProps {
  testId?: string;
  value: number;
  max?: number;
  min?: number;
  onChange: (value: number) => void;
}

const CustomNumberInputField: React.FC<INumberInputProps> = ({ testId, max, min = 0, value, onChange }) => {
  const handleIncrease = () => {
    if (max === undefined || value < max) {
      onChange(value + 1);
    }
  };

  const handleDecrease = () => {
    if (value > min) {
      onChange(value - 1);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value, 10);

    if (!isNaN(newValue)) {
      if (max !== undefined && newValue > max) {
        onChange(max);
      } else if (newValue < min) {
        onChange(min);
      } else {
        onChange(newValue);
      }
    }
  };
  return (
    <Box display="flex" alignItems="center" className="relative">
      <TextField
        data-testid={testId}
        type="number"
        value={value}
        onChange={handleInputChange}
        fullWidth
        className="h-11 rounded-lg"
        sx={{
          "& input[type=number]": {
            MozAppearance: "textfield",
          },
          "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
            WebkitAppearance: "none",
            margin: 0,
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "8px",
          },
        }}
      />
      <Stack className="bg-[rgba(18,73,84,0.04)] p-1 rounded-lg absolute right-2 bottom-[14px] text-mt-dark-gray cursor-pointer">
        <RemoveIcon className="w-3 h-3" onClick={handleDecrease} />
      </Stack>
      <Stack className="bg-[rgba(18,73,84,0.04)] p-1 rounded-lg absolute right-8 bottom-[14px] text-mt-dark-gray cursor-pointer">
        <AddIcon className="w-3 h-3" onClick={handleIncrease} />
      </Stack>
    </Box>
  );
};

export default CustomNumberInputField;
