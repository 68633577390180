import React from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import CheckIcon from "assets/icons/check";
import TextInputView from "components/shared/TextInputView";

interface IAnswerItemProps {
  name: string;
  answer: string;
  correct: boolean;
  handleFieldChange: (name: string, value: string) => void;
}
const AnswerItem: React.FC<IAnswerItemProps> = ({ name, answer, correct, handleFieldChange }) => {
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <Box className="border border-solid border-[#E2E2E2] p-3 rounded-lg">
        <CheckIcon width={21} fill={correct ? undefined : "white"} />
      </Box>

      <div className="flex-1">
        <TextInputView name={name} value={answer} id={`edit__txt_${name}`} onChangeTextInput={handleFieldChange} />
      </div>
    </Stack>
  );
};

export default AnswerItem;
