import React from "react";
import Typography from "@mui/material/Typography";
import SavingIcon from "assets/icons/saving";
import SavedIcon from "assets/icons/saved";
import { useTranslation } from "react-i18next";

interface ISavingViewProps {
  isLoading: boolean;
}

const SavingView: React.FC<ISavingViewProps> = ({ isLoading }) => {
  const { t } = useTranslation();
  return (
    <Typography
      className={`text-xs italic flex flex-row gap-1 ${isLoading ? "text-yellow-600" : "text-green-600"}`}
      data-testid="saving_view"
    >
      {isLoading ? t("global.saving") : t("global.saved")}
      {isLoading ? <SavingIcon width={16} /> : <SavedIcon width={16} />}
    </Typography>
  );
};

export default SavingView;
