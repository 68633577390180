import React from "react";

import { ViewModeType } from "components/course/CourseViewOrEdit/types";

import Stack from "@mui/material/Stack";

import { useCourseViewOrEditSelector } from "contexts/CourseViewOrEditContext";
import QuestionDetail from "./QuestionDetail";
import TopicDetail from "./TopicDetail";

interface IContentProps {
  viewModeType: ViewModeType;
  isTopicSelectedGenerating: boolean;
}
const Content: React.FC<IContentProps> = ({ viewModeType, isTopicSelectedGenerating }) => {
  const { currentTopicSelected } = useCourseViewOrEditSelector(state => state);

  return (
    <Stack className="flex-1 p-6 bg-white rounded-2xl">
      {viewModeType === "topic" && currentTopicSelected && <TopicDetail currentTopicSelected={currentTopicSelected} />}
      {viewModeType === "quiz" && currentTopicSelected && (
        <QuestionDetail
          currentTopicSelected={currentTopicSelected}
          isTopicSelectedGenerating={isTopicSelectedGenerating}
        />
      )}
    </Stack>
  );
};

export default Content;
