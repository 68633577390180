import React from "react";
import icon from "./base";

export default icon("Google", () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32 16.3637C32 15.2292 31.8961 14.1383 31.7032 13.091H16.3265V19.2801H25.1132C24.7347 21.2801 23.5844 22.9746 21.8553 24.1092V28.1237H27.1317C30.2189 25.3382 32 21.2364 32 16.3637Z"
      fill="#4285F4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3265 31.9999C20.7347 31.9999 24.4304 30.5673 27.1317 28.1237L21.8553 24.1092C20.3933 25.0692 18.5232 25.6363 16.3265 25.6363C12.0742 25.6363 8.47497 22.8218 7.19111 19.04H1.73656V23.1854C4.42302 28.4145 9.94436 31.9999 16.3265 31.9999Z"
      fill="#34A853"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.19111 19.04C6.86458 18.08 6.67904 17.0546 6.67904 16.0001C6.67904 14.9455 6.86457 13.9201 7.1911 12.9601V8.81462H1.73655C0.630798 10.9746 0 13.4182 0 16.0001C0 18.5819 0.630805 21.0254 1.73656 23.1854L7.19111 19.04Z"
      fill="#FBBC05"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3265 6.36363C18.7236 6.36363 20.8757 7.1709 22.5677 8.75635L27.2505 4.16727C24.423 1.58545 20.7273 0 16.3265 0C9.94436 0 4.42301 3.58553 1.73655 8.81462L7.1911 12.9601C8.47496 9.17825 12.0742 6.36363 16.3265 6.36363Z"
      fill="#EA4335"
    />
  </svg>
));
