import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const SectionHeader = ({ title }: { title: string }) => {
  return (
    <Box
      className="bg-[#FFB3190A] rounded-2xl my-10"
      sx={{
        position: "relative",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        gap: 2,
        padding: "16px",
        "&::before, &::after": {
          content: '""',
          flex: 1,
          height: "25px",
          backgroundImage: `repeating-linear-gradient(
            -45deg,
            transparent,
            transparent 8px,
            #e0e0e0 8px,
            #e0e0e0 9px
          )`,
        },
      }}
    >
      <Typography
        className="text-[#313B3D] text-sm max-w-[312px] mx-auto"
        sx={{
          fontStyle: "italic",
          fontWeight: 600,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

export default SectionHeader;
