import { Accordion, AccordionSummary, AccordionDetails, Box, Typography } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TopicType } from "types/topic";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import HTMLPreview from "components/shared/HTMLPreview";
import TextInputView from "components/shared/TextInputView";
import { useUpdateTopicToOrganization } from "hooks/useTopic";
import debounce from "lodash/debounce";
import { useHotkeys } from "react-hotkeys-hook";

export const TopicItemView = ({
  topic,
  index,
  refetchListTopics,
}: {
  topic: TopicType;
  index: number;
  refetchListTopics: () => void;
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [formData, setFormData] = useState(topic);
  const componentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();

  const { mutate: updateTopic } = useUpdateTopicToOrganization(
    {
      id: formData.id,
      name: formData.name.trim(),
      description: formData.description.trim(),
      course_id: formData.course_id,
    },
    () => {
      toast.success(t("global.updated_successfully"));
      refetchListTopics();
    },
    error => {
      toast.error(error);
    },
  );

  useEffect(() => {
    if (isEditing) {
      setExpanded(true);
    }
  }, [isEditing]);

  const handleAccordionChange = (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const debouncedSave = useMemo(
    () =>
      debounce(() => {
        const trimmedName = formData.name.trim();
        const trimmedDescription = formData.description.trim();

        if (!trimmedName) {
          toast.error(t("topic.name_required"));
          return;
        }

        if (!trimmedDescription) {
          toast.error(t("topic.description_required"));
          return;
        }

        if (topic.name !== trimmedName || topic.description !== trimmedDescription) {
          updateTopic();
        }
      }, 2000),
    [topic, formData, updateTopic, t],
  );

  useEffect(() => {
    debouncedSave();

    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  const onChangeTextInput = useCallback((name: string, value: string) => {
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  useHotkeys(
    "esc",
    () => {
      if (isEditing) {
        const activeElement = document.activeElement;
        if (componentRef.current?.contains(activeElement)) {
          setIsEditing(false);
          setFormData(topic);
        }
      }
    },
    { enableOnFormTags: true },
    [isEditing, topic],
  );

  return (
    <Accordion
      key={topic.id}
      ref={componentRef}
      expanded={expanded}
      onChange={handleAccordionChange}
      className="bg-transparent border-none shadow-none"
      sx={{
        "&::before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`topic-${index}-content`}
        id={`topic-${index}-header`}
        className="mt-4"
      >
        <Box className="w-full" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 1 }}>
          <Typography
            className="text-lg font-bold"
            sx={{
              bgcolor: "#313B3D",
              color: "white",
              width: 40,
              height: 40,
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {index + 1}
          </Typography>
          {isEditing ? (
            <div className="flex-1">
              <TextInputView
                name="name"
                label=""
                value={formData.name}
                id={`input-topic-name-${topic.id}`}
                onChangeTextInput={onChangeTextInput}
              />
            </div>
          ) : (
            <Typography
              className="text-base font-bold text-[#313B3D] flex-1"
              data-testid={`topic-name-${topic.id}`}
              onClick={() => setIsEditing(true)}
            >
              {topic.name}
            </Typography>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails className="pl-10">
        {isEditing ? (
          <TextInputView
            type="textarea"
            name="description"
            label=""
            value={formData.description}
            id={`input-topic-description-${topic.id}`}
            onChangeTextInput={onChangeTextInput}
          />
        ) : (
          <HTMLPreview htmlContent={topic.description} className="text-sm text-black cursor-pointer" />
        )}
      </AccordionDetails>
    </Accordion>
  );
};
