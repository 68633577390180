import React from "react";
import { useTranslation } from "react-i18next";
import { AnswerStatsType, QuizData } from "types/training";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useTrainingContext } from "contexts/TrainingContext";
import CourseHintPreview from "./components/CourseHintPreview";
import LearnCourseAnswerView from "./components/LearnCourseAnswerView";
import LearnCourseFinishView from "./components/LearnCourseFinishView";

interface IViewProps {
  currentQuestionIndex: number;
  currentQuestion?: QuizData;
  isTopicFinished: boolean;
  handleNextQuestion: () => void;
  isLoadingResetQuiz: boolean;
  handleRetryTopic: () => void;
  answerStats: AnswerStatsType;
}

const View: React.FC<IViewProps> = ({
  currentQuestionIndex,
  currentQuestion,
  isTopicFinished,
  handleNextQuestion,
  isLoadingResetQuiz,
  handleRetryTopic,
  answerStats,
}) => {
  const { t } = useTranslation();
  const { currentTopicSelected } = useTrainingContext();

  return (
    <Stack>
      <Stack gap={1} data-testid="learn-course-view-topic">
        <Typography className="font-medium uppercase text-sm text-[#313B3D]">
          {t("question.topic", {
            index: currentTopicSelected.uiIndex,
          })}
        </Typography>
        <Typography className="font-bold text-[2rem] text-[#313B3D]">{currentTopicSelected.name}</Typography>
      </Stack>
      <Divider className="my-6 text-[#E2E2E2]" />

      {(!currentQuestion || isTopicFinished) && (
        <LearnCourseFinishView
          isLoadingResetQuiz={isLoadingResetQuiz}
          isLastTopic={currentTopicSelected.isLastTopic}
          handleRetryTopic={handleRetryTopic}
          answerStats={answerStats}
        />
      )}

      {currentQuestion && !isTopicFinished && (
        <>
          <CourseHintPreview
            currentQuestionIndex={currentQuestionIndex}
            currentQuestion={currentQuestion}
            answerStats={answerStats}
          />

          <LearnCourseAnswerView currentQuestion={currentQuestion} handleNextQuestion={handleNextQuestion} />
        </>
      )}
    </Stack>
  );
};

export default View;
