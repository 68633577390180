import React from "react";
import { Card, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AiIcon from "assets/icons/ai";

const CreateCourseCard: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        height: "100%",
        width: "100%",
        margin: "auto",
        padding: 3,
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
        justifyContent: "center",
        border: "2px dashed #2E7D32",
        borderRadius: 3,
      }}
      className="shadow-none"
    >
      <img src="/images/create-course-with-ai.png" alt="Create with AI" width={117} height={117} />
      <Typography className="font-bold text-mt-dark-gray" variant="h6" textAlign="left">
        {t("course.create_with_ai")}
      </Typography>
      <Typography variant="body2" className="" color="black" textAlign="left" mb={2}>
        {t("course.using_document")}
      </Typography>
      <Link id="btn-add-course-file" className="btn btn-default mt-2" to="/v2/create_course_with_ai">
        <Button
          variant="contained"
          className="flex items-center justify-center bg-btn-gradient px-4 py-3"
          sx={{ textTransform: "none", borderRadius: 3 }}
        >
          <AiIcon width={24} />
          <Typography className="leading-6 font-bold">{t("course.create_btn")}</Typography>
        </Button>
      </Link>
    </Card>
  );
};

export default CreateCourseCard;
