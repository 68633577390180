import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

interface IEditorViewProps {
  value: any;
  error?: string;
  name: string;
  label?: string;
  maxHeight?: string;
  id: string;
  onChangeContent: (value: string, name: string) => void;
}
const modules = {
  toolbar: [
    // Font and size dropdowns
    [{ font: [] }],
    [{ size: [] }],

    // Formatting buttons
    ["bold", "italic", "underline", "strike"], // Text formatting
    [{ color: [] }, { background: [] }], // Font color and background color
    [{ script: "sub" }, { script: "super" }], // Subscript / superscript
    ["blockquote", "code-block"],

    // Headers
    [{ header: 1 }, { header: 2 }], // Header levels

    // List and indentation
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "+1" }, { indent: "-1" }], // Indent / Outdent

    // Alignment
    [{ align: [] }],

    // Add links, images, and videos
    ["link", "image", "video"],
  ],
};
const formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "script",
  "blockquote",
  "code-block",
  "header",
  "list",
  "bullet",
  "indent",
  "align",
  "link",
  "image",
  "video",
  "table",
];

const MIN_HEIGHT = 200;

const EditorView: React.FC<IEditorViewProps> = ({ value, name, onChangeContent, id, maxHeight }) => {
  const editorRef = useRef<HTMLDivElement>(null);
  const [toolbarHeight, setToolbarHeight] = useState(0);

  const handleEditorChange = useCallback(
    (newContent: string) => {
      onChangeContent(newContent, name);
    },
    [name],
  );

  const handleResize = useCallback((moveEvent: MouseEvent) => {
    if (!editorRef.current) return;

    const newHeight = Math.max(MIN_HEIGHT, moveEvent.clientY - editorRef.current.getBoundingClientRect().top);
    editorRef.current.style.height = `${newHeight}px`;
  }, []);

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      document.addEventListener("mousemove", handleResize);

      const cleanup = () => {
        document.removeEventListener("mousemove", handleResize);
        document.removeEventListener("mouseup", cleanup);
      };

      document.addEventListener("mouseup", cleanup);
    },
    [handleResize],
  );

  useEffect(() => {
    const updateToolbarHeight = () => {
      if (editorRef.current) {
        const toolbar = editorRef.current.querySelector(".ql-toolbar");
        if (toolbar) {
          setToolbarHeight(toolbar.getBoundingClientRect().height);
        }
      }
    };

    updateToolbarHeight();
    window.addEventListener("resize", updateToolbarHeight);
    return () => window.removeEventListener("resize", updateToolbarHeight);
  }, []);

  return (
    <div data-testid={id} ref={editorRef} className="relative custom-editor" style={{ height: maxHeight || "500px" }}>
      <ReactQuill
        theme="snow"
        value={value}
        modules={modules}
        formats={formats}
        onChange={handleEditorChange}
        className="h-[calc(100%-9px)]"
        id={id}
      />

      <style>{`
        #${id} .ql-container {
          height: calc(100% - ${toolbarHeight}px) !important;
        }
      `}</style>
      <div className="editor-resize-handle" onMouseDown={handleMouseDown}>
        <div className="resize-line" />
      </div>
    </div>
  );
};

export default EditorView;
