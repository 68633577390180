/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";

const LoadingInPageView = ({ isSettingUp, refreshAppInfo }: { isSettingUp?: boolean; refreshAppInfo: () => void }) => {
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (!isSettingUp && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    if (isSettingUp && !intervalId) {
      const newInterval = setInterval(refreshAppInfo, 3000);
      setIntervalId(newInterval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isSettingUp, intervalId, refreshAppInfo]);

  return (
    <p className="pt-10 text-center">
      <img data-testid="image-loading" height="30px" src="/images/loading.gif" className="m-auto" />
      {isSettingUp && <p className="mt-5">Please wait — setting up your account for first-time login!</p>}
    </p>
  );
};

export default LoadingInPageView;
