import { useQuery } from "@tanstack/react-query";
import { fetchDataAsync } from "utilities/http";
import { AuthType } from "types/auth";
import { Ability } from "@casl/ability";

const useAuthContext = () => {
  return useQuery({
    queryKey: ["fetchInfoAppDashboard"],
    queryFn: async () => {
      try {
        const data = await fetchDataAsync<AuthType>("/get_info_app_dashboard", "GET");
        return {
          ...data,
          ability: new Ability(data.rules),
        };
      } catch (error) {
        return null;
      }
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: false,
    staleTime: 0,
  });
};

export default useAuthContext;
