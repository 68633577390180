import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import UploadFileIcon from "assets/icons/upload_file";
import PDFIcon from "assets/icons/pdf_document";
import CourseAssignImg from "assets/images/course_assign.png";
import CourseReviewImg from "assets/images/course_review.png";
import CourseGeneratingPhase1Img from "assets/images/course_generating_phase_1.gif";
import CourseGeneratingPhase2Img from "assets/images/course_generating_phase_2.gif";
import CourseGeneratingPhase3Img from "assets/images/course_generating_phase_3.gif";
import CourseCompletedImg from "assets/images/course_completed.gif";
import CourseFailedImg from "assets/images/course_failed.gif";
import AiIcon from "assets/icons/ai";
import { useDropzone } from "react-dropzone";
import { Box, LinearProgress, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import useUploadAiFile from "hooks/useUploadAiFile";
import useGenerateCourse from "hooks/useGenerateCourse";
import { useGetCategoryList } from "hooks/useCourse";
import CustomNumberInputField from "components/shared/CustomNumberInputField";
import { CategoryType, LanguageOptionType, ToneOptionType } from "types/course";
import useGenerateQuestionsStatus from "hooks/useGenerateQuestionsStatus";
import { MAX_FILE_SIZE, TIMEOUTS } from "utilities/constants";
import ReactGA from "react-ga4";
import { toast } from "react-toastify";
import CourseReview from "./components/CourseReview";

const TONE_OPTIONS = [
  { id: 1, name: "Friendly" },
  { id: 2, name: "Professional" },
  { id: 3, name: "Informative" },
  { id: 4, name: "Engaging" },
  { id: 5, name: "Casual" },
  { id: 6, name: "Humorous" },
  { id: 7, name: "Storytelling" },
  { id: 8, name: "Analytical" },
  { id: 9, name: "Inspiring" },
];

const LANGUAGE_OPTIONS = [
  { value: "en", name: "English" },
  { value: "vi", name: "Vietnamese" },
];

const DIFFICULTY_LEVELS = ["Beginner", "Intermediate", "Advanced"];

const STEPS = {
  setup: "setup",
  course_generating_phase_1: "course_generating_phase_1",
  course_generating_phase_2: "course_generating_phase_2",
  course_generating_phase_3: "course_generating_phase_3",
  course_completed: "course_completed",
  course_review: "course_review",
  course_failed: "course_failed",
};

const CreateCourseWithAI = () => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [aiFileId, setAiFileId] = useState("");
  const [courseId, setCourseId] = useState("");
  const [currentStep, setCurrentStep] = useState(STEPS.setup);
  const timeoutIdRef = useRef<any>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { t } = useTranslation();
  const [category, setCategory] = useState("");
  const [tone, setTone] = useState("");
  const [language, setLanguage] = useState("");
  const [startAge, setStartAge] = useState(18);
  const [endAge, setEndAge] = useState(80);
  const [difficultyLevel, setDifficultyLevel] = useState("");
  const [courseDuration, setCourseDuration] = useState(5);
  const [topicLength, setTopicLength] = useState(10);
  const [wordPerTopic, setWordPerTopic] = useState(500);
  const [mainContent, setMainContent] = useState("");
  const isInitialRender = useRef(true);
  const [errors, setErrors] = useState({
    content: false,
    category: false,
    tone: false,
    language: false,
    startAge: false,
    endAge: false,
    difficultyLevel: false,
    courseDuration: false,
    topicLength: false,
    wordPerTopic: false,
  });
  const { data: categoryList } = useGetCategoryList();

  const { isCompleted } = useGenerateQuestionsStatus(courseId);

  useEffect(() => {
    if (!isCompleted || !courseId) return;

    setCurrentStep(STEPS.course_completed);
    setTimeout(() => {
      setCurrentStep(STEPS.course_review);
    }, TIMEOUTS.COURSE_COMPLETED_DISPLAY);
  }, [isCompleted, courseId]);

  const uploadAiFile = useUploadAiFile({
    onSuccess: data => {
      setAiFileId(data.file_id || "");
    },
  });

  const generateCourseMutation = useGenerateCourse({
    onSuccess: data => {
      if (data.success) {
        setCourseId(data.course_id || "");
        ReactGA.event("create_course_ai", { course_id: data.course_id });
      } else {
        setCurrentStep(STEPS.course_failed);
      }
      clearTimeout(timeoutIdRef.current);
    },
    onError: () => {
      setCurrentStep(STEPS.course_failed);
      clearTimeout(timeoutIdRef.current);
    },
  });

  const handleFileChange = (acceptedFiles: File[]) => {
    if (!acceptedFiles?.length) {
      return;
    }

    const file = acceptedFiles?.[0];
    if (file) {
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("file", file);
      uploadAiFile.mutate(formData);
    }
  };
  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
      return;
    }
    checkValidPayload();
  }, [
    category,
    tone,
    language,
    startAge,
    endAge,
    difficultyLevel,
    courseDuration,
    topicLength,
    wordPerTopic,
    mainContent,
    aiFileId,
  ]);
  const checkValidPayload = () => {
    const newErrors = {
      content: false,
      category: false,
      tone: false,
      language: false,
      startAge: false,
      endAge: false,
      difficultyLevel: false,
      courseDuration: false,
      topicLength: false,
      wordPerTopic: false,
    };
    if (!aiFileId && !mainContent) newErrors.content = true;
    if (!category) newErrors.category = true;
    if (!tone) newErrors.tone = true;
    if (!language) newErrors.language = true;
    if (!startAge) newErrors.startAge = true;
    if (!endAge) newErrors.endAge = true;
    if (!difficultyLevel) newErrors.difficultyLevel = true;
    if (!courseDuration) newErrors.courseDuration = true;
    if (!topicLength) newErrors.topicLength = true;
    if (!wordPerTopic) newErrors.wordPerTopic = true;

    setErrors(newErrors);
    return Object.values(newErrors).every(value => value === false);
  };
  const handleCreateCourse = () => {
    const isValid = checkValidPayload();
    if (!isValid) return;
    const payload = {
      file_id: aiFileId || "",
      category_id: parseInt(category),
      tone_id: parseInt(tone),
      language: language,
      start_age: startAge,
      end_age: endAge,
      difficulty_level: difficultyLevel,
      duration_in_minutes: courseDuration * 60,
      topic_duration_in_minutes: topicLength,
      words_per_topic: wordPerTopic,
      main_content: mainContent || "",
    };
    generateCourseMutation.mutate(payload);
    setCurrentStep(STEPS.course_generating_phase_1);

    let timeoutId;
    timeoutId = setTimeout(() => {
      setCurrentStep(STEPS.course_generating_phase_2);

      timeoutId = setTimeout(() => {
        setCurrentStep(STEPS.course_generating_phase_3);
      }, 10000);
      timeoutIdRef.current = timeoutId;
    }, 10000);
    timeoutIdRef.current = timeoutId;
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    onDrop: acceptedFiles => {
      handleFileChange(acceptedFiles);
    },
    onDropRejected: rejectedFiles => {
      if (rejectedFiles[0].file.size > MAX_FILE_SIZE) {
        toast.error(t("global.file_size_too_large"));
      }
    },
    multiple: false,
    maxSize: MAX_FILE_SIZE,
    disabled: false,
  });

  if (currentStep === STEPS.course_generating_phase_1) {
    return (
      <Container maxWidth="md" className="py-10">
        <CourseGeneratingPhase1 />
      </Container>
    );
  }

  if (currentStep === STEPS.course_generating_phase_2) {
    return (
      <Container maxWidth="md" className="py-10">
        <CourseGeneratingPhase2 />
      </Container>
    );
  }

  if (currentStep === STEPS.course_generating_phase_3) {
    return (
      <Container maxWidth="md" className="py-10">
        <CourseGeneratingPhase3 />
      </Container>
    );
  }

  if (currentStep === STEPS.course_completed) {
    return (
      <Container maxWidth="md" className="py-10">
        <CourseCompleted />
      </Container>
    );
  }

  if (currentStep === STEPS.course_review) {
    return (
      <Container maxWidth="md" className="py-10">
        <CourseReview courseId={courseId} />
      </Container>
    );
  }

  if (currentStep === STEPS.course_failed) {
    return (
      <Container maxWidth="md" className="py-10">
        <CourseFailed onClick={handleCreateCourse} />
      </Container>
    );
  }

  return (
    <Container className="py-10 max-w-[612px] bg-[#F4F4F4]">
      <>
        <div className="p-4 bg-gray-200 rounded-t-lg">
          <h2 className="text-xl font-semibold text-gray-700">{t("create_course_with_ai.title")}</h2>
        </div>
        <Stack className="p-6 bg-white rounded-b-lg" spacing={3}>
          <Box className="flex-1">
            <Typography className="text-base font-semibold">{t("create_course_with_ai.category")}</Typography>
            <Select
              data-testid="category-select"
              value={category}
              onChange={e => setCategory(e.target.value)}
              displayEmpty
              fullWidth
              className="mt-2 rounded-xl"
              sx={{
                "& .MuiSelect-outlined": {
                  padding: "12.5px 32px 12.5px 14px",
                },
              }}
            >
              <MenuItem value="" disabled>
                {t("create_course_with_ai.category_placeholder")}
              </MenuItem>
              {categoryList?.categories.map((category: CategoryType) => (
                <MenuItem key={category.id} value={category.id}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
            {errors.category && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
          </Box>
          <Box className="flex-1">
            <Typography className="text-base font-semibold">{t("create_course_with_ai.tone")}</Typography>
            <Select
              data-testid="tone-select"
              value={tone}
              onChange={e => setTone(e.target.value)}
              displayEmpty
              fullWidth
              className="mt-2 rounded-xl"
              sx={{
                "& .MuiSelect-outlined": {
                  padding: "12.5px 32px 12.5px 14px",
                },
              }}
            >
              <MenuItem value="" disabled>
                {t("create_course_with_ai.tone_placeholder")}
              </MenuItem>
              {TONE_OPTIONS.map((tone: ToneOptionType) => (
                <MenuItem key={tone.id} value={tone.id}>
                  {tone.name}
                </MenuItem>
              ))}
            </Select>
            {errors.tone && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
          </Box>
          <Box className="flex-1">
            <Typography className="text-base font-semibold">{t("create_course_with_ai.language")}</Typography>
            <Select
              data-testid="language-select"
              value={language}
              onChange={e => setLanguage(e.target.value)}
              displayEmpty
              fullWidth
              className="mt-2 rounded-xl"
              sx={{
                "& .MuiSelect-outlined": {
                  padding: "12.5px 32px 12.5px 14px",
                },
              }}
            >
              <MenuItem value="" disabled>
                {t("create_course_with_ai.language_placeholder")}
              </MenuItem>
              {LANGUAGE_OPTIONS.map((language: LanguageOptionType) => (
                <MenuItem key={language.value} value={language.value}>
                  {language.name}
                </MenuItem>
              ))}
            </Select>
            {errors.language && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
          </Box>
          <Typography className="text-xl font-bold">{t("create_course_with_ai.learners_background")}</Typography>
          <Stack className="flex-row gap-4">
            <Box className="flex-1">
              <Typography className="mb-2 text-base font-semibold">{t("create_course_with_ai.start_age")}</Typography>
              <CustomNumberInputField testId="start-age-input" value={startAge} onChange={e => setStartAge(e)} />
              {errors.startAge && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
            </Box>
            <Box className="flex-1">
              <Typography className="mb-2 text-base font-semibold">{t("create_course_with_ai.end_age")}</Typography>
              <CustomNumberInputField testId="end-age-input" value={endAge} onChange={e => setEndAge(e)} />
              {errors.endAge && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
            </Box>
          </Stack>
          <Box className="flex-1">
            <Typography className="text-base font-semibold">{t("create_course_with_ai.difficulty_level")}</Typography>
            <Select
              data-testid="difficulty-select"
              value={difficultyLevel}
              onChange={e => setDifficultyLevel(e.target.value)}
              displayEmpty
              fullWidth
              className="mt-2 rounded-xl"
              sx={{
                "& .MuiSelect-outlined": {
                  padding: "12.5px 32px 12.5px 14px",
                },
              }}
            >
              <MenuItem value="" disabled>
                {t("create_course_with_ai.difficulty_level_placeholder")}
              </MenuItem>
              {DIFFICULTY_LEVELS.map((level: string) => (
                <MenuItem key={level} value={level}>
                  {level}
                </MenuItem>
              ))}
            </Select>
            {errors.difficultyLevel && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
          </Box>
          <Typography className="text-xl font-bold">{t("create_course_with_ai.course")}</Typography>
          <Stack direction="row" alignItems="center" className="flex-row gap-4">
            <Box className="flex-1">
              <Typography className="mb-2 text-base font-semibold">
                {t("create_course_with_ai.course_duration")}
              </Typography>
              <CustomNumberInputField
                testId="course-duration-input"
                max={40}
                value={courseDuration}
                onChange={e => setCourseDuration(e)}
              />
              {errors.courseDuration && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
            </Box>
            <Box className="flex-1">
              <Typography className="mb-2 text-base font-semibold">
                {t("create_course_with_ai.topic_length")}
              </Typography>
              <CustomNumberInputField
                testId="topic-length-input"
                max={120}
                value={topicLength}
                onChange={e => setTopicLength(e)}
              />
              {errors.topicLength && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
            </Box>
          </Stack>
          <Box className="flex-1">
            <Typography className="mb-2 text-base font-semibold">
              {t("create_course_with_ai.words_per_topic")}
            </Typography>
            <CustomNumberInputField testId="word-topic-input" value={wordPerTopic} onChange={e => setWordPerTopic(e)} />
            {errors.wordPerTopic && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
          </Box>
          <Box className="flex-1">
            <Typography className="mb-1 text-base font-semibold">{t("create_course_with_ai.main_content")}</Typography>
            <TextField
              data-testid="main-content-input"
              multiline
              rows={4}
              value={mainContent}
              placeholder={t("create_course_with_ai.main_content_placeholder")}
              onChange={e => setMainContent(e.target.value)}
              fullWidth
              sx={{
                "& .MuiOutlinedInput-root": {
                  padding: "8px",
                  borderRadius: "8px",
                  "& textarea": {
                    padding: "8px",
                    boxShadow: "none",
                  },
                },
              }}
            />
            {errors.content && <Typography className="text-red-500">{t("course.field_required")}</Typography>}
          </Box>
          <Typography className="mb-1 text-base font-semibold">{t("create_course_with_ai.upload_file")}</Typography>
          <div
            className="flex flex-row items-center justify-between w-full p-6 mt-0 border-2 border-gray-300 border-dashed rounded-lg"
            {...getRootProps()}
          >
            <input
              {...getInputProps()}
              ref={fileInputRef}
              id="file-input"
              data-testid="create-course-file-input"
              disabled={uploadAiFile.isLoading || generateCourseMutation.isLoading}
            />
            <label htmlFor="file-input" className="w-full cursor-pointer">
              <Stack className="flex-row items-center gap-4">
                <UploadFileIcon width={48} height={48} />
                <Stack flex={1}>
                  <p className="text-sm font-bold">{t("global.drop_file")}</p>
                  <p className="mt-2 text-xs text-gray-400">{t("global.limit_file_size")}</p>
                </Stack>
                <Button
                  variant="contained"
                  component="span"
                  className="mt-2 normal-case bg-transparent border border-solid border-mt-dark-slate text-mt-dark-slate"
                  disabled={uploadAiFile.isLoading || generateCourseMutation.isLoading}
                >
                  {t("global.select_file")}
                </Button>
              </Stack>
            </label>
          </div>

          {selectedFile && (
            <div className="flex items-center p-2 mt-4 border border-gray-300 rounded-lg">
              <div className="flex items-center mr-4">
                <PDFIcon width={48} height={52} />
              </div>

              <div className="flex-1 min-w-0">
                <p className="font-bold text-gray-700 truncate">{selectedFile.name}</p>
                <LinearProgress
                  className="text-primary"
                  color="inherit"
                  variant={uploadAiFile.isLoading ? undefined : "determinate"}
                  sx={{ height: 10 }}
                />
              </div>
            </div>
          )}
        </Stack>

        <Button
          onClick={handleCreateCourse}
          variant="contained"
          className="w-full mt-4 bg-btn-gradient"
          startIcon={<AiIcon />}
          data-testid="create-course-button"
        >
          <Typography className="font-bold text-white normal-case">
            {t("create_course_with_ai.create_course_btn")}
          </Typography>
        </Button>
      </>
      {/*Preload heavy gif images*/}
      <div style={{ display: "none" }}>
        <img src={CourseGeneratingPhase1Img} />
        <img src={CourseGeneratingPhase2Img} />
        <img src={CourseGeneratingPhase3Img} />
        <img src={CourseCompletedImg} />
        <img src={CourseFailedImg} />
      </div>
    </Container>
  );
};

const CourseGeneratingPhase1 = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center w-full py-4 bg-white rounded-t-lg">
        <Typography variant="h5" className="text-gray-700">
          {t("create_course_with_ai.your_course_is_being_generated")}
        </Typography>
        <img src={CourseGeneratingPhase1Img} width={150} height={150} className="mt-4" />
      </div>
      <NextUp />
    </div>
  );
};

const CourseGeneratingPhase2 = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center w-full py-4 bg-white rounded-t-lg">
        <Typography variant="h5" className="font-bold text-gray-700">
          {t("create_course_with_ai.almost_there")}
        </Typography>
        <Typography variant="h5" className="font-bold text-gray-700">
          {t("create_course_with_ai.it_takes_around_some_minute_to_go")}
        </Typography>
        <img src={CourseGeneratingPhase2Img} width={150} height={133} className="mt-4" />
      </div>
      <NextUp />
    </div>
  );
};

const CourseGeneratingPhase3 = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center w-full py-4 bg-white rounded-t-lg">
        <Typography variant="h5" className="font-bold text-gray-700">
          {t("create_course_with_ai.ai_is_using_100_of_knowledge_and_heart_to_create_your_course")}
        </Typography>
        <img src={CourseGeneratingPhase3Img} width={150} height={133} className="mt-4" />
      </div>
      <NextUp />
    </div>
  );
};

const CourseCompleted = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center w-full py-4 bg-white rounded-t-lg">
        <Typography variant="h5" className="font-bold text-gray-700">
          {t("create_course_with_ai.done")}
        </Typography>
        <img src={CourseCompletedImg} width={150} height={133} className="mt-4" />
      </div>
      <NextUp />
    </div>
  );
};

const CourseFailed = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center w-full py-4 bg-white rounded-t-lg">
        <Typography variant="h5" className="font-bold text-gray-700">
          {t("global.something_went_wrong")}
        </Typography>
        <img src={CourseFailedImg} width={145} height={150} className="mt-4" />
        <Button
          variant="contained"
          component="span"
          className="mt-2 bg-btn-gradient"
          onClick={onClick}
          data-testid="retry-create-course-button"
        >
          {t("create_course_with_ai.try_again")}
        </Button>
      </div>
      <NextUp />
    </div>
  );
};

const NextUp = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full p-6 bg-gray-200 rounded-b-lg">
      <Typography variant="subtitle1" className="mb-4 font-medium text-gray-800 underline underline-offset-8">
        {t("create_course_with_ai.next_up")}
      </Typography>
      <div className="space-y-4">
        <div className="flex items-center space-x-3">
          <img src={CourseReviewImg} alt="Review Icon" width={60} height={48} />
          <Typography variant="body1" className="text-gray-600">
            {t("create_course_with_ai.review")}
          </Typography>
        </div>
        <div className="flex items-center space-x-3">
          <img src={CourseAssignImg} alt="Assign Icon" width={60} height={48} />
          <Typography variant="body1" className="text-gray-600">
            {t("create_course_with_ai.assign")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default CreateCourseWithAI;
