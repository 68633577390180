import React from "react";
import icon from "./base";

export default icon("Add", ({ fill = "#357E81" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M12 5.25L12 12.1443M12 12.1443L5.10571 12.1443M12 12.1443L12.0001 19.0386M12 12.1443L18.8943 12.1443"
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
