import React from "react";
import { Button, Stack, Typography } from "@mui/material";
import CloseIcon from "assets/icons/close";
import EyesIcon from "assets/icons/eyes";
import { useTranslation } from "react-i18next";
import { useCourseViewOrEditSelector } from "contexts/CourseViewOrEditContext";
import SavingView from "components/shared/SavingView";

interface ICourseHeaderProps {
  courseId: string;
}

const HeaderView: React.FC<ICourseHeaderProps> = ({ courseId }) => {
  const { t } = useTranslation();
  const { showSaving, isLoading } = useCourseViewOrEditSelector(state => state);

  return (
    <header className="sticky top-0 z-50 bg-white" data-testid="course-header-view">
      <Stack className="flex-row items-center justify-between w-full px-6 py-4">
        <Button
          LinkComponent="a"
          href="/v2/dashboard/courses"
          variant="text"
          className="flex-row items-center w-56 gap-2"
        >
          {/** TODO: Add back icon */}
          {/* <BackIcon width={9} height={18} /> */}
        </Button>
        <Stack direction="row" alignItems="center" gap={1}>
          <Typography className="text-base font-bold leading-6 text-mt-dark-gray">{t("course.edit_course")}</Typography>
          {showSaving && <SavingView isLoading={isLoading} />}
        </Stack>
        <Stack className="flex-row items-center gap-2">
          <Button
            LinkComponent="a"
            href={`/v2/training/course/${courseId}?type=learner`}
            variant="text"
            className="flex-row items-center gap-2 normal-case text-[#313B3D] text-xs font-bold rounded-lg px-4 py-[10px]"
            sx={{
              background: "rgba(53, 126, 129, 0.16)",
            }}
            data-testid="header-view-as-learner-button"
          >
            {t("course.action.view_as_learner")}
            <EyesIcon width={16} />
          </Button>
          <Button
            LinkComponent="a"
            href="/v2/dashboard/courses"
            variant="text"
            className="flex-row items-center gap-2 normal-case text-[#313B3D] text-xs font-bold rounded-lg border border-solid border-[#555F60] px-4 py-2"
            data-testid="header-close-button"
          >
            {t("global.close")}
            <CloseIcon width={16} />
          </Button>
        </Stack>
      </Stack>
    </header>
  );
};

export default HeaderView;
