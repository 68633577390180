import React, { useContext } from "react";
import { Redirect, Route, Switch as RRSwitch } from "react-router-dom";

import CourseList from "components/course/CourseList";
import CreateCourseWithAI from "components/course/CourseCreate/CreateCourseWithAI";
import CourseEdit from "components/course/CourseEdit";
import LearnCourseView from "components/training";
import NotFoundView from "components/shared/NotFoundView";
import { AuthContext } from "contexts/AuthContext";

import RequireAccessView from "components/shared/RequireAccessView";
import LoginView from "components/shared/LoginView";
import CourseView from "../components/course/CourseView";
import EditorRoute from "./EditorRoute";
import MemberRoute from "./MemberRoute";

const NewPermissionSwitch = () => (
  <RRSwitch>
    <Route exact path="/v2" component={() => <Redirect to="/v2/dashboard/courses" />} />
    <Route exact path="/v2/login" component={LoginView} />
    <Route exact path="/v2/require_access" component={RequireAccessView} />
    <Route exact path="/v2/create_course_with_ai" component={CreateCourseWithAI} />

    {/** Training */}
    <Route exact path="/v2/training/course/:course_id" component={LearnCourseView} />

    {/** Course */}
    <MemberRoute exact path="/v2/dashboard/courses" component={CourseList} />
    <MemberRoute exact path="/v2/dashboard/courses/:course_id/view" component={CourseView} />
    <MemberRoute exact path="/v2/dashboard/courses/:course_id/edit" component={CourseEdit} />

    <Route component={NotFoundView} />
  </RRSwitch>
);

const OldPermissionSwitch = () => (
  <RRSwitch>
    <Route exact path="/v2" component={() => <Redirect to="/v2/dashboard/courses" />} />
    <Route exact path="/v2/login" component={LoginView} />
    <Route exact path="/v2/require_access" component={RequireAccessView} />
    <Route exact path="/v2/create_course_with_ai" component={CreateCourseWithAI} />

    {/** Training */}
    <Route exact path="/v2/training/course/:course_id" component={LearnCourseView} />

    {/** Course */}
    <EditorRoute exact path="/v2/dashboard/courses" component={CourseList} />
    <EditorRoute exact path="/v2/dashboard/courses/:course_id/view" component={CourseView} />
    <EditorRoute exact path="/v2/dashboard/courses/:course_id/edit" component={CourseEdit} />

    <Route component={NotFoundView} />
  </RRSwitch>
);

export const Switch = () => {
  const { isNewPermissionSystem } = useContext(AuthContext);
  return isNewPermissionSystem ? <NewPermissionSwitch /> : <OldPermissionSwitch />;
};
