import { useMutation } from "@tanstack/react-query";
import { getCSRFToken } from "../utilities/fetchDataOption";

interface UploadFileResponse {
  success: boolean;
  error?: string;
  file_id?: string;
  vector_store_id?: string;
}

const useUploadAiFile = ({ onSuccess }: { onSuccess: (data: UploadFileResponse) => void }) => {
  return useMutation(
    (formData: FormData) => {
      return fetch("/open_ai_upload_file", {
        method: "POST",
        // @ts-ignore
        headers: {
          "X-CSRF-Token": getCSRFToken(),
          "X-Requested-With": "XMLHttpRequest",
        },
        credentials: "same-origin",
        body: formData,
      }).then(res => res.json());
    },
    {
      onSuccess,
    },
  );
};

export default useUploadAiFile;
