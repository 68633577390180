const en = {
  global: {
    homepage: "Homepage",
    drop_file: "Drop your file here",
    select_file: "Select file",
    limit_file_size: "PDF file size must not exceed 5MB",
    saving: "Saving...",
    saved: "Saved",
    added_successfully: "Added successfully",
    deleted_successfully: "Deleted successfully",
    updated_successfully: "Updated successfully",
    move_up: "Move up",
    move_down: "Move down",
    select_a_file: "Select a file or drag and drop here",
    file_size_required: "JPG, PNG , file size no more than 10MB",
    something_went_wrong: "Oops! Something went wrong",
    search: "Search",
    page_not_found: "Page not found",
    back_to_home: "Back to home",
    close: "Close",
    back: "Back",
    you_need_access_to_learn_this_course: "You need access to learn this course",
    welcome_to_the_training_platform: "Welcome to the training platform",
    login_to_continue: "Log in / Sign up to begin",
    sign_in_with_google: "Sign in with Google",
    user_does_not_belong_to_this_organization: "User does not belong to this organization.",
    file_size_too_large: "File size too large",
  },
  course: {
    course: "Course",
    title: "Title",
    description: "Description",
    question: "Question",
    tabs: {
      course: "courses",
      learning: "learning",
    },
    library: "Library",
    create_with_ai: "Create a course with AI",
    using_document: "Using existing document",
    create_btn: "Create with AI",
    assign_course: "Assign Course",
    assigned_by: "Assigned by",
    already_enrolled: "already enrolled",
    topic: "Topics",
    questions: "Questions",
    curriculum: "Curriculum",
    action: {
      edit: "Edit",
      manage_learners: "Manage learners",
      view_as_learner: "View as learner",
      delete: "Delete",
    },
    delete_modal: {
      title: "Delete Course",
      content: "Are you sure you want to delete this course?",
      cancel: "Cancel",
      accept: "Accept",
      success_msg: "Deleted course successfully",
    },
    assign_modal: {
      title: "Assign Course",
      label: "Learners:",
      placeholder: "Enter email",
      assign: "Assign learner",
      success_msg: "Assigned course successfully",
    },
    back_to_library: "Back to Library",
    your_progress: "Your progress",
    learning: {
      complete: "Complete",
      completed: "Completed",
      resume_course: "Resume course",
      start_course: "Start course",
      view_course: "View course",
      filter: {
        all: "All",
        completed: "Completed",
        in_progress: "In Progress",
      },
    },
    edit_course: "Edit course",
    filter: {
      all: "All",
      completed: "Completed",
      in_progress: "In Progress",
    },
    name_required: "Name is required",
    description_required: "Description is required",
    field_required: "This field is required",
  },
  topic: {
    topic: "Topic",
    count_words: "Words",
    thread_word_limit: "The number of words must not be greater than {{word_limit}}",
    add_topic: "Add topic",
    add_question: "Add question",
    delete_topic: "Delete topic",
    topic_count: "{{topicCount}} Topic",
    untitled: "Untitled",
    add_content_here: "Add content here",
    your_topic_is_being_generated: "Your topic is being generated",
    name_required: "Name is required",
    description_required: "Description is required",
    ai_writer: "AI Writer",
    ai_writer_modal: {
      replace_text: "Replace existing text",
      describe_text: "Describe what you want to write about:",
      describe_text_placeholder: "Describe what you want to write about",
      word_count: "Word count:",
      update_button: "Update page",
      word_count_error: "Word count must be greater than 0",
    },
    ai_quiz_maker: "AI Quiz Maker",
  },
  question: {
    topic: "Topic {{index}}",
    question: "Question {{index}}",
    answers: "Answers",
    remove_question: "Remove question",
    explanation: "Explanation for the answer",
    reference_page: "Reference page",
    page: "Page",
    add_question: "Add Q&A",
    question_count_one: "{{count}} Question",
    question_count_other: "{{count}} Questions",
    untitled: "Untitled",
    add_answer_here: "Correct answer",
    add_answer_noise_here: "Incorrect answer {{index}}",
    add_explanation_here: "Add explanation here",
    add_reference_page_here: "Add reference page here",
    your_question_is_being_generated: "Your question is being generated",
    required_fields: "Question and correct answer cannot be empty",
  },
  create_course_with_ai: {
    title:
      "In this step, you will need to enter a brief description of the course you want to create, then we can suggest you tailored course contents.",
    create_course_btn: "Create Course with AI",
    your_course_is_being_generated: "Your course is being generated",
    almost_there: "Almost there!",
    it_takes_around_some_minute_to_go: "It takes around some minute to go....",
    ai_is_using_100_of_knowledge_and_heart_to_create_your_course:
      "AI is using 100% of knowledge and heart to create your course",
    done: "Done!",
    next_up: "Next up",
    review: "Review and edit the course content if you wish.",
    assign: "Assign the course to learners.",
    try_again: "Try again",
    generate_detailed_topics: "Generate detailed topics",
    automatically_write_content_for_all_topics: "Automatically write content for all topics",
    edit_your_course_structure_below: "Edit your course structure broken down into brief topics below",
    category: "Category *",
    category_placeholder: "Select a category",
    tone: "Tone *",
    tone_placeholder: "Select a tone",
    language: "Language *",
    language_placeholder: "Select a language",
    learners_background: "Learners Background",
    start_age: "Start age *",
    end_age: "End age *",
    difficulty_level: "Difficulty level *",
    difficulty_level_placeholder: "Select a difficulty level",
    course: "Course",
    course_duration: "Course Duration (max 40 hours)*",
    topic_length: "Topic Length (max 120 minutes)*",
    words_per_topic: "Words per topic",
    main_content: "Main content",
    main_content_placeholder:
      "Please share the content details and learning goals for this lesson. The more detailed and high-quality information you provide, the better the topic will be.",
    upload_file: "Upload reference materials (optional if main content is provided)",
  },
  header: {
    title: "Microtraining",
    logout: "Log out",
  },
  training: {
    about_this_course: "About this course",
    complete_and_continue: "Complete & Continue",
    question_index: "Question {{questionIndex}}",
    check_answer: "Check answer",
    next: "Next",
    retry: "Retry",
    continue: "Continue",
    correct: "Correct",
    there_are_topics_in_course_one: "There is {{count}} topic in this course",
    there_are_topics_in_course_other: "There are {{count}} topics in this course",
    result_answer_correct: "You got {{correctCount}} out of {{totalQuestions}} correct",
    your_progress: "Your progress",
    completed: "Completed",
    back_to_library: "Back to library",
    keep_going_and_finish_strong: "Keep going and finish strong!",
    congratulations_on_finishing_your_course: "Congratulations on finishing your course!",
  },
};

export default en;
