import React, { useCallback } from "react";
import { useTrainingContext } from "contexts/TrainingContext";
import { Stack, CircularProgress, Typography } from "@mui/material";
import Popover from "@mui/material/Popover";
import { useTranslation } from "react-i18next";
import ArrowDown from "assets/icons/arrow-down";

const CurrentProgressView = () => {
  const { t } = useTranslation();
  const { courseOverallAnswerStats } = useTrainingContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handlePopoverClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const percentage = Math.round(courseOverallAnswerStats.percentage);

  return (
    <>
      <Stack
        className="flex-row items-center gap-1 cursor-pointer"
        aria-owns={open ? "current-progress-popover" : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <Stack className="relative">
          <CircularProgress
            variant="determinate"
            className="absolute"
            value={100}
            size={40}
            thickness={4}
            sx={{
              color: "rgba(89,93,98,0.24)",
            }}
          />
          <CircularProgress
            size={40}
            thickness={4}
            className="text-orange-400"
            variant="determinate"
            value={courseOverallAnswerStats.percentage}
          />
          <img className="absolute top-2.5 left-2.5" src="/images/trophy.png" alt="trophy" width={20} height={20} />
        </Stack>
        <Typography className="font-medium text-sm leading-6">{t("course.your_progress")}</Typography>

        <ArrowDown width={20} />
      </Stack>
      <Popover
        id="current-progress-popover"
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        slotProps={{
          paper: {
            sx: {
              mt: 1,
              padding: 1.5,
              borderRadius: "4px",
              border: "solid 1px white",
              boxShadow: "0px 6px 6px -6px rgba(0, 0, 0, 0.16), 0px 0px 1px 0px rgba(0, 0, 0, 0.40)",
            },
          },
        }}
      >
        <Stack gap={1}>
          <Typography className="font-bold text-sm">
            {percentage}% {t("training.completed")}
          </Typography>
          <Typography className="text-sm">
            {percentage === 100
              ? t("training.congratulations_on_finishing_your_course")
              : t("training.keep_going_and_finish_strong")}
          </Typography>
        </Stack>
      </Popover>
    </>
  );
};

export default CurrentProgressView;
