import React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ResponsiveImageView from "components/shared/ResponsiveImageView";
import TopicIcon from "assets/icons/course-topic";
import QuestionIcon from "assets/icons/course-question";
import ArrowRightIcon from "assets/icons/arrow-right";
import BoxItemView from "components/shared/BoxItemView";
import { useGetCourseDetails, useGetListTopics } from "hooks/useCourse";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HTMLPreview from "components/shared/HTMLPreview";
import { useTrainingContext } from "contexts/TrainingContext";
import CourseProgressView from "./CourseProgressView";

interface IAboutCourseViewProps {}

const AboutCourseView: React.FC<IAboutCourseViewProps> = () => {
  const { t } = useTranslation();

  const { courseOverallAnswerStats, handleNextTopic } = useTrainingContext();

  const { course_id: courseId } = useParams<{ course_id: string }>();
  const { data: courseDetail } = useGetCourseDetails(courseId);
  const { data: dataTopic } = useGetListTopics(courseId);

  if (!courseDetail || !dataTopic) return <></>;

  return (
    <Stack gap={3}>
      <ResponsiveImageView
        src={courseDetail.image ?? "/images/unknown-image.png"}
        borderRadius="8px"
        alt="image-course"
        width="100%"
        ratio={16 / 9}
      />

      <Stack gap={1}>
        <Typography className="text-[#313B3D] text-sm font-medium">{t("course.course")}</Typography>
        <Typography className="text-[#313B3D] text-[2.5rem] font-bold leading-9">{courseDetail.name}</Typography>
      </Stack>

      <Stack direction="row" gap={1}>
        <BoxItemView
          icon={<TopicIcon width={20} fill="#313B3D" />}
          label={t("topic.topic_count", {
            topicCount: courseDetail.total_topics.toLocaleString(),
          })}
          className="!bg-[#357E811F] !text-[#313B3D]"
        />
        <BoxItemView
          icon={<QuestionIcon width={20} fill="#313B3D" />}
          label={t("question.question_count", {
            count: courseDetail.total_questions,
          })}
          className="!bg-[#357E811F] !text-[#313B3D]"
        />
      </Stack>

      <Stack gap={1}>
        <Typography className="text-2xl font-bold">{t("course.description")}</Typography>

        <HTMLPreview htmlContent={courseDetail.description} className="text-[#555F60] text-sm " />
      </Stack>

      <Stack gap={2}>
        <Typography className="text-2xl font-bold">{t("course.curriculum")}</Typography>

        <Stack gap={2} className="p-6 rounded-2xl border border-solid border-[#E2E2E2]">
          <Stack gap={2}>
            <Typography className="text-xl font-bold">{t("training.your_progress")}</Typography>
            <CourseProgressView value={courseOverallAnswerStats.percentage} />
          </Stack>
          <Stack gap={1}>
            <Typography className="text-xl font-bold">
              {t("training.there_are_topics_in_course", {
                count: dataTopic.total,
              })}
            </Typography>

            <Stack className="pl-6" gap={2}>
              {dataTopic.data.map((item, index) => {
                const topicTestId = `course_topic_item_${item.id}`;
                return (
                  <Typography
                    key={topicTestId}
                    data-testid={topicTestId}
                    className="text-[#313B3D] text-base font-medium"
                  >
                    {index + 1}. {item.name}
                  </Typography>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <Stack alignItems="center">
        <Button
          className="rounded-lg bg-primary normal-case text-white px-4 py-3 h-10 hover:bg-primary/80 flex items-center gap-2"
          onClick={handleNextTopic}
          data-testid="btn-continue-course"
        >
          {t("training.continue")}
          <ArrowRightIcon height={24} />
        </Button>
      </Stack>
    </Stack>
  );
};

export default AboutCourseView;
