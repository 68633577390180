import React, { KeyboardEvent } from "react";
import { useTranslation } from "react-i18next";

type SearchInputProps = {
  keyword: string;
  setKeyword: (value: string) => void;
  handleKeyPressEnterSearch: (event: KeyboardEvent<HTMLInputElement>) => void;
  handleSearchAction: () => void;
};

const SearchInput: React.FC<SearchInputProps> = ({
  keyword,
  setKeyword,
  handleKeyPressEnterSearch,
  handleSearchAction,
}) => {
  const { t } = useTranslation();
  return (
    <div className="relative lg:w-[400px] w-[200px]">
      <input
        id="txt-search"
        name="txt-search"
        data-testid="txt-search"
        value={keyword}
        onChange={e => setKeyword(e.target.value)}
        onKeyPress={e => handleKeyPressEnterSearch(e)}
        type="text"
        className="w-full rounded-lg form-control no-shadown-mobile border border-solid border-[#E2E2E2] pl-[32px]"
        style={{ height: "37px" }}
        placeholder={t("global.search")}
        autoFocus
      />

      <span className="absolute left-[-12px]">
        <button
          id="btn-search"
          onClick={handleSearchAction}
          style={{ width: "60px", height: "37px" }}
          className="d-flex align-items-center justify-content-center"
          type="button"
        >
          <i className="fa fa-search text-mt-dark-slate"></i>
        </button>
      </span>
    </div>
  );
};

export default SearchInput;
