import React from "react";
import Stack from "@mui/material/Stack";
import HTMLPreview from "components/shared/HTMLPreview";
import { QuestionType } from "types/question";
import { useTranslation } from "react-i18next";
import AnswerItem from "./AnswerItem";

interface IQuestionItemProps {
  question: QuestionType;
  index: number;
}

const QuestionItem: React.FC<IQuestionItemProps> = ({ question, index }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={1.5}>
      <HTMLPreview
        className="font-bold text-base"
        htmlContent={`${t("question.question", { index })}: ${question.hint}`}
      />

      <Stack gap={1}>
        <AnswerItem answer={question.answer.answer} correct />

        {question.answer_noise_1 && <AnswerItem answer={question.answer_noise_1.answer} correct={false} />}
        {question.answer_noise_2 && <AnswerItem answer={question.answer_noise_2.answer} correct={false} />}
        {question.answer_noise_3 && <AnswerItem answer={question.answer_noise_3.answer} correct={false} />}
      </Stack>
    </Stack>
  );
};

export default QuestionItem;
