export const getCSRFToken = () => {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    const meta = metas[i];
    if (meta.getAttribute("name") === "csrf-token") {
      return meta.getAttribute("content");
    }
  }

  return null;
};

export const ReloadPage = () => {
  window.location.href = "/";
  // e.preventDefault();
};

export const fetchDataOption = (method: string, data?: any) => {
  if (getCSRFToken() != null) {
    return {
      method: method,
      headers: {
        "X-CSRF-Token": getCSRFToken(),
        "X-Requested-With": "XMLHttpRequest",
      },
      credentials: "same-origin",
      body: data,
    };
  }

  return null;
};

export const GetDataFromServer = (method: string, url: string) => {
  // @ts-ignore
  fetch(url, fetchDataOption("GET"))
    .then(resp => {
      if (resp.ok) {
        resp.json().then(json => {
          return json;
        });
      } else {
        ReloadPage();
      }
    })
    .catch(function() {
      ReloadPage();
    });
};

export default { fetchDataOption, GetDataFromServer, ReloadPage };
