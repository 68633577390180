import React from "react";
import icon from "./base";

export default icon("AI", ({ fill = "#fff" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_457_1481)">
      <path
        opacity="0.5"
        d="M13.7889 3.14203C14.9759 2.90503 15.9049 1.97603 16.1419 0.789028L16.1999 0.500028C16.2659 0.172028 16.7339 0.172028 16.7999 0.500028L16.8579 0.789028C17.0949 1.97603 18.0239 2.90503 19.2109 3.14203L19.4999 3.20003C19.8279 3.26603 19.8279 3.73403 19.4999 3.80003L19.2109 3.85803C18.0239 4.09503 17.0949 5.02403 16.8579 6.21103L16.7999 6.50003C16.7339 6.82803 16.2659 6.82803 16.1999 6.50003L16.1419 6.21103C15.9049 5.02403 14.9759 4.09503 13.7889 3.85803L13.4999 3.80003C13.1719 3.73403 13.1719 3.26603 13.4999 3.20003L13.7889 3.14203Z"
        fill={fill}
      />
      <path
        d="M4.55193 9.69005C6.13493 9.37305 7.37293 8.13505 7.68993 6.55205L8.38893 3.05605C8.52193 2.38805 9.47793 2.38805 9.61093 3.05605L10.3099 6.55205C10.6269 8.13505 11.8649 9.37305 13.4479 9.69005L16.9439 10.3891C17.6119 10.5221 17.6119 11.4781 16.9439 11.6111L13.4479 12.3101C11.8649 12.6271 10.6269 13.8651 10.3099 15.4481L9.61093 18.9441C9.47793 19.6121 8.52193 19.6121 8.38893 18.9441L7.68993 15.4481C7.37293 13.8651 6.13493 12.6271 4.55193 12.3101L1.05593 11.6111C0.387932 11.4781 0.387932 10.5221 1.05593 10.3891L4.55193 9.69005Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_457_1481">
        <rect width="20" height="20" fill={fill} />
      </clipPath>
    </defs>
  </svg>
));
