import { useEffect, useMemo } from "react";
import { useGetGenerateQuestionsStatus } from "./useQuestion";

const useGenerateQuestionsStatus = (courseId: string) => {
  const { data: dataCourseStatus, refetch } = useGetGenerateQuestionsStatus(courseId);

  const [isCompleted, isHasTopic, topicsGenerating] = useMemo(() => {
    if (!dataCourseStatus) return [false, false, []];

    const isCompleted = dataCourseStatus.status === "completed";
    const isHasTopic = dataCourseStatus.waiting_questions_in_topics?.length > 0;
    const topicsGenerating = dataCourseStatus.waiting_questions_in_topics ?? [];

    return [isCompleted, isHasTopic, topicsGenerating];
  }, [dataCourseStatus]);

  useEffect(() => {
    let intervalId: any;

    if (!isCompleted && courseId) {
      intervalId = setInterval(() => {
        refetch();
      }, 5000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isCompleted, refetch, courseId]);

  return { isCompleted, isHasTopic, topicsGenerating };
};

export default useGenerateQuestionsStatus;
