import React from "react";
import Typography from "@mui/material/Typography";
import GeneratingImg from "assets/images/course_generating_phase_1.gif";

const GeneratingView = ({ label, isBorder }: { label: string; isBorder?: boolean }) => {
  return (
    <div
      className={`flex flex-col w-full items-center justify-center ${
        isBorder ? "border border-gray-200 rounded-lg" : ""
      }`}
    >
      <div className="flex flex-col items-center w-full py-5 bg-white rounded-t-lg">
        <Typography className="font-bold text-2xl text-[#313B3D]">{label}</Typography>
        <img src={GeneratingImg} width={150} height={150} className="mt-4" />
      </div>
    </div>
  );
};

export default GeneratingView;
