import "regenerator-runtime/runtime";
import "font-awesome/css/font-awesome.min.css";
import "react-toastify/dist/ReactToastify.css";
import "../../../client/stylesheets/v2.css.scss";
import "./locales";

import React from "react";

import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import App from "./App";
import AuthProvider from "./contexts/AuthContext";

const queryClient = new QueryClient();

function Layout() {
  const theme = createTheme({
    typography: {
      fontFamily: "Inter, sans-serif",
    },
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            backgroundColor: "#357E81",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            "&.Mui-selected": {
              color: "#124954",
            },
          },
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <AuthProvider>
            <App />
          </AuthProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

export default Layout;
