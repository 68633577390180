import React, { createContext, useState, useEffect, useMemo, useCallback } from "react";
import { AuthType } from "types/auth";
import { Ability } from "@casl/ability";
import { AbilityContext } from "utilities/can";
import { getDataFromLocalStorage } from "utilities/localStorage";
import useAuthContext from "hooks/useAuthContext";
import ReactGA from "react-ga4";

const initialState: AuthType = {
  current_role_organization: "",
  full_name: "",
  organization_name: "",
  organization_id: 0,
  is_loading: true,
  is_setting_up: false,
  current_user_role: "",
  thread_name_word_limit: 0,
  thread_description_word_limit: 0,
  ability: new Ability([]),
  new_permission_system_enabled: false,
  notice_messages: [],
  rules: [],
};

interface AuthContextType {
  infoAppDashboard: AuthType;
  refreshAppInfo: () => void;
  isLoading: boolean;
  isNewPermissionSystem: boolean;
}

const defaultContextValue: AuthContextType = {
  infoAppDashboard: initialState,
  refreshAppInfo: () => {},
  isLoading: true,
  isNewPermissionSystem: false,
};

export const AuthContext = createContext<AuthContextType>(defaultContextValue);

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [infoAppDashboard, setInfoAppDashboard] = useState<AuthType>(initialState);

  const { data: dataInfoAppDashboard, isLoading, refetch: refetchInfoAppDashboard } = useAuthContext();

  useEffect(() => {
    if (!dataInfoAppDashboard) return;

    setInfoAppDashboard(dataInfoAppDashboard);
  }, [dataInfoAppDashboard]);

  useEffect(() => {
    if (
      dataInfoAppDashboard?.is_setting_up &&
      dataInfoAppDashboard?.is_setting_up !== infoAppDashboard?.is_setting_up
    ) {
      const trackingData = getDataFromLocalStorage("trackingData", {});
      ReactGA.event("sign_up", trackingData);
    }
  }, [dataInfoAppDashboard?.is_setting_up, infoAppDashboard?.is_setting_up]);

  const refreshAppInfo = useCallback(() => {
    refetchInfoAppDashboard();
  }, [refetchInfoAppDashboard]);

  const isNewPermissionSystem = ["owner", "admin", "member", "demo"].includes(infoAppDashboard.current_user_role);

  const value = useMemo(
    () => ({
      infoAppDashboard,
      refreshAppInfo,
      isLoading,
      isNewPermissionSystem,
    }),
    [infoAppDashboard, refreshAppInfo, isLoading, isNewPermissionSystem],
  );
  return (
    <AuthContext.Provider value={value}>
      <AbilityContext.Provider value={infoAppDashboard.ability}>{children}</AbilityContext.Provider>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
