import { useState, useCallback } from "react";

const useLocalStorage = (key: string, defaultValue: string) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = localStorage.getItem(key);
      return value ?? defaultValue;
    } catch (error) {
      return defaultValue;
    }
  });

  const setValue = useCallback(
    (value: string) => {
      try {
        setStoredValue(value);
        localStorage.setItem(key, value);
      } catch (error) {
        console.error(error);
      }
    },
    [key],
  );

  return [storedValue, setValue] as const;
};

export default useLocalStorage;
