import { useMutation, useQuery } from "@tanstack/react-query";
import {
  CheckAnswerForCourseQuestionTrainingRequest,
  CheckAnswerForCourseQuestionTrainingResponse,
  LearnCourseTrainingResponse,
  LearnProgressOfCourseResponse,
} from "types/training";
import { fetchDataAsync, postDataAsync } from "utilities/http";

export const useGetQuestionCourseTraining = (courseId: string, firstTime?: boolean) => {
  return useQuery({
    queryKey: ["fetchGetQuestionCourseTraining", courseId],
    queryFn: async () => {
      const data = await fetchDataAsync<LearnCourseTrainingResponse>(
        `/get_question_course_training?id=${courseId}&first_time=${firstTime}`,
        "GET",
      );
      return data;
    },
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });
};

export const useGetLearnProgressOfCourseTraining = (courseId: string) => {
  return useQuery({
    queryKey: ["fetchGetLearnProgressOfCourseTraining", courseId],
    queryFn: async () => {
      const data = await fetchDataAsync<LearnProgressOfCourseResponse>(
        `/get_learn_progress_of_course?id=${courseId}`,
        "GET",
      );
      return data;
    },
    refetchOnWindowFocus: false,
    enabled: !!courseId,
  });
};

export const useCheckAnswerForCourseQuestionTraining = (
  payload: CheckAnswerForCourseQuestionTrainingRequest,
  onSuccess?: (data: CheckAnswerForCourseQuestionTrainingResponse) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["checkAnswerForCourseQuestionTraining", payload],
    async () => {
      const formData = { data: payload };
      const data = await postDataAsync<CheckAnswerForCourseQuestionTrainingResponse>(
        "/check_answer_for_course_question_training",
        formData,
      );
      return data;
    },
    {
      onSuccess: (data: CheckAnswerForCourseQuestionTrainingResponse) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useUpdateSeenThreadInCourseTraining = (
  topicId: number,
  onSuccess?: (data: boolean) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["updateUpdateSeenThreadInCourseTraining", topicId],
    async () => {
      const data = await fetchDataAsync<boolean>(`/seen_thread_in_course_training?course_thread_id=${topicId}`, "POST");
      return data;
    },
    {
      onSuccess: (data: boolean) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message);
      },
    },
  );
};

export const useResetLearnProgressOfCourseTraining = (
  topicId: number,
  onSuccess?: (data: boolean) => void,
  onError?: (error: string) => void,
) => {
  return useMutation(
    ["resetLearnProgressOfCourseTraining", topicId],
    async () => {
      const data = await fetchDataAsync<boolean>(`/reset_learn_progress_of_course?topic_id=${topicId}`, "POST");
      return data;
    },
    {
      onSuccess: (data: boolean) => {
        onSuccess?.(data);
      },
      onError: (error: any) => {
        onError?.(error?.message);
      },
    },
  );
};
