import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "assets/icons/arrow-right";
import { useTranslation } from "react-i18next";

interface IAboutCourseButtonProps {
  isActiveCourse: boolean;
  handleCurrentAboutCourseChange: () => void;
}

const AboutCourseButton: React.FC<IAboutCourseButtonProps> = ({ isActiveCourse, handleCurrentAboutCourseChange }) => {
  const { t } = useTranslation();

  return (
    <Stack className="gap-2" id="about_this_course">
      <Stack gap={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className={`cursor-pointer border border-solid border-[#E4E7EC] px-4 py-[10px] rounded-lg ${
            isActiveCourse ? "bg-[#0B3037] text-white" : "bg-[#357E8105]"
          }`}
          onClick={handleCurrentAboutCourseChange}
          gap={1}
        >
          <Typography className="flex-1 truncate text-sm font-semibold">{t("training.about_this_course")}</Typography>
          <ArrowRightIcon width={24} fill={isActiveCourse ? "white" : "#0B3037"} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default AboutCourseButton;
