import React, { useCallback, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  IconButton,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import AiIcon from "assets/icons/ai";
import { toast } from "react-toastify";
import { useDeleteCourseToOrganization } from "hooks/useCourse";
import { CourseType } from "types/course";
import TruncatedTextWithTooltip from "components/shared/TruncatedTextWithTooltip";
import AssignCourseModal from "./AssignCourseModal";
import DeleteCourseModal from "./DeleteCourseModal";

interface ICourseItemProps {
  course: CourseType;
  refetchCourse: () => void;
}

const CourseCard: React.FC<ICourseItemProps> = ({ course, refetchCourse }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAssignModal, setShowAssignModal] = useState(false);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const { mutate } = useDeleteCourseToOrganization(
    course.id,
    data => {
      if (data) {
        toast.success(t("course.delete_modal.success_msg"));
        setShowDeleteModal(false);
        refetchCourse();

        return;
      }
      toast.error(data.message);
    },
    error => {
      toast.error(error);
    },
  );
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAssignModal = () => {
    setShowAssignModal(false);
  };
  const handleAssignCourseModal = useCallback(() => {
    setShowAssignModal(true);
  }, [showAssignModal]);
  const onAssign = () => {
    setShowAssignModal(false);
  };

  const handleCloseDeleteModal = () => {
    setAnchorEl(null);
    setShowDeleteModal(false);
  };
  const handleDeleteCourseModal = useCallback(() => {
    setAnchorEl(null);
    setShowDeleteModal(true);
  }, [showDeleteModal]);
  const onDelete = useCallback(() => {
    mutate();
  }, []);

  return (
    <>
      <Card
        sx={{
          margin: "auto",
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <CardContent className="p-2">
          <Link to={`/v2/dashboard/courses/${course.id}/edit`}>
            <Stack className="w-full pt-[75%] relative overflow-hidden rounded-2xl border border-solid border-[#E1EBEB]">
              <img
                src={course.image ?? "/images/unknown-image.png"}
                alt="Course Image"
                className="absolute w-full top-0 left-0 object-cover "
              />
            </Stack>
            <TruncatedTextWithTooltip
              text={course.name}
              className="px-4 mt-3 font-bold text-[18px] leading-[30px] line-clamp-2 min-h-[64px]"
            />
          </Link>
          <Stack className="flex-row justify-start items-center gap-1 mb-3 px-4">
            <AiIcon width={20} fill={"#124954"} />
            <Typography variant="body2" color="black">
              {course.creator}
            </Typography>
          </Stack>
          <Box display="flex" gap={1} mt={1} className="px-4">
            <Stack className="px-3 py-1 text-[12px] font-[500] capitalize rounded-[40px] text-black bg-[#357E811F]">
              {course.total_topics || 0} {t("course.topic")}
            </Stack>
            <Stack className="px-3 py-1 text-[12px] font-[500] capitalize rounded-[40px] text-black bg-[#357E811F]">
              {course.total_questions || 0} {t("course.questions")}
            </Stack>
          </Box>
        </CardContent>
        <CardActions className="px-6 mb-3">
          <Button
            onClick={handleAssignCourseModal}
            variant="contained"
            className="bg-primary py-2"
            fullWidth
            sx={{ textTransform: "none", borderRadius: 2 }}
          >
            <Typography className="leading-4 text-[12px] font-bold">{t("course.assign_course")}</Typography>
          </Button>
          <IconButton onClick={handleClick} className="rounded-lg border border-solid border-[#555F6099] p-1">
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={handleClose}>{t("course.action.manage_learners")}</MenuItem>
            <Link to={`/v2/training/course/${course.id}`}>
              <MenuItem onClick={handleClose}>{t("course.action.view_as_learner")}</MenuItem>
            </Link>
            <MenuItem onClick={handleDeleteCourseModal}>{t("course.action.delete")}</MenuItem>
          </Menu>
        </CardActions>
      </Card>
      <DeleteCourseModal
        showDeleteCourseModal={showDeleteModal}
        hideDeleteModal={handleCloseDeleteModal}
        onDelete={onDelete}
      />
      <AssignCourseModal
        courseId={course.id}
        showAssignCourseModal={showAssignModal}
        hideAssignModal={handleCloseAssignModal}
        onAssign={onAssign}
      />
    </>
  );
};

export default CourseCard;
