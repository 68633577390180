import React, { useCallback } from "react";
import { Box, Button, Grid, Stack } from "@mui/material";
import LoadingOverlayView from "components/shared/LoadingOverLayView";
import { CourseLearningType } from "types/course";
import { useCourseContext } from "contexts/CourseContext";
import { useTranslation } from "react-i18next";
import CourseLearningCard from "./CourseLearningCard";

const CoursesLearningGrid: React.FC = () => {
  const [filterValue, setFilterValue] = React.useState("all");
  const { coursesLearningList, isLoadingCourseLearningList, filterLearningCourses } = useCourseContext();
  const { t } = useTranslation();
  const filters = ["all", "in_progress", "completed"];
  const handleSelect = useCallback(
    (value: string) => {
      if (value !== filterValue) {
        setFilterValue(value);
        filterLearningCourses(value);
      }
    },
    [filterValue, filterLearningCourses],
  );
  if (isLoadingCourseLearningList) {
    return <LoadingOverlayView />;
  }

  return (
    <Box minHeight="100vh">
      <Stack className="my-6 flex-row">
        {filters.map((filter, index) => (
          <Button
            className={`px-3 py-1 mr-2 font-bold border rounded-xs normal-case ${
              filterValue === filter ? "bg-[#124954] text-white" : "bg-white text-black"
            }`}
            key={index}
            onClick={() => handleSelect(filter)}
          >
            {t(`course.filter.${filter}`)}
          </Button>
        ))}
      </Stack>
      <Grid container spacing={3} justifyContent="between">
        {coursesLearningList?.map((course: CourseLearningType, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <CourseLearningCard course={course} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CoursesLearningGrid;
