/* eslint-disable max-len */
import React, { useCallback } from "react";
import Button from "@mui/material/Button";
import AddIcon from "assets/icons/add";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useAddQuestionToOrganization } from "hooks/useQuestion";
import { scrollToElement } from "utilities/scroll";

interface IAddQuestionButtonProps {
  courseId: string;
  threadId: string;
  refetch: () => void;
  isDisabled?: boolean;
}

const AddQuestionButton: React.FC<IAddQuestionButtonProps> = ({ courseId, threadId, refetch, isDisabled }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useAddQuestionToOrganization(
    {
      id: 0,
      hint: t("question.untitled"),
      answer: t("question.add_answer_here"),
      answer_noise_1: t("question.add_answer_noise_here", { index: 1 }),
      answer_noise_2: t("question.add_answer_noise_here", { index: 2 }),
      answer_noise_3: t("question.add_answer_noise_here", { index: 3 }),
      course_id: Number(courseId),
      course_thread_id: Number(threadId),
      explanation: t("question.add_explanation_here"),
      reference_page: t("question.add_reference_page_here"),
    },
    data => {
      if (data) {
        toast.success(t("global.added_successfully"));
        refetch();

        setTimeout(() => {
          scrollToElement(`question_item_${data.question.id}`);
        }, 1100);
      }
    },
    error => {
      toast.error(error);
    },
  );

  const handleAddQuestion = useCallback(() => {
    mutate();
  }, [mutate]);

  return (
    <Button
      variant="outlined"
      className="flex items-center gap-2 py-0 h-10 text-[#555F60] normal-case font-bold text-sm rounded-lg border-[#595D62] w-fit"
      onClick={handleAddQuestion}
      disabled={isLoading || isDisabled}
      data-testid="btn-add-question"
    >
      <AddIcon width={24} fill="#595D62" /> {t("question.add_question")}
    </Button>
  );
};

export default AddQuestionButton;
