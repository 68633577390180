const vi = {
  global: {
    homepage: "Trang chủ",
    drop_file: "Kéo thả tệp của bạn vào đây",
    select_file: "Chọn tệp",
    limit_file_size: "Kích thước tệp PDF không được vượt quá 5MB",
    saving: "Đang lưu...",
    saved: "Đã lưu",
    added_successfully: "Thêm mới thành công.",
    deleted_successfully: "Xóa thành công.",
    updated_successfully: "Cập nhật thành công.",
    move_up: "Đưa lên",
    move_down: "Đưa xuống",
    select_a_file: "Chọn một tệp hoặc kéo và thả vào đây",
    file_size_required: "Các tệp JPG, PNG, kích thước tệp không được lớn hơn 10MB",
    something_went_wrong: "Đã có lỗi xảy ra",
    search: "Tìm kiếm",
    page_not_found: "Không tìm thấy trang",
    back_to_home: "Quay lại trang chủ",
    close: "Đóng",
    back: "Quay lại",
    you_need_access_to_learn_this_course: "Bạn cần có quyền truy cập để học khóa học này",
    welcome_to_the_training_platform: "Chào mừng bạn đến với nền tảng đào tạo",
    login_to_continue: "Đăng nhập / Đăng ký để bắt đầu",
    sign_in_with_google: "Đăng nhập với Google",
    user_does_not_belong_to_this_organization: "Người dùng không thuộc tổ chức này.",
    file_size_too_large: "Kích thước tệp quá lớn",
  },
  course: {
    course: "Khóa học",
    title: "Tiêu đề",
    description: "Nội dung",
    question: "Câu hỏi",
    tabs: {
      course: "khoá học",
      learning: "đang học",
    },
    library: "Thư viện",
    create_with_ai: "Tạo mới khoá học với AI",
    using_document: "Sử dụng tài liệu sẵn có",
    create_btn: "Tạo mới với AI",
    assign_course: "Giao khoá học",
    assigned_by: "Giao bởi",
    already_enrolled: "đã đăng ký",
    topic: "Chủ đề",
    questions: "Câu hỏi",
    curriculum: "Chương trình giảng dạy",
    action: {
      edit: "Sửa",
      manage_learners: "Quản lý học viên",
      view_as_learner: "Xem với tư cách là người học",
      delete: "Xoá",
    },
    delete_modal: {
      title: "Xoá khoá học",
      content: "Bạn có chắc chắn muốn xoá khoá học?",
      cancel: "Huỷ bỏ",
      accept: "Chấp nhận",
      success_msg: "Xoá khoá học thành công",
    },
    assign_modal: {
      title: "Chỉ định khoá học",
      label: "Học viên:",
      placeholder: "Nhập email",
      assign: "Chỉ định học viên",
      success_msg: "Chỉ định học viên thành công",
    },
    back_to_library: "Quay lại thư viện",
    your_progress: "Tiến độ của bạn",
    learning: {
      complete: "Hoàn thành",
      completed: "Đã hoàn thành",
      resume_course: "Tiếp tục học",
      start_course: "Bắt đầu học",
      view_course: "Xem khoá học",
      filter: {
        all: "Tất cả",
        completed: "Đã hoàn thành",
        in_progress: "Đang học",
      },
    },
    edit_course: "Chỉnh sửa khoá học",
    filter: {
      all: "Tất cả",
      completed: "Đã hoàn thành",
      in_progress: "Đang học",
    },
    name_required: "Tên khóa học không được trống",
    description_required: "Mô tả khóa học không được trống",
    field_required: "Trường này không được trống",
  },
  topic: {
    topic: "Chủ đề",
    count_words: "Số từ",
    thread_word_limit: "Số từ không được lớn hơn {{word_limit}}",
    add_topic: "Thêm chủ đề",
    add_question: "Add câu hỏi",
    delete_topic: "Xóa chủ đề",
    topic_count: "{{topicCount}} Chủ đề",
    untitled: "Không có tiêu đề",
    add_content_here: "Thêm nội dung vào đây",
    name_required: "Tên topic không được trống",
    description_required: "Mô tả topic không được trống",
    ai_writer: "Viết bởi AI",
    ai_writer_modal: {
      replace_text: "Thay thế văn bản hiện có",
      describe_text: "Mô tả điều bạn muốn viết:",
      describe_text_placeholder: "Nhập mô tả điều bạn muốn viết",
      word_count: "Số từ:",
      update_button: "Cập nhật trang",
      word_count_error: "Số từ phải lớn hơn 0",
    },
    ai_quiz_maker: "Tạo bài kiểm tra bằng AI",
  },
  question: {
    topic: "Chủ đề {{index}}",
    question: "Câu hỏi {{index}}",
    answers: "Câu trả lời",
    remove_question: "Xóa câu hỏi",
    explanation: "Giải thích cho câu trả lời",
    reference_page: "Trang tham khảo",
    page: "Trang",
    add_question: "Thêm câu hỏi",
    question_count_one: "{{count}} Câu hỏi",
    question_count_other: "{{count}} Câu hỏi",
    untitled: "Không có tiêu đề",
    add_answer_here: "Câu trả lời đúng",
    add_answer_noise_here: "Câu trả lời sai {{index}}",
    add_explanation_here: "Thêm giải thích vào đây",
    add_reference_page_here: "Thêm trang tham khảo vào đây",
    required_fields: "Câu hỏi và câu trả lời đúng không được trống",
  },
  create_course_with_ai: {
    title:
      "Ở bước này, bạn sẽ cần nhập mô tả ngắn gọn về khóa học bạn muốn tạo, sau đó chúng tôi có thể đề xuất cho bạn nội dung khóa học phù hợp.",
    create_course_btn: "Tạo khóa học bằng AI",
    your_course_is_being_generated: "Khóa học của bạn đang được tạo",
    almost_there: "Sắp hoàn tất!",
    it_takes_around_some_minute_to_go: "Sẽ mất một chút thời gian để hoàn thành...",
    ai_is_using_100_of_knowledge_and_heart_to_create_your_course:
      "AI đang sử dụng 100% kiến thức và tâm huyết để tạo ra khóa học của bạn",
    done: "Hoàn tất!",
    next_up: "Tiếp theo",
    review: "Xem lại và chỉnh sửa nội dung khóa học nếu bạn muốn.",
    assign: "Giao khóa học cho người học.",
    try_again: "Thử lại",
    generate_detailed_topics: "Tạo chi tiết chủ đề",
    automatically_write_content_for_all_topics: "Tự động viết nội dung cho tất cả chủ đề",
    edit_your_course_structure_below: "Chỉnh sửa cấu trúc khóa học được phân chia thành chủ đề ngắn gọn bên dưới",
    category: "Danh mục *",
    category_placeholder: "Chọn một danh mục",
    tone: "Tone *",
    tone_placeholder: "Chọn một tone",
    language: "Ngôn ngữ *",
    language_placeholder: "Chọn một ngôn ngữ",
    learners_background: "Nền tảng của người học",
    start_age: "Độ tuổi bắt đầu *",
    end_age: "Độ tuổi kết thúc *",
    difficulty_level: "Mức độ khó *",
    difficulty_level_placeholder: "Chọn mức độ khó",
    course: "Khoá học",
    course_duration: "Thời gian khoá học (tối đa 40 tiếng)*",
    topic_length: "Độ dài chủ đề (tối đa 120 phút)*",
    words_per_topic: "Số từ mỗi chủ đề",
    main_content: "Nội dung chính",
    main_content_placeholder:
      "Hãy chia sẻ chi tiết nội dung và mục tiêu học tập của bài học này nhé. Thông tin bạn cung cấp càng chi tiết và chất lượng cao thì chủ đề sẽ càng hay.",
    upload_file: "Tải lên tài liệu tham khảo (tùy chọn nếu cung cấp nội dung chính)",
  },
  header: {
    title: "Microtraining",
    logout: "Đăng xuất",
  },
  training: {
    about_this_course: "Chi tiết khóa học",
    complete_and_continue: "Hoàn thành & Tiếp tục",
    question_index: "Câu hỏi {{questionIndex}}",
    check_answer: "Kiểm tra câu trả lời",
    next: "Tiếp tục",
    retry: "Thử lại",
    continue: "Tiếp tục",
    correct: "Đúng",
    there_are_topics_in_course_one: "Có {{count}} chủ đề trong khóa học này",
    there_are_topics_in_course_other: "Có {{count}} chủ đề trong khóa học này",
    result_answer_correct: "Bạn trả lời đúng {{correctCount}} trên {{totalQuestions}} câu hỏi",
    your_progress: "Tiến độ của bạn",
    completed: "Đã hoàn thành",
    back_to_library: "Quay lại thư viện",
    keep_going_and_finish_strong: "Tiếp tục cố gắng và hoàn thành thật xuất sắc!",
    congratulations_on_finishing_your_course: "Chúc mừng bạn đã hoàn thành khóa học!",
  },
};

export default vi;
