import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Stack, Tab, Tabs, Typography } from "@mui/material";
import { useCourseContext } from "contexts/CourseContext";
import useLocalStorage from "hooks/useLocalStorage";
import SearchInput from "../../shared/SearchInputView";
import CourseGrid from "./components/CourseGrid";
import CoursesLearningGrid from "./components/CoursesLearningGrid";

const View: React.FC = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useLocalStorage("currentTab", "courses");
  const { isLoading, coursesList, refetchDataCourses, searchDataCourses } = useCourseContext();
  const [keyword, setKeyword] = useState("");
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setKeyword("");
    setTab(newValue);
  };
  const handleSearchAction = () => {
    searchDataCourses(keyword);
  };

  const handleKeyPressEnterSearch = (e: any) => {
    if (e.key === "Enter") {
      handleSearchAction();
    }
  };

  return (
    <div className="container my-[30px] mx-auto lg:px-20 px-6">
      <Stack className="flex-row justify-between items-center">
        <Typography className="text-mt-dark-gray text-2xl leading-8 font-bold capitalize">
          {t("course.library")}
        </Typography>
        <SearchInput {...{ handleSearchAction, handleKeyPressEnterSearch, keyword, setKeyword }} />
      </Stack>
      <Tabs className="relative" value={tab} onChange={handleChangeTab}>
        <Tab label={t("course.tabs.course")} value="courses" sx={{ textTransform: "capitalize", fontWeight: "bold" }} />
        <Tab
          label={t("course.tabs.learning")}
          value="learning"
          sx={{ textTransform: "capitalize", fontWeight: "bold" }}
        />
        <Stack className="absolute h-[1px] bottom-[-1px] w-[100vw] border border-solid border-[#D1DAD9]"></Stack>
      </Tabs>
      {tab === "courses" && (
        <CourseGrid isLoading={isLoading} coursesList={coursesList} refetchDataCourses={refetchDataCourses} />
      )}
      {tab === "learning" && <CoursesLearningGrid />}
    </div>
  );
};

export default View;
