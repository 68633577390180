import React from "react";

interface ITextInputViewProps {
  id: string;
  name: string;
  value: any;
  error?: string;
  label?: string;
  type?: "input" | "textarea";
  onChangeTextInput: (name: string, value: any) => void;
}

const TextInputView: React.FC<ITextInputViewProps> = ({ type, value, error, name, label, id, onChangeTextInput }) => {
  const TagName = type || "input";

  return (
    <div className="flex flex-col gap-1 form-group">
      {label && (
        <label htmlFor={name + "_text"} className="text-base font-bold">
          {label}:
        </label>
      )}
      <TagName
        id={`${name}_text`}
        name={name}
        type="text"
        className={`form-control ${name}_text bg-white border border-solid ${
          error ? "border-red-600" : "border-[#E2E2E2] focus:border-primary"
        } rounded-lg text-sm p-3`}
        value={value}
        rows={4}
        onChange={(e: any) => onChangeTextInput(name, e.target.value)}
        onClick={e => e.stopPropagation()}
        data-testid={id}
      />
      {error && (
        <p id={id + "-error"} className="text-danger m-b-0 p-t-5">
          {error}
        </p>
      )}
    </div>
  );
};
export default TextInputView;
