import React from "react";
import LearnCourseFinishImg from "assets/images/learn_course_finish.gif";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import ArrowRightIcon from "assets/icons/arrow-right";
import { AnswerStatsType } from "types/training";
import { useTrainingContext } from "contexts/TrainingContext";

interface ILearnCourseFinishViewProps {
  isLoadingResetQuiz: boolean;
  handleRetryTopic: () => void;
  answerStats: AnswerStatsType;
  isLastTopic?: boolean;
}

const LearnCourseFinishView: React.FC<ILearnCourseFinishViewProps> = ({
  isLoadingResetQuiz,
  isLastTopic,
  handleRetryTopic,
  answerStats,
}) => {
  const { t } = useTranslation();
  const { handleNextTopic } = useTrainingContext();

  return (
    <Stack alignItems="center" justifyContent="center" className="h-full" data-testid="learn-course-finish-view">
      <img src={LearnCourseFinishImg} width={216} height={238} className="mt-4" />

      <Typography className="font-bold text-xl">
        {t("training.result_answer_correct", {
          correctCount: answerStats.correct,
          totalQuestions: answerStats.total,
        })}
      </Typography>

      <Stack direction="row" alignItems="center" className="mt-4" gap={2} mt={2}>
        <Button
          className="normal-case rounded-lg border border-solid border-primary text-primary disabled:border-primary/20 h-10 px-10"
          onClick={handleRetryTopic}
          disabled={isLoadingResetQuiz}
          data-testid="learn-course-finish-view-retry-button"
        >
          {t("training.retry")}
        </Button>
        <Button
          className="normal-case rounded-lg bg-primary hover:bg-primary/80 text-white disabled:text-white/80 h-10 px-3 flex items-center gap-2"
          onClick={isLastTopic ? undefined : handleNextTopic}
          disabled={isLoadingResetQuiz}
          LinkComponent={isLastTopic ? "a" : undefined}
          href={isLastTopic ? "/v2/dashboard/courses" : undefined}
          data-testid="learn-course-finish-view-next-button"
        >
          {isLastTopic ? t("training.back_to_library") : t("training.continue")}
          <ArrowRightIcon width={24} height={24} />
        </Button>
      </Stack>
    </Stack>
  );
};

export default LearnCourseFinishView;
