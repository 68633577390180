import React, { useCallback } from "react";
import { useParams } from "react-router-dom";
import { TopicType } from "types/topic";
import { useCourseViewOrEditContext } from "contexts/CourseViewOrEditContext";
import { ActionType, ViewModeType } from "./types";
import View from "./view";

interface IContainerProps {
  action: ActionType;
  isCompleted: boolean;
  isHasTopic: boolean;
  topicsGenerating: number[];
}

const Container: React.FC<IContainerProps> = ({ action, isCompleted, isHasTopic, topicsGenerating }) => {
  const { course_id: courseId } = useParams<{ course_id: string }>();
  const [currentTopicQuizSelected, setCurrentTopicQuizSelected] = React.useState<string>();
  const [viewModeType, setViewModeType] = React.useState<ViewModeType>("topic");
  const { handleSetCurrentTopic } = useCourseViewOrEditContext();

  const handleCurrentTopicChange = useCallback((topic: TopicType) => {
    handleSetCurrentTopic(topic);
    setCurrentTopicQuizSelected(undefined);
    setViewModeType("topic");
  }, []);

  const handleCurrentTopicQuizChange = useCallback((topic: TopicType, quizId: string) => {
    handleSetCurrentTopic(topic);
    setCurrentTopicQuizSelected(quizId);
    setViewModeType("quiz");
  }, []);

  return (
    <View
      action={action}
      courseId={courseId}
      currentTopicQuizSelected={currentTopicQuizSelected}
      handleCurrentTopicChange={handleCurrentTopicChange}
      handleCurrentTopicQuizChange={handleCurrentTopicQuizChange}
      viewModeType={viewModeType}
      isCompleted={isCompleted}
      isHasTopic={isHasTopic}
      topicsGenerating={topicsGenerating}
    />
  );
};

export default Container;
