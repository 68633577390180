import React, { useCallback, useEffect, useMemo } from "react";
import Stack from "@mui/material/Stack";
import TextInputView from "components/shared/TextInputView";
import { useUpdateCourseToOrganization } from "hooks/useCourse";
import { useTranslation } from "react-i18next";
import { CourseType, UpdateCourseTypeRequest } from "types/course";
import debounce from "lodash/debounce";
import ImageInputView from "components/shared/ImageInputView";
import { toast } from "react-toastify";
import { useCourseViewOrEditContext } from "contexts/CourseViewOrEditContext";
import EditorView from "components/shared/EditorView";
import { Typography } from "@mui/material";
import sanitizeHtml from "sanitize-html";

interface ICourseEditProps {
  courseDetail: CourseType;
}

const CourseEdit: React.FC<ICourseEditProps> = ({ courseDetail }) => {
  const { t } = useTranslation();
  const { handleShowSaving, handleLoading } = useCourseViewOrEditContext();

  const [formState, setFormState] = React.useState<UpdateCourseTypeRequest>({
    id: courseDetail.id,
    name: courseDetail.name,
    description: courseDetail.description,
  });

  const { mutate, isLoading } = useUpdateCourseToOrganization(
    {
      id: formState.id,
      name: formState.name.trim(),
      description: sanitizeHtml(formState.description.trim()),
      image: formState.image,
      image_file: formState.image_file,
    },
    data => {
      if (data && data.errors) {
        handleShowSaving(false);
      }
    },
    error => {
      toast.error(error);
    },
  );

  const debouncedSave = useMemo(
    () =>
      debounce((newName: string, newDescription: string) => {
        const trimmedName = newName.trim();
        const trimmedDescription = sanitizeHtml(newDescription.trim());

        if (!trimmedName) {
          toast.error(t("course.name_required"));
          return;
        }

        if (!trimmedDescription) {
          toast.error(t("course.description_required"));
          return;
        }

        if (courseDetail.name !== trimmedName || courseDetail.description !== trimmedDescription) {
          mutate();
        }
      }, 2000),
    [courseDetail, mutate, t],
  );

  const handleFieldChange = useCallback(
    (name: string, value: string) => {
      setFormState(prev => {
        const next = { ...prev, [name]: value };
        debouncedSave(next.name, next.description);
        return next;
      });
    },
    [debouncedSave, formState.name, formState.description],
  );

  useEffect(() => {
    if (formState.image_file) {
      mutate();
    }
  }, [formState.image_file, mutate]);

  const handleChangeSelectImage = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      const imageUrl = URL.createObjectURL(selectedFile);

      setFormState(prev => ({
        ...prev,
        image_file: selectedFile,
        image: imageUrl,
      }));
    }
  }, []);

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    handleLoading(isLoading);
    if (isLoading) handleShowSaving(true);

    if (!isLoading) {
      const timer = setTimeout(() => {
        handleShowSaving(false);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [isLoading]);

  return (
    <Stack className="container relative py-4" gap={2}>
      <ImageInputView
        label="Course image"
        buttonText="Change"
        onChangeSelectImage={handleChangeSelectImage}
        image={formState.image ?? courseDetail?.image ?? "/images/unknown-image.png"}
        inputId="image_course"
      />

      <TextInputView
        name="name"
        label={t("course.title")}
        value={formState.name}
        id="edit_name_txt"
        onChangeTextInput={handleFieldChange}
      />

      <Stack>
        <Typography className="mb-2 text-base font-bold">{t("course.description")}:</Typography>
        <EditorView
          id={`edit_description_txt_${courseDetail.id}`}
          name="description"
          value={formState.description}
          onChangeContent={(newContent, name) => handleFieldChange(name, newContent)}
          maxHeight="200px"
        />
      </Stack>
    </Stack>
  );
};

export default CourseEdit;
