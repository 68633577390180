/* eslint-disable max-len */
import React, { useCallback, useEffect, useMemo } from "react";
import Stack from "@mui/material/Stack";
import { QuestionType, UpdateQuestionTypeRequest } from "types/question";
import { useTranslation } from "react-i18next";
import { useDeleteQuestionToOrganization, useUpdateQuestionToOrganization } from "hooks/useQuestion";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import TextInputView from "components/shared/TextInputView";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DetailIcon from "assets/icons/detail";
import AreaIcon from "assets/icons/area";
import EditorView from "components/shared/EditorView";
import { useCourseViewOrEditContext } from "contexts/CourseViewOrEditContext";
import sanitizeHtml from "sanitize-html";
import AnswerItem from "./AnswerItem";

interface IQuestionItemProps {
  question: QuestionType;
  index: number;
  refetchListQuestion: () => void;
}

const QuestionItem: React.FC<IQuestionItemProps> = ({ question, index, refetchListQuestion }) => {
  const { t } = useTranslation();
  const { handleShowSaving, handleLoading } = useCourseViewOrEditContext();

  const [formState, setFormState] = React.useState<UpdateQuestionTypeRequest>({
    id: question.id,
    hint: question.hint,
    answer: question.answer.answer,
    answer_noise_1: question.answer_noise_1?.answer,
    answer_noise_2: question.answer_noise_2?.answer,
    answer_noise_3: question.answer_noise_3?.answer,
    course_thread_id: question.course_thread_id,
    course_id: question.course_id,
    explanation: question.question_explanation?.explanation,
    reference_page: question.question_explanation?.reference_page?.toString(),
  });

  const { mutate: mutateDeleteQuestion, isLoading: isLoadingDeleteQuestion } = useDeleteQuestionToOrganization(
    question.id,
    () => {
      refetchListQuestion();
    },
    error => {
      toast.error(error);
    },
  );

  const { mutate: mutateUpdateQuestion, isLoading: isLoadingUpdateQuestion } = useUpdateQuestionToOrganization(
    {
      id: formState.id,
      hint: formState.hint?.trim(),
      answer: formState.answer?.trim(),
      answer_noise_1: formState.answer_noise_1?.trim(),
      answer_noise_2: formState.answer_noise_2?.trim(),
      answer_noise_3: formState.answer_noise_3?.trim(),
      course_id: formState.course_id,
      course_thread_id: formState.course_thread_id,
      explanation: sanitizeHtml(formState.explanation ?? ""),
      reference_page: formState.reference_page,
    },
    data => {
      if (data && data.errors) {
        handleShowSaving(false);
      }
    },
    error => {
      toast.error(error);
    },
  );

  const debouncedSave = useMemo(
    () =>
      debounce((newState: UpdateQuestionTypeRequest) => {
        const trimmedHint = newState.hint?.trim();
        const trimmedAnswer = newState.answer?.trim();
        const trimmedExplanation = sanitizeHtml(newState.explanation?.trim() ?? "");

        if (!trimmedHint || !trimmedAnswer) {
          toast.error(t("question.required_fields"));
          return;
        }

        if (
          question.hint !== trimmedHint ||
          question.answer.answer !== trimmedAnswer ||
          question.answer_noise_1?.answer !== newState.answer_noise_1?.trim() ||
          question.answer_noise_2?.answer !== newState.answer_noise_2?.trim() ||
          question.answer_noise_3?.answer !== newState.answer_noise_3?.trim() ||
          question.question_explanation?.explanation !== trimmedExplanation ||
          question.question_explanation?.reference_page?.toString() !== newState.reference_page
        ) {
          mutateUpdateQuestion();
        }
      }, 2000),
    [question, mutateUpdateQuestion, t],
  );

  const handleFieldChange = useCallback(
    (name: string, value: string) => {
      setFormState(prev => {
        const newState = {
          ...prev,
          [name]: value,
        };
        debouncedSave(newState);
        return newState;
      });
    },
    [debouncedSave],
  );

  const editorOnChange = useCallback(
    (value: string, name: string) => {
      handleFieldChange(name, value);
    },
    [handleFieldChange],
  );

  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  useEffect(() => {
    handleLoading(isLoadingUpdateQuestion);
    if (isLoadingUpdateQuestion) {
      handleShowSaving(true);
    } else {
      const timer = setTimeout(() => {
        handleShowSaving(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isLoadingUpdateQuestion, handleLoading, handleShowSaving]);

  return (
    <Stack
      gap={2}
      className="relative"
      id={`question_item_${question.id}`}
      data-testid={`question_item_${question.id}`}
    >
      <TextInputView
        name="hint"
        label={t("question.question", { index })}
        value={formState.hint}
        id={`edit_hint_txt_${question.id}`}
        onChangeTextInput={handleFieldChange}
      />

      <Stack gap={1} className="px-4">
        <Typography className="text-base">{t("question.answers")}:</Typography>

        <AnswerItem name="answer" handleFieldChange={handleFieldChange} answer={formState.answer || ""} correct />

        <AnswerItem
          name="answer_noise_1"
          handleFieldChange={handleFieldChange}
          answer={formState.answer_noise_1 || ""}
          correct={false}
        />
        <AnswerItem
          name="answer_noise_2"
          handleFieldChange={handleFieldChange}
          answer={formState.answer_noise_2 || ""}
          correct={false}
        />
        <AnswerItem
          name="answer_noise_3"
          handleFieldChange={handleFieldChange}
          answer={formState.answer_noise_3 || ""}
          correct={false}
        />
      </Stack>

      <Stack className="p-4 bg-[#FBFAF3] rounded-2xl" gap={3}>
        <Stack gap={1}>
          <Typography className="flex items-center gap-2 text-base font-bold" component="div">
            <DetailIcon width={24} />
            {t("question.explanation")}:
          </Typography>
          <EditorView
            id={`edit_dexplanation_txt_${question.id}`}
            name="explanation"
            value={formState.explanation}
            onChangeContent={editorOnChange}
            maxHeight="69px"
          />
        </Stack>

        <Stack gap={1}>
          <Typography className="flex items-center gap-2 text-base font-bold" component="div">
            <AreaIcon width={24} />
            {t("question.reference_page")}:
          </Typography>
          <TextInputView
            name="reference_page"
            value={formState.reference_page}
            id={`edit_reference_page_txt_${question.id}`}
            onChangeTextInput={handleFieldChange}
          />
        </Stack>
      </Stack>

      <Button
        variant="outlined"
        className="flex items-center gap-2 py-2 text-black normal-case font-bold text-sm rounded-lg border-[#595D62] w-fit"
        onClick={() => {
          mutateDeleteQuestion();
        }}
        disabled={isLoadingDeleteQuestion}
        data-testid={`delete_question_${question.id}`}
      >
        {t("question.remove_question")}
      </Button>
    </Stack>
  );
};

export default QuestionItem;
