import React from "react";
import { Box, Card, CardContent, CardActions, Button, Typography, Stack, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import AiIcon from "assets/icons/ai";
import { Link } from "react-router-dom";
import { CourseLearningType } from "types/course";
import TruncatedTextWithTooltip from "components/shared/TruncatedTextWithTooltip";

interface ICourseItemProps {
  course: CourseLearningType;
}

const CourseLearningCard: React.FC<ICourseItemProps> = ({ course }) => {
  const { t } = useTranslation();
  const { total_learned, total_questions, total_topics } = course;
  let progress = 0;
  if (total_questions > 0) {
    progress = Math.round((total_learned / total_questions) * 100);
  }

  return (
    <>
      <Card
        sx={{
          margin: "auto",
          borderRadius: 3,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <CardContent className="p-2">
          <Link to={`/v2/training/course/${course.id}`}>
            <Stack className="w-full pt-[75%] relative overflow-hidden rounded-2xl border border-solid border-[#E1EBEB]">
              <img
                src={course.image ?? "/images/unknown-image.png"}
                alt="Course Image"
                className="absolute w-full top-0 left-0 object-cover "
              />
            </Stack>
            <TruncatedTextWithTooltip
              text={course.name}
              className="px-4 mt-3 font-bold text-[18px] leading-[30px] line-clamp-2 min-h-[64px]"
            />
          </Link>
          <Stack className="flex-row justify-start items-center gap-1 mb-3 px-4">
            <AiIcon width={20} fill={"#124954"} />
            <Typography component="span" className="mr-1 text-mt-dark-slate text-sm">
              {t("course.assigned_by")}:
              <Typography component="span" className="ml-1 text-black text-sm">
                {course.assigned_by}
              </Typography>
            </Typography>
          </Stack>
          <Box display="flex" gap={1} mt={1} className="px-4">
            <Stack className="px-3 py-1 text-[12px] font-[500] capitalize rounded-[40px] text-black bg-[#357E811F]">
              {total_topics || 0} {t("course.topic")}
            </Stack>
            <Stack className="px-3 py-1 text-[12px] font-[500] capitalize rounded-[40px] text-black bg-[#357E811F]">
              {total_questions || 0} {t("course.questions")}
            </Stack>
          </Box>
          {progress < 100 && (
            <Stack className="px-4">
              <LinearProgress
                sx={{
                  backgroundColor: "rgba(89,93,98,0.2)",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#FFB319",
                  },
                }}
                className="mt-6 mb-2"
                variant="determinate"
                value={progress}
              />
              <Typography variant="body2" className="text-black ">
                <Typography component="span" display="inline" variant="body2" className="text-black font-bold mr-1">
                  {progress}%
                </Typography>
                {t("course.learning.complete")}
              </Typography>
            </Stack>
          )}
          {progress === 100 && (
            <Stack className="flex-row items-center gap-1 px-4 mt-6">
              <img src="/images/trophy.png" alt="trophy"></img>
              <Typography variant="body2" className="text-black font-bold">
                {t("course.learning.completed")}
              </Typography>
            </Stack>
          )}
        </CardContent>
        <CardActions className="px-6 mb-3">
          <Button
            href={`/v2/training/course/${course.id}`}
            variant="contained"
            className="bg-primary py-2"
            fullWidth
            sx={{ textTransform: "none", borderRadius: 2 }}
          >
            <Typography className="leading-4 text-[12px] font-bold">
              {progress === 0 && t("course.learning.start_course")}
              {progress > 0 && progress < 100 && t("course.learning.resume_course")}
              {progress === 100 && t("course.learning.view_course")}
            </Typography>
          </Button>
        </CardActions>
      </Card>
    </>
  );
};

export default CourseLearningCard;
