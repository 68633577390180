import React from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import ResponsiveImageView from "components/shared/ResponsiveImageView";
import TopicIcon from "assets/icons/course-topic";
import QuestionIcon from "assets/icons/course-question";
import BoxItemView from "components/shared/BoxItemView";
import { useTranslation } from "react-i18next";
import { CourseType } from "types/course";

interface ICourseViewProps {
  courseDetail: CourseType;
}

const CourseView: React.FC<ICourseViewProps> = ({ courseDetail }) => {
  const { t } = useTranslation();

  if (!courseDetail) return <></>;

  return (
    <Stack className=" bg-[#07171A] py-10 px-20">
      <div className="container flex items-center gap-9">
        <ResponsiveImageView
          src={courseDetail.image ?? "/images/unknown-image.png"}
          borderRadius="8px"
          alt="image-course"
          width="232px"
        />

        <Stack gap={3}>
          <Typography className="text-white text-[2rem] font-bold">{courseDetail.name}</Typography>
          <Typography className="text-[#D2D2D2] text-sm">{courseDetail.description}</Typography>

          <Stack direction="row" gap={1}>
            <BoxItemView
              icon={<TopicIcon width={20} />}
              label={t("topic.topic_count", {
                topicCount: courseDetail.total_topics.toLocaleString(),
              })}
            />
            <BoxItemView
              icon={<QuestionIcon width={20} />}
              label={t("question.question_count", {
                count: courseDetail.total_questions,
              })}
            />
          </Stack>
        </Stack>
      </div>
    </Stack>
  );
};

export default CourseView;
