import React, { useCallback } from "react";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoveUp from "assets/icons/move-up";
import MoveDown from "assets/icons/move-down";
import Delete from "assets/icons/delete";
import { useDeleteTopicToOrganization, useMoveTopicInCourse } from "hooks/useTopic";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface ITopicOptionsButtonProps {
  topicId: number;
  refetchListTopic: () => void;
  isFirst?: boolean;
  isLast?: boolean;
}

const TopicOptionsButton: React.FC<ITopicOptionsButtonProps> = ({ isFirst, isLast, topicId, refetchListTopic }) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { mutate: mutateDelete, isLoading: isLoadingDelete } = useDeleteTopicToOrganization(
    topicId,
    () => {
      refetchListTopic();
      toast.success(t("global.deleted_successfully"));
    },
    error => {
      toast.error(error);
    },
  );

  const { mutate: mutateMoveTopic, isLoading: isLoadingMoveTopic } = useMoveTopicInCourse(
    topicId,
    () => {
      refetchListTopic();
      toast.success(t("global.updated_successfully"));
    },
    error => {
      toast.error(error);
    },
  );

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleMoveUpDown = useCallback(
    (isMoveUp: boolean) => {
      mutateMoveTopic(isMoveUp);
      handleClose();
    },
    [mutateMoveTopic, handleClose],
  );

  const handleDeleteTopic = useCallback(() => {
    mutateDelete();
    handleClose();
  }, [mutateDelete, handleClose]);

  return (
    <div>
      <IconButton
        aria-label="more"
        id="topic-options-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="text-primary bg-[#12495414] rounded-lg"
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="topic-options-button"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            style: {
              backgroundColor: "white",
              width: "150px",
              borderRadius: "12px",
            },
          },
        }}
      >
        <MenuItem
          onClick={() => handleMoveUpDown(true)}
          className="gap-2 items-center py-3"
          disabled={isFirst || isLoadingMoveTopic}
          data-testid="move-topic-up-button"
        >
          <MoveUp width={16} />
          {t("global.move_up")}
        </MenuItem>
        <MenuItem
          onClick={() => handleMoveUpDown(false)}
          className="gap-2 items-center py-3"
          disabled={isLast || isLoadingMoveTopic}
          data-testid="move-topic-down-button"
        >
          <MoveDown width={16} />
          {t("global.move_down")}
        </MenuItem>
        <MenuItem
          onClick={handleDeleteTopic}
          className="text-[#FF4E64] gap-2 items-center py-3"
          disabled={isLoadingDelete}
          data-testid="delete-topic-button"
        >
          <Delete width={16} />
          {t("topic.delete_topic")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default TopicOptionsButton;
