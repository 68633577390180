import {
  AnswerStatsType,
  CheckAnswerForCourseQuestionTrainingResponse,
  CourseOverallAnswerStatsType,
  QuestionsInTopicsType,
} from "types/training";

export const determineAnswerBackgroundClass = ({
  answer,
  answerSelected,
  answerIndex,
  currentAnswer,
  answerInputed,
}: {
  answer?: CheckAnswerForCourseQuestionTrainingResponse;
  answerSelected?: number;
  answerIndex: number;
  currentAnswer: string;
  answerInputed: string;
}) => {
  // No answer selected yet
  if (!answer && !answerSelected) {
    return "bg-mt-white-smoke";
  }

  // Answer has been selected
  if (!answer && answerSelected === answerIndex) {
    return "bg-mt-pale-blue-gray";
  }

  // Correct answer
  if (
    (answer?.data.answer_is_true === true && answer?.data.answer_input === currentAnswer) ||
    (answer?.data.answer_is_true === false && answer?.data.answer === currentAnswer) ||
    (answer?.data.answer_is_true === false && answer?.data.answer === answerInputed)
  ) {
    return "bg-mt-medium-sea-green";
  }

  // Incorrect answer
  if (!answer?.data.answer_is_true && answer?.data.answer_input === currentAnswer) {
    return "bg-mt-bright-red-pink";
  }

  return "bg-mt-white-smoke";
};

export const calculateAnswerStatsByTopic = ({ answers }: { answers: QuestionsInTopicsType }) => {
  const result: { [topicId: string]: AnswerStatsType } = {};

  for (const topicId in answers) {
    let correct = 0;
    let incorrect = 0;

    for (const questionId in answers[topicId]) {
      if (answers[topicId][questionId]) {
        correct++;
      } else {
        incorrect++;
      }
    }

    // Set questions to 0 because we reuse the type and calculate the number of questions elsewhere
    result[topicId] = { correct, incorrect, total: correct + incorrect, questions: 0 };
  }

  return result;
};

export const calculateCourseAnswerStats = ({
  learnedQuestionsInTopics,
}: {
  learnedQuestionsInTopics: QuestionsInTopicsType;
}): CourseOverallAnswerStatsType => {
  let totalAnswered = 0;
  let totalQuestions = 0;
  const totalQuestionsInTopics: { [topicId: string]: number } = {};

  for (const topicId in learnedQuestionsInTopics) {
    let questionCount = 0;
    for (const questionId in learnedQuestionsInTopics[topicId]) {
      questionCount++;
      totalQuestions++;
      if (learnedQuestionsInTopics[topicId][questionId]) {
        totalAnswered++;
      }
    }

    totalQuestionsInTopics[topicId] = questionCount;
  }

  const percentage = totalQuestions > 0 ? Math.round((totalAnswered / totalQuestions) * 100) : 0;

  return {
    totalAnswered,
    totalQuestions,
    percentage: parseFloat(percentage.toFixed(2)),
    totalQuestionsInTopics,
  };
};
