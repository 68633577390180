import React, { useCallback } from "react";
import { Box, Grid } from "@mui/material";
import LoadingOverlayView from "components/shared/LoadingOverLayView";
import { CourseType } from "types/course";
import CourseCard from "./CourseItemView";
import CreateCourseCard from "./CourseCreateView";

interface ICourseListProps {
  coursesList?: CourseType[];
  isLoading: Boolean;
  refetchDataCourses: () => void;
}

const CourseGrid: React.FC<ICourseListProps> = (props: ICourseListProps) => {
  const { coursesList, isLoading, refetchDataCourses } = props;
  const refetchCourse = useCallback(() => {
    refetchDataCourses();
  }, [refetchDataCourses]);

  if (isLoading) {
    return <LoadingOverlayView />;
  }

  return (
    <Box className="my-6" minHeight="100vh">
      <Grid container spacing={3} justifyContent="between">
        <Grid className="border-none" item xs={12} sm={6} md={4} lg={3}>
          <CreateCourseCard />
        </Grid>
        {coursesList?.map((course: CourseType, index: number) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
            <CourseCard refetchCourse={refetchCourse} course={course} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CourseGrid;
