import React from "react";
import icon from "./base";

export default icon("CourseTopic", ({ fill = "white" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <g clipPath="url(#clip0_530_185)">
      <mask
        id="mask0_530_185"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="21"
        height="20"
      >
        <path d="M0.25 0H20.25V20H0.25V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_530_185)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2498 1.25002C5.41734 1.25002 1.49984 5.16752 1.49984 10C1.49984 14.8325 5.41734 18.75 10.2498 18.75C15.0823 18.75 18.9998 14.8325 18.9998 10C18.9998 5.16752 15.0823 1.25002 10.2498 1.25002ZM0.666504 10C0.666504 4.70752 4.95734 0.416687 10.2498 0.416687C15.5423 0.416687 19.8332 4.70752 19.8332 10C19.8332 15.2925 15.5423 19.5834 10.2498 19.5834C4.95734 19.5834 0.666504 15.2925 0.666504 10Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.59131 6.30835H8.95631L8.97214 6.30918C9.41051 6.34156 9.82266 6.53036 10.1335 6.84117C10.4443 7.15199 10.6331 7.56414 10.6655 8.00252L10.6663 8.01835V15.2417L9.83298 15.2642C9.81066 14.8562 9.63928 14.4707 9.35137 14.1808C9.06347 13.8909 8.67911 13.7168 8.27131 13.6917H4.59131V6.30835ZM5.42464 7.14168V12.8583H8.30547C8.87143 12.8894 9.41004 13.1118 9.83298 13.4892V8.05002C9.81235 7.81604 9.71003 7.5968 9.54394 7.43071C9.37786 7.26463 9.15861 7.16231 8.92464 7.14168H5.42464Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.543 6.30835H15.908V13.6917H12.2288C11.8211 13.7182 11.4371 13.8929 11.1492 14.1828C10.8613 14.4728 10.6892 14.8579 10.6655 15.2658L9.83301 15.2417V8.03335H10.2497L9.83384 8.00252C9.86621 7.56414 10.055 7.15199 10.3658 6.84117C10.6767 6.53036 11.0888 6.34156 11.5272 6.30918L11.543 6.30835ZM10.6663 8.05002V13.4925C11.0888 13.1152 11.6266 12.8919 12.1922 12.8592L12.2038 12.8583H15.0747V7.14168H11.5747C11.3407 7.16231 11.1215 7.26463 10.9554 7.43071C10.7893 7.5968 10.687 7.81604 10.6663 8.05002Z"
          fill={fill}
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_530_185">
        <rect width="20" height="20" fill="white" transform="translate(0.25)" />
      </clipPath>
    </defs>
  </svg>
));
