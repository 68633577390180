import React from "react";
import icon from "./base";

export default icon("Area", () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 3.12695H23.5V22.127H0.5V3.12695ZM1.5 4.12695V21.127H22.5V4.12695H1.5Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4302 13.147H15.4302V16.6L16.6702 15.965L17.9102 16.6V13.147H18.9102V18.234L16.6702 17.089L14.4302 18.234V13.147Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.67 7.89697C15.9725 7.89697 15.3036 8.17406 14.8103 8.66728C14.3171 9.1605 14.04 9.82945 14.04 10.527C14.04 11.2245 14.3171 11.8934 14.8103 12.3867C15.3036 12.8799 15.9725 13.157 16.67 13.157C17.3676 13.157 18.0365 12.8799 18.5297 12.3867C19.023 11.8934 19.3 11.2245 19.3 10.527C19.3 9.82945 19.023 9.1605 18.5297 8.66728C18.0365 8.17406 17.3676 7.89697 16.67 7.89697ZM13.04 10.527C13.04 10.0503 13.1339 9.57824 13.3164 9.13783C13.4988 8.69742 13.7662 8.29725 14.1032 7.96018C14.4403 7.6231 14.8405 7.35571 15.2809 7.17329C15.7213 6.99087 16.1933 6.89697 16.67 6.89697C17.1467 6.89697 17.6188 6.99087 18.0592 7.17329C18.4996 7.35571 18.8998 7.6231 19.2368 7.96018C19.5739 8.29725 19.8413 8.69742 20.0237 9.13783C20.2061 9.57824 20.3 10.0503 20.3 10.527C20.3 11.4897 19.9176 12.413 19.2368 13.0938C18.5561 13.7745 17.6328 14.157 16.67 14.157C15.7073 14.157 14.784 13.7745 14.1032 13.0938C13.4225 12.413 13.04 11.4897 13.04 10.527Z"
      fill="black"
    />
  </svg>
));
