import React from "react";
// eslint-disable-next-line import/named
import LinearProgress, { LinearProgressProps } from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";

const CourseProgressView = (props: LinearProgressProps & { value: number }) => {
  const { t } = useTranslation();

  return (
    <Stack gap={1} className="w-full px-6">
      <Box className="mb-2 w-full">
        <LinearProgress
          variant="determinate"
          color="inherit"
          className={props.value === 100 ? "text-green-600" : "text-[#FFB319]"}
          {...props}
        />
      </Box>
      <Typography>
        <span className="font-bold">{`${Math.round(props.value)}%`}</span> {t("training.completed")}
      </Typography>
    </Stack>
  );
};

export default CourseProgressView;
