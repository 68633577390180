import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDropzone } from "react-dropzone";
import { MAX_FILE_SIZE } from "utilities/constants";

interface IImageInputViewProps {
  image: any;
  error?: string;
  label?: string;
  buttonText?: string;
  inputId: string;
  onChangeSelectImage: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const ImageInputView: React.FC<IImageInputViewProps> = ({
  buttonText,
  error,
  inputId,
  label,
  image,
  onChangeSelectImage,
}) => {
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
    },
    onDrop: acceptedFiles => {
      if (!acceptedFiles?.length) return;

      const syntheticEvent = ({
        target: {
          files: acceptedFiles,
        },
      } as unknown) as React.ChangeEvent<HTMLInputElement>;
      onChangeSelectImage(syntheticEvent);
    },
    multiple: false,
    maxSize: MAX_FILE_SIZE,
    disabled: false,
  });

  return (
    <Stack gap={1}>
      <Typography className="text-base font-bold">{label}</Typography>
      <Stack
        className="rounded-[10px] border border-dashed border-[#00000040] py-2 pl-4 pr-6 cursor-pointer"
        direction="row"
        alignItems="center"
        gap={2}
        {...getRootProps()}
      >
        <img
          src={image ?? "/images/unknown-image.png"}
          className="rounded-lg h-[78px]"
          alt="image-course"
          height={78}
          width={78}
          data-testid={`${inputId}_image`}
        />
        <Stack className="flex-1" gap={1}>
          <Typography className="text-[13px]">{t("global.select_a_file")}</Typography>
          <Typography className="text-xs text-[#00000066]">{t("global.file_size_required")}</Typography>
        </Stack>

        <Button
          className="w-fit rounded-md border-[#555F60] py-[10px] px-4 h-10 normal-case text-[#555F60]"
          variant="outlined"
        >
          {buttonText}
        </Button>
        <input
          {...getInputProps()}
          type="file"
          id={inputId}
          name="payment_image"
          accept=".png,.jpg,.jpeg"
          data-testid={inputId}
          hidden
        />
      </Stack>
      {error ? <p className="pt-2 text-red-600">{error}</p> : ""}
    </Stack>
  );
};
export default ImageInputView;
