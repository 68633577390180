import React from "react";
import icon from "./base";

export default icon("CourseQuestion", ({ fill = "white" }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none">
    <g clipPath="url(#clip0_530_195)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.50024 14.5833H5.66691V18.75H1.50024V14.5833ZM2.33357 15.4167V17.9167H4.83357V15.4167H2.33357ZM17.7502 17.0833H8.58357V16.25H17.7502V17.0833ZM1.50024 7.91666H5.66691V12.0833H1.50024V7.91666ZM2.33357 8.75V11.25H4.83357V8.75H2.33357ZM17.7502 10.4167H8.58357V9.58333H17.7502V10.4167ZM17.7502 3.75H8.58357V2.91666H17.7502V3.75ZM8.04524 1.12833L3.58357 5.58916L0.788574 2.795L1.37857 2.205L3.58357 4.41083L7.45524 0.53833L8.04524 1.12833Z"
        fill={fill}
      />
    </g>
    <defs>
      <clipPath id="clip0_530_195">
        <rect width="20" height="20" fill={fill} transform="translate(0.25)" />
      </clipPath>
    </defs>
  </svg>
));
