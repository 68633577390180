import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

const NotFoundView = () => {
  const { t } = useTranslation();

  return (
    <div className="container text-center pt-10">
      <img className="mx-auto" src="/images/404.png" alt="404" />
      <p className="font-bold text-10 leading-12" style={{ fontSize: "35px" }}>
        {t("global.page_not_found")}
      </p>
      <Button
        variant="contained"
        className="bg-primary mt-6 py-2 px-10"
        href="/v2/dashboard/courses"
        sx={{ textTransform: "none", borderRadius: 2 }}
      >
        <Typography className="leading-6 text-[14px] font-bold">{t("global.back_to_home")}</Typography>
      </Button>
    </div>
  );
};

export default NotFoundView;
