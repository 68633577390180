import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useResetLearnProgressOfCourseTraining } from "hooks/useTraining";
import { useTrainingContext } from "contexts/TrainingContext";
import View from "./view";

export interface IContainerProps {}

const Container: React.FC<IContainerProps> = () => {
  const {
    currentTopicSelected,
    questionsInTopic,
    countAnswersByTopic,
    courseOverallAnswerStats,
    handleRefetchListTopics,
  } = useTrainingContext();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(-1);

  const { mutate: mutateResetQuiz, isLoading: isLoadingResetQuiz } = useResetLearnProgressOfCourseTraining(
    currentTopicSelected.id,
    () => {
      setCurrentQuestionIndex(0);
      handleRefetchListTopics();
    },
  );

  const answerStats = useMemo(() => {
    const questions = courseOverallAnswerStats.totalQuestionsInTopics[currentTopicSelected.id] ?? 0;
    const answers = countAnswersByTopic[currentTopicSelected.id] ?? { correct: 0, incorrect: 0, total: 0 };
    return { ...answers, questions };
  }, [currentTopicSelected, countAnswersByTopic, courseOverallAnswerStats]);

  const currentQuestion = useMemo(() => {
    if (questionsInTopic.length === 0 || currentQuestionIndex === questionsInTopic.length) return undefined;

    return questionsInTopic[currentQuestionIndex];
  }, [questionsInTopic, currentQuestionIndex]);

  const isTopicFinished = useMemo(
    () => !questionsInTopic || questionsInTopic.length === 0 || !!currentTopicSelected.isCompleted,
    [questionsInTopic, currentTopicSelected],
  );

  const handleNextQuestion = useCallback(() => {
    setCurrentQuestionIndex(prev => prev + 1);
  }, []);

  const handleRetryTopic = useCallback(() => {
    mutateResetQuiz();
  }, [mutateResetQuiz]);

  useEffect(() => {
    if (isTopicFinished || questionsInTopic.length === 0 || currentQuestionIndex > -1) return;

    setCurrentQuestionIndex(0);
  }, [questionsInTopic, currentQuestionIndex, isTopicFinished]);

  return (
    <View
      currentQuestionIndex={currentQuestionIndex}
      answerStats={answerStats}
      currentQuestion={currentQuestion}
      isTopicFinished={isTopicFinished}
      handleNextQuestion={handleNextQuestion}
      isLoadingResetQuiz={isLoadingResetQuiz}
      handleRetryTopic={handleRetryTopic}
    />
  );
};

export default Container;
