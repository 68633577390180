import React from "react";

const Footer = () => {
  return (
    <footer className="bg-mt-dark-teal">
      <div className="container font-helvetica-neue text-white p-5 flex flex-row items-center justify-between">
        <div className="text-base font-medium">&copy; {new Date().getFullYear()} Microtraining</div>
      </div>
    </footer>
  );
};

export default Footer;
