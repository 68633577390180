import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import HTMLPreview from "components/shared/HTMLPreview";
import { TopicType } from "types/topic";
import { useTranslation } from "react-i18next";
import { useUpdateSeenThreadInCourseTraining } from "hooks/useTraining";

interface ITopicDetailProps {
  currentTopicSelected: TopicType;
  isLearning?: boolean;
  handleCompleteAndContinue?: () => void;
}

const TopicDetail: React.FC<ITopicDetailProps> = ({ currentTopicSelected, isLearning, handleCompleteAndContinue }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useUpdateSeenThreadInCourseTraining(currentTopicSelected.id, () => {
    handleCompleteAndContinue?.();
  });

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography className="font-medium uppercase text-sm text-[#313B3D]">
          {t("question.topic", {
            index: currentTopicSelected.uiIndex,
          })}{" "}
        </Typography>
        <Typography className="font-bold text-[2rem] text-[#313B3D]">{currentTopicSelected.name}</Typography>
      </Stack>

      <HTMLPreview htmlContent={currentTopicSelected.description} />

      {isLearning && (
        <Stack alignItems="center">
          <Button
            className="rounded-lg bg-primary normal-case text-white px-4 hover:bg-primary/80"
            onClick={currentTopicSelected.isSeen ? handleCompleteAndContinue : () => mutate()}
            disabled={isLoading}
          >
            {currentTopicSelected.isSeen ? t("training.continue") : t("training.complete_and_continue")}
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default TopicDetail;
