export const getDataFromLocalStorage = (key, defaultValue = {}) => {
  try {
    const data = localStorage.getItem(key);

    if (!data) return defaultValue;

    return JSON.parse(data);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(`Error parsing data from localStorage for key "${key}":`, error);
    return defaultValue;
  }
};
